import PropTypes from 'prop-types';
import Modal from '@uilib/business-components/Modal';

import i18n from 'Services/i18n';

export default function ModalWrapper(props) {
    return (
        <Modal
            {...props}
            testDataLabel="eid-popup"
            title={i18n(props.title)}
            onAway={props.onDispose}
            onClose={props.onDispose}
            onKeyEsc={props.onDispose}
        >
            {i18n(props.children)}
        </Modal>
    );
}

ModalWrapper.propTypes = {
    onDispose: PropTypes.func.isRequired,
};
