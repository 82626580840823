import { useState, Fragment, useEffect } from 'react';
import { BACKEND, withBackend } from 'Services/backend';
import PropTypes from 'prop-types';

import Button from 'uilib-wrappers/button';
import ConfirmationModal from 'uilib-wrappers/confirmation-modal';
import Modal from 'uilib-wrappers/modal';

import { createSimpleSelectionBody } from 'Bricks/Helpers';
import i18n from 'Services/i18n';

import TagsEdit from 'Bricks/tags-edit';
import toast from '@uilib/business-components/ToastContainer/Toast';
const MODAL_WINDOW_ID = 'eid-tags-edit';

function TagsEditModal(props) {
    // This is needed to fix problem with menu z-index.
    const [menuPortal, setMenuPortal] = useState(null);
    const [value, setValue] = useState([]);
    const [lengthExceededModalOpen, setLengthExceededModalOpen] = useState(false);

    function handleLengthExceeded() {
        setLengthExceededModalOpen(true);
    }

    function setMenuPortalOnLoad() {
        setMenuPortal(document.getElementById(MODAL_WINDOW_ID));
    }

    function handleApply(event) {
        const TAGS_DIFF = {
            added: value
                .filter((valueTag) => !props.tags.some((propsTag) => propsTag.id === valueTag.value))
                .map((tag) => tag.label),
            removed: props.tags
                .filter((propsTag) => !value.some((valueTag) => valueTag.value === propsTag.id))
                .map((tag) => tag.name),
        };
        props.onClose(event);
        props.onChange(event, TAGS_DIFF);
    }

    function handleErrorDismiss(event) {
        setLengthExceededModalOpen(false);
    }

    return (
        <Fragment>
            <Modal
                id={MODAL_WINDOW_ID}
                show={props.open}
                title={props.title || 'EDIT_TAGS'}
                onDispose={props.onClose}
                buttons={[
                    <Button id="eid-tags-edit-apply" type="primary" text="APPLY" onClick={handleApply} />,
                    <Button id="eid-tags-edit-cancel" type="secondary" text="CANCEL" onClick={props.onClose} />,
                ]}
            >
                <TagsEdit
                    tags={props.tags}
                    value={value}
                    setValue={setValue}
                    handleLengthExceeded={handleLengthExceeded}
                    menuPortalTarget={menuPortal}
                    setMenuPortalOnLoad={setMenuPortalOnLoad}
                />
            </Modal>
            <ConfirmationModal
                show={lengthExceededModalOpen}
                type="error"
                message="TAG_IS_TOO_LONG"
                text={i18n('TAG_HAS_BEEN_DISCARDED_MAXIMUM_TAG_LENGTH_IS_N_CHARACTERS', {
                    count: window.serverInfo.dbColumnsLength['tags'].tag_name,
                })}
                onDispose={handleErrorDismiss}
                buttons={[
                    <Button id="eid-tags-edit-error-dismiss" type="primary" text="OK" onClick={handleErrorDismiss} />,
                ]}
            />
        </Fragment>
    );
}
//-----------------------------------------------------------------------------
TagsEditModal.propTypes = {
    open: PropTypes.bool.isRequired,
    tags: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

//-----------------------------------------------------------------------------
TagsEditModal.defaultProps = {
    // No default props for now...
};

const TagsEditModalWithBackend = withBackend((props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);

    function handleTagsEditChange(event, diff) {
        const requestBody = createSimpleSelectionBody([props.tagsObjectId]);
        requestBody.objectType = props.tagsObjectType;

        if (props.tagsObjectType === window.serverInfo.constants.dbObject.process) {
            requestBody.groupId = props.groupId;
            requestBody.moduleId = props.moduleId;
        }

        requestBody.added = diff.added;
        requestBody.removed = diff.removed;

        BACKEND.post('tags/update', { ...requestBody, ...props.additionalDataForRequestBody }, props.componentUuid)
            .success((response) => {
                props.onChange(event);
            })
            .failure((respose) => {
                toast.error(i18n('TAG_CREATION_FAILED'), { autoClose: 3000 });
            })
            .execute();
    }

    useEffect(() => {
        setIsLoaded(false);
        if (props.open) {
            const requestBody = createSimpleSelectionBody([props.tagsObjectId]);
            requestBody.objectType = props.tagsObjectType;

            if (props.tagsObjectType === window.serverInfo.constants.dbObject.process) {
                requestBody.groupId = props.groupId;
                requestBody.moduleId = props.moduleId;
            }

            BACKEND.post(
                'tags/list',
                {
                    ...requestBody,
                    ...props.additionalDataForRequestBody,
                },
                props.componentUuid
            )
                .success((response) => {
                    setSelectedTags(response);
                    setIsLoaded(true);
                })
                .execute();
        }
    }, [props.open]);

    return (
        <TagsEditModal
            title={props.title}
            open={props.open && isLoaded}
            tags={selectedTags}
            onChange={handleTagsEditChange}
            onClose={props.onClose}
        />
    );
});

export { TagsEditModalWithBackend };
export default TagsEditModal;
