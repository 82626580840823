import { EVENT, EventNames } from 'Services/Eventing';

class GroupFilterService {
    constructor() {
        this.groupFilterDisabled = false;
        this.filterGroupId = undefined;
        this.activeParentGroupId = undefined;
        this.activeGroupId = undefined;
        this.activeGroupName = undefined;
    }

    enable = () => {
        this.groupFilterDisabled = false;
        this.activeGroupId = this.filterGroupId;
        EVENT.publish(EventNames.GROUP_FILTER_CHANGED_EVENT, {
            parentGroupId: this.activeParentGroupId,
            groupId: this.activeGroupId,
            groupName: this.activeGroupName,
            disabled: this.groupFilterDisabled,
            save: false,
        });
    };

    disable = (fixedGroupId) => {
        this.groupFilterDisabled = true;
        this.activeGroupId = fixedGroupId || 1;
        EVENT.publish(EventNames.GROUP_FILTER_CHANGED_EVENT, {
            parentGroupId: this.activeParentGroupId,
            groupId: this.activeGroupId,
            groupName: this.activeGroupName,
            disabled: this.groupFilterDisabled,
            save: false,
        });
    };

    set = (group, skipSave = false) => {
        const parentGroupId = group.parentGroupId;
        const groupId = group.groupId || 1;
        const groupName = group.groupName;

        this.filterGroupId = this.activeGroupId = groupId;
        this.activeParentGroupId = parentGroupId;
        this.activeGroupName = groupName;

        EVENT.publish(EventNames.GROUP_FILTER_CHANGED_EVENT, {
            parentGroupId,
            groupId,
            groupName,
            disabled: this.groupFilterDisabled,
            save: !skipSave,
        });
    };

    getId = () => {
        return this.activeGroupId;
    };

    get = () => {
        return {
            parentGroupId: this.activeParentGroupId,
            groupId: this.activeGroupId,
            groupName: this.activeGroupName,
            disabled: this.groupFilterDisabled,
        };
    };
}

const groupFilterService = new GroupFilterService();

export default groupFilterService;
