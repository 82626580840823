import { v4 as uuidv4 } from 'uuid';

import { EVENT, EventNames } from 'Services/Eventing';
import Authorization from 'Services/Authorization';
import asyncWorker from './async-requests';

import Navigator from 'Services/navigator';

import moment from 'moment';

import i18n from 'Services/i18n';

const SESSION_ID = Math.floor(1000000 * Math.random());

const FETCH_FAILED_CHROMIUM = 'Failed to fetch';
const FETCH_FAILED_FIREFOX = 'NetworkError when attempting to fetch resource.';
const FETCH_FAILED_SAFARI = 'Network request failed';

const FETCH_RETRY_ATTEMPTS = 4;
const FETCH_RETRY_TIMEOUT = 1000;

function isNetworkError(error) {
    return (
        error?.name === 'TypeError' &&
        (error?.message === FETCH_FAILED_CHROMIUM ||
            error?.message === FETCH_FAILED_FIREFOX ||
            error?.message === FETCH_FAILED_SAFARI)
    );
}

function getErrorMessage(method, url, response, type, message, details) {
    return {
        method: method,
        url: url,
        status: '[' + response.status + ', ' + response.xhrStatus + ']',
        timestamp: moment().local().format('YYYY-MM-DD HH:mm:ss'),
        type: type || 'error',
        message: i18n(message || 'SERVER_ERROR'),
        details: i18n(details || 'ERROR_OCCURED_WHILE_TRYING_TO_FULFILL_COMMAND'),
        redirectToDefaultState: response.status !== 408 && response.status !== 403 && response.status !== 400,
        showRequestDetails: response.status !== 403 && response.status !== 400,
    };
}

function retrieveToken(response) {
    const TOKEN = response.headers.get('X-Security-Token');
    if (TOKEN) {
        EVENT.publish(EventNames.AUTHORIZATION_TOKEN_EVENT, TOKEN);
    }
}

function errorResponse(response) {
    const CONTENT_LENGTH = response.headers.get('content-length');
    if (CONTENT_LENGTH > 0) {
        const JSON_TYPE = response.headers.get('content-type')?.includes('application/json');

        if (!response.headers.get('content-type')) {
            console.error('The error response does not contain a content-type header');
        }

        let p = JSON_TYPE ? response.json() : response.text();
        return p.then(
            function (json) {
                response.data = json;
                return Promise.reject(response);
            },
            function (error) {
                return Promise.reject(response);
            }
        );
    } else {
        return Promise.reject({});
    }
}

function defaultHandler(method, url, response) {
    EVENT.publish(EventNames.BACKEND_ALERT_EVENT, getErrorMessage(method, url, response));
    return Promise.reject(response);
}

function okHandler(method, url, response) {
    // Check the content length - for now assume we always use JSON object!
    const CONTENT_LENGTH = response.headers.get('content-length');

    // Check the transfer encoding length - it might be chunked with length set to zero!
    let encoding = response.headers.get('transfer-encoding');
    encoding = encoding === null ? encoding : encoding.toLowerCase();
    const IS_CHUNKED = encoding === 'chunked' || encoding === 'identity';

    const JSON_TYPE = response.headers.get('content-type')?.includes('application/json');
    if (!response.headers.get('content-type')) {
        console.error(`The response to the request URL ${url} does not contain a content-type header`);
    }

    return CONTENT_LENGTH > 0 || IS_CHUNKED ? (JSON_TYPE ? response.json() : response.text()) : {};
}

function badRequestHandler(method, url, response) {
    return errorResponse(response).then(
        (response) => {
            return Promise.reject(response); // just in case
        },
        (error) => {
            EVENT.publish(
                EventNames.BACKEND_ALERT_EVENT,
                getErrorMessage(method, url, response, 'error', 'BAD_REQUEST', error.data.message)
            );
            return Promise.reject(error);
        }
    );
}

function unauthorizedHandler(method, url, response) {
    Authorization.expireToken();
    return errorResponse(response);
}

function forbiddenHandler(method, url, response) {
    EVENT.publish(
        EventNames.BACKEND_ALERT_EVENT,
        getErrorMessage(
            method,
            url,
            response,
            'warning',
            'AUTHORIZATION_WARNING',
            'YOU_ARE_NOT_AUTHORIZED_TO_EXECUTE_THIS_ACTION'
        )
    );
    retrieveToken(response);
    return Promise.reject(response);
}

function timeoutHandler(method, url, response) {
    EVENT.publish(
        EventNames.BACKEND_ALERT_EVENT,
        getErrorMessage(
            method,
            url,
            response,
            'error',
            'CONNECTION_HAS_TIMED_OUT',
            'SERVER_IS_TAKING_TOO_LONG_TO_RESPOND_THE_SITE_CAN_BE_TEMPORARILY_UNAVAILABLE_OR_TOO_BUSY_TRY_AGAIN_IN_FEW_MOMENTS_TIMEOUT_OCCURRED_WHILE_TRYING_TO_FULL_COMMAND'
        )
    );
    return Promise.reject(response);
}

function internalServerErrorHandler(method, url, response) {
    return errorResponse(response).then(
        (response) => {
            return Promise.reject(response); // just in case
        },
        (error) => {
            const header = error.data === 'SqlError' ? 'DATABASE_SERVER_ERROR' : 'INTERNAL_SERVER_ERROR';
            const message =
                error.data === 'SqlError'
                    ? 'PROBLEM_WITH_CONNECTING_TO_DB_OCCURRED'
                    : 'INTERNAL_ERROR_OCCURRED_WHILE_TRYING_TO_FULFILL_COMMAND';

            EVENT.publish(
                EventNames.BACKEND_ALERT_EVENT,
                getErrorMessage(method, url, response, 'error', header, message)
            );

            return Promise.reject(error);
        }
    );
}

function gatewayTimeout(method, url, response) {
    // Do not show any error message - just redirect to the login page (same as ESMC does in such case).
    Authorization.removeToken();
    Navigator.replace(null, !window.serverInfo.useCentralRedirector ? 'login' : 'redirector');
    return Promise.reject(response);
}

const htmlResponseHandlers = {
    200: okHandler,
    400: badRequestHandler,
    401: unauthorizedHandler,
    403: forbiddenHandler,
    408: timeoutHandler,
    500: internalServerErrorHandler,
    504: gatewayTimeout,
};

class BackendService {
    constructor() {
        this.abortControllers = {
            /*
            [uuid-xxx]: {
                cancelled: true/false,
                requests: {
                    [key-xxx-1]: AbortController,
                    [key-xxx-2]: AbortController,
                    [key-xxx-3]: AbortController,
                }
            },
            [uuid-yyy]: {
                cancelled: true/false,
                requests: {
                    [key-yyy-1]: AbortController,
                    [key-yyy-2]: AbortController,
                    [key-yyy-3]: AbortController,
                }
            },
            [uuid-zzz]: {
                cancelled: true/false,
                requests: {
                    [key-zzz-1]: AbortController,
                    [key-zzz-2]: AbortController,
                    [key-zzz-3]: AbortController,
                }
            },
        */
        };
        this.timers = {
            /*
            [uuid-xxx]: {
                requests: {
                    [key-xxx-1]: AsyncWorkerTimer,
                    [key-xxx-2]: AsyncWorkerTimer,
                }
            },
            [uuid-yyy]: {
                requests: {
                    [key-yyy-1]: AsyncWorkerTimer,
                    [key-yyy-2]: AsyncWorkerTimer,
                }
            },
             */
        };
        this.cancelled = {};
    }

    init = () => {
        const componentUuid = uuidv4();
        this.abortControllers[componentUuid] = { cancelled: false, requests: {} };
        this.timers[componentUuid] = { requests: {} };
        return componentUuid;
    };

    cancel = (componentUuid) => {
        if (this.abortControllers[componentUuid]) {
            this.abortControllers[componentUuid].cancelled = true;
            Object.values(this.abortControllers[componentUuid].requests).forEach((abortController) =>
                abortController.abort()
            );
        }

        if (this.timers[componentUuid]) {
            Object.values(this.timers[componentUuid].requests).forEach((timer) => timer.cancel());
        }
    };

    clear = (componentUuid) => {
        if (this.abortControllers[componentUuid]) {
            Object.values(this.abortControllers[componentUuid].requests).forEach((abortController) =>
                abortController.abort()
            );
            delete this.abortControllers[componentUuid];
        }

        if (this.timers[componentUuid]) {
            Object.values(this.timers[componentUuid].requests).forEach((timer) => timer.cancel());
            delete this.timers[componentUuid];
        }
    };

    clearAll = () => {
        Object.keys(this.abortControllers).forEach(this.clear);
        Object.keys(this.timers).forEach(this.clear);
    };

    worker = async (method, path, data, componentUuid, skipTokenUpdate, customHandlers) => {
        const URL = `${window.location.protocol}//${window.location.host}/frontend/${path}`;
        const OPTIONS = {
            method: method,
            headers: {
                Accept: ['application/json', 'text/plain', '*/*'],
            },
        };

        // Attach body in case of POST, PUT or DELETE.
        if (method === 'post' || method === 'put' || method === 'delete') {
            if (typeof data === 'string') {
                OPTIONS.body = data;
                OPTIONS.headers['Content-Type'] = 'application/text';
            } else {
                if (data && data.session) {
                    delete data.session;
                    data.sessionId = SESSION_ID;
                }
                OPTIONS.body = JSON.stringify(data);
                OPTIONS.headers['Content-Type'] = 'application/json';
            }
        }

        // if request should be cancellable, we must add unique ID to it
        if (customHandlers['cancellable']) {
            OPTIONS.headers['X-Request-ID'] = uuidv4();
        }

        if (componentUuid === undefined) {
            throw new Error('Abort controller for component is not defined.');
        }

        const abortData = {
            key: `${componentUuid}_${method}_${URL}_${OPTIONS.body || ''}`,
            controllers: this.abortControllers[componentUuid],
        };

        // if request issuer has been unmounted or same request is already pending...
        if (abortData.controllers === undefined || abortData.controllers.requests[abortData.key]) {
            return;
        }

        if (skipTokenUpdate) {
            OPTIONS.headers['X-Token-Update'] = `skip`;
        }

        abortData.controllers.requests[abortData.key] = new window.AbortController();
        OPTIONS.signal = abortData.controllers.requests[abortData.key].signal;

        // Attach authorization token if exists.
        const TOKEN = Authorization.token;
        if (TOKEN) {
            OPTIONS.headers['Authorization'] = `Bearer ${TOKEN}`;
        }

        return this.runFetch(URL, OPTIONS, abortData, skipTokenUpdate, customHandlers, componentUuid);
    };

    runFetch = async (
        url,
        options,
        abortData,
        skipTokenUpdate,
        customHandlers,
        componentUuid,
        retryAttempts = FETCH_RETRY_ATTEMPTS
    ) => {
        let blockAlwaysHandler = false;
        let asyncWorkerCalled = false;
        try {
            let response = await fetch(url, options);

            if (!skipTokenUpdate || window.serverInfo.useCentralRedirector) {
                retrieveToken(response);
            }

            if (response.status === 202 && customHandlers['async'] === true) {
                console.log(`Running asynchronous worker for request ${url}`);
                EVENT.publish(EventNames.ASYNCHRONOUS_WORKER_FOR_REQUEST_EVENT, componentUuid);
                asyncWorkerCalled = true;
                const asyncWorkerResponse = await asyncWorker(
                    response,
                    componentUuid,
                    skipTokenUpdate,
                    this.abortControllers,
                    this.timers,
                    isNetworkError
                );
                response = asyncWorkerResponse;
            }

            if (response.status === -1) {
                throw new Error(response);
            }

            const customHandler = customHandlers[response.status];
            const predefinedHandler = htmlResponseHandlers[response.status] || defaultHandler;

            let responseData = null;
            if (customHandler) {
                responseData = await customHandler(options.method, url, response, async () => {
                    return await predefinedHandler(options.method, url, response);
                });
            } else if (
                (response.status === 401 && window.serverInfo.useCentralRedirector) ||
                response.status === 200 ||
                !customHandlers['failure']
            ) {
                responseData = await predefinedHandler(options.method, url, response);
            } else {
                throw new Error(response);
            }

            delete abortData.controllers.requests[abortData.key];
            return customHandlers['success']?.(responseData);
        } catch (error) {
            console.error('Fetch exception!', error);

            if (isNetworkError(error)) {
                if (retryAttempts > 0 && !asyncWorkerCalled) {
                    blockAlwaysHandler = true;
                    return setTimeout(() => {
                        console.info('Retrying Fetch request!');
                        this.runFetch(
                            url,
                            options,
                            abortData,
                            skipTokenUpdate,
                            customHandlers,
                            componentUuid,
                            retryAttempts - 1
                        );
                    }, FETCH_RETRY_TIMEOUT);
                } else {
                    if (!skipTokenUpdate) {
                        blockAlwaysHandler = false;
                        Authorization.removeToken();
                        Navigator.replace(null, !window.serverInfo.useCentralRedirector ? 'login' : 'redirector');
                    }
                }
            }

            delete abortData.controllers.requests[abortData.key];

            if (!error || error.name === 'AbortError') {
                if (customHandlers['cancellable']) {
                    const requestId = options.headers['X-Request-ID'];
                    EVENT.publish(EventNames.REQUEST_CANCELLATION_EVENT, { requestId, componentUuid });
                }

                if (abortData.controllers.cancelled) {
                    // Request cancelled by user.
                    if (Object.keys(abortData.controllers.requests).length === 0) {
                        abortData.controllers.cancelled = false;
                    }
                } else {
                    // Request aborted by component unmount.
                    if (customHandlers['always']) {
                        customHandlers['always'] = null;
                    }
                    return;
                }
            }
            return customHandlers['failure']?.(error);
        } finally {
            if (!blockAlwaysHandler) {
                customHandlers['always']?.();
            }
        }
    };

    get = (path, componentUuid, skipTokenUpdate, customHandlers = {}) => {
        return this.worker('get', path, null, componentUuid, skipTokenUpdate, customHandlers);
    };

    post = (path, data, componentUuid, skipTokenUpdate, customHandlers = {}) => {
        return this.worker('post', path, data, componentUuid, skipTokenUpdate, customHandlers);
    };

    put = (path, data, componentUuid, skipTokenUpdate, customHandlers = {}) => {
        return this.worker('put', path, data, componentUuid, skipTokenUpdate, customHandlers);
    };

    delete = (path, data, componentUuid, skipTokenUpdate, customHandlers = {}) => {
        return this.worker('delete', path, data, componentUuid, skipTokenUpdate, customHandlers);
    };
}

export default BackendService;
