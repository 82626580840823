const REMEDIATION_ACTIONS = {
    BLOCK_EXECUTABLES: 0,
    CLEAN_AND_BLOCK_EXECUTABLES: 1,
    ISOLATE_COMPUTERS_FROM_NETWORK: 2,
    KILL_PROCESSES_ON_COMPUTERS: 3,
    SCAN_COMPUTERS_FOR_MALWARE: 4,
    SHUTDOWN_COMPUTERS: 5,
};

const PROTECT_NETWORK_ACTIONS = [
    {
        value: REMEDIATION_ACTIONS.BLOCK_EXECUTABLES,
        name: 'BLOCK_EXECUTABLES',
        tooltip: 'BLOCK_EXECUTABLES_TOOLTIP',
        disableOn: [(_, checkboxesState) => checkboxesState[REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES]],
        enables: [() => [REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS]],
    },
    {
        value: REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES,
        name: 'CLEAN_AND_BLOCK_EXECUTABLES',
        tooltip: 'CLEAN_AND_BLOCK_EXECUTABLES_TOOLTIP',
        disableOn: [],
        enables: [() => [REMEDIATION_ACTIONS.BLOCK_EXECUTABLES, REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS]],
    },
    {
        value: REMEDIATION_ACTIONS.ISOLATE_COMPUTERS_FROM_NETWORK,
        name: 'ISOLATE_COMPUTERS_FROM_NETWORK',
        tooltip: 'ISOLATE_COMPUTERS_FROM_NETWORK_TOOLTIP',
        disableOn: [],
        enables: [],
    },
];

const PROTECT_COMPUTER_ACTIONS = [
    {
        value: REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS,
        name: 'KILL_PROCESSES_ON_COMPUTERS',
        tooltip: 'KILL_PROCESSES_ON_COMPUTERS_TOOLTIP',
        disableOn: [
            (_, checkboxesState) =>
                checkboxesState[REMEDIATION_ACTIONS.BLOCK_EXECUTABLES] ||
                checkboxesState[REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES],
        ],
        enables: [],
    },
    {
        value: REMEDIATION_ACTIONS.SCAN_COMPUTERS_FOR_MALWARE,
        name: 'SCAN_COMPUTERS_FOR_MALWARE',
        tooltip: 'SCAN_COMPUTER_FOR_MALWARE_TOOLTIP',
        disableOn: [],
        enables: [],
    },
    {
        value: REMEDIATION_ACTIONS.SHUTDOWN_COMPUTERS,
        name: 'SHUTDOWN_COMPUTERS',
        tooltip: 'SHUTDOWN_COMPUTERS_TOOLTIP',
        disableOn: [],
        enables: [],
    },
];

export { PROTECT_NETWORK_ACTIONS, PROTECT_COMPUTER_ACTIONS, REMEDIATION_ACTIONS };
