import { createContext } from 'react';

const defaultAppContextValue = {
    username: null,
    setUsername: () => {},
    settings: {},
    setSettings: () => {},
    appState: {},
};

export const AppContext = createContext(defaultAppContextValue);
