import { Fragment } from 'react';
import { createEsetHelpLink } from 'app.common';
import i18n, { i18nFormattedTranslation } from 'Services/i18n';
import ProductTourImages from './images';

function Mark(props) {
    return (
        <mark data-images={props.tagValue}>
            <b>{props.value}</b>
        </mark>
    );
}

function getPages(isCloud) {
    const img = new ProductTourImages(isCloud);

    const PAGE_A = {
        sections: [
            {
                title: null,
                paragraphs: [<Fragment>{i18n('EI_PAGE_A_SECTION_1_PARAGRAPH_1')}</Fragment>],
            },
            {
                title: i18n('EI_PAGE_A_SECTION_2_TITLE'),
                paragraphs: [
                    <Fragment>{i18n('EI_PAGE_A_SECTION_2_PARAGRAPH_1')}</Fragment>,
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_A_SECTION_2_PARAGRAPH_2'
                        )}
                    </Fragment>,
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_A_SECTION_2_PARAGRAPH_3'
                        )}
                    </Fragment>,
                    <Fragment>{i18n('EI_PAGE_A_SECTION_2_PARAGRAPH_4')}</Fragment>,
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_A_SECTION_2_PARAGRAPH_5'
                        )}
                    </Fragment>,
                ],
            },
            {
                title: i18n('EI_PAGE_A_SECTION_3_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_A_SECTION_3_PARAGRAPH_1'
                        )}
                    </Fragment>,
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_A_SECTION_3_PARAGRAPH_2'
                        )}
                    </Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_A_SECTION_3_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink(),
                },
            },
        ],
        images: [img.A_1, img.A_2, img.A_3, img.A_4],
        imageDimensions: [730, 640],
    };

    const PAGE_B = {
        sections: [
            {
                title: i18n('EI_PAGE_B_SECTION_1_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_B_SECTION_1_PARAGRAPH_1'
                        )}
                    </Fragment>,
                ],
            },
            {
                title: i18n('EI_PAGE_B_SECTION_2_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_B_SECTION_2_PARAGRAPH_1'
                        )}
                    </Fragment>,
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_B_SECTION_2_PARAGRAPH_2'
                        )}
                    </Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_B_SECTION_2_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('incidents'),
                },
            },
        ],

        images: [img.B_1, img.B_2, img.B_3],
        imageDimensions: [730, 640],
    };

    const PAGE_C = {
        sections: [
            {
                title: i18n('EI_PAGE_C_SECTION_1_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_C_SECTION_1_PARAGRAPH_1'
                        )}
                    </Fragment>,
                ],
            },
            {
                title: i18n('EI_PAGE_C_SECTION_2_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_C_SECTION_2_PARAGRAPH_1'
                        )}
                    </Fragment>,
                ],
            },
            {
                title: i18n('EI_PAGE_C_SECTION_3_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_C_SECTION_1_PARAGRAPH_3'
                        )}
                    </Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_C_SECTION_3_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('detections'),
                },
            },
        ],

        images: [img.C_1, img.C_2, img.C_3, img.C_4],
        imageDimensions: [730, 640],
    };

    const PAGE_D = {
        sections: [
            {
                title: i18n('EI_PAGE_D_SECTION_1_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_D_SECTION_1_PARAGRAPH_1'
                        )}
                    </Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_D_SECTION_1_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('rule_remediate', false, 'remediate'),
                },
            },
        ],

        images: [img.D_1],
        imageDimensions: [730, 640],
    };

    const PAGE_E = {
        sections: [
            {
                title: null,
                paragraphs: [<Fragment>{i18n('EI_PAGE_E_SECTION_1_PARAGRAPH_1')}</Fragment>],
            },
            {
                title: i18n('EI_PAGE_E_SECTION_2_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_E_SECTION_2_PARAGRAPH_1'
                        )}
                    </Fragment>,
                    <Fragment>{i18n('EI_PAGE_E_SECTION_2_PARAGRAPH_2')}</Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_E_SECTION_2_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('executables'),
                },
            },
            {
                title: i18n('EI_PAGE_E_SECTION_3_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_E_SECTION_3_PARAGRAPH_1'
                        )}
                    </Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_E_SECTION_3_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('scripts'),
                },
            },
            {
                title: i18n('EI_PAGE_E_SECTION_4_TITLE'),
                paragraphs: [
                    <Fragment>{i18n('EI_PAGE_E_SECTION_4_PARAGRAPH_1')}</Fragment>,
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_E_SECTION_4_PARAGRAPH_2'
                        )}
                    </Fragment>,
                    <Fragment>{i18n('EI_PAGE_E_SECTION_4_PARAGRAPH_3')}</Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_E_SECTION_4_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('search'),
                },
            },
        ],

        images: [img.E_1, img.E_2, img.E_3, img.E_4, img.E_5],
        imageDimensions: [730, 640],
    };

    const PAGE_F = {
        sections: [
            {
                title: i18n('EI_PAGE_F_SECTION_1_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_F_SECTION_1_PARAGRAPH_1'
                        )}
                    </Fragment>,
                    <Fragment>{i18n('EI_PAGE_F_SECTION_1_PARAGRAPH_2')}</Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_F_SECTION_1_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('login', false, 'learning_mode'),
                },
            },
            {
                title: i18n('EI_PAGE_F_SECTION_2_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_F_SECTION_2_PARAGRAPH_1'
                        )}
                    </Fragment>,
                    <Fragment>{i18n('EI_PAGE_F_SECTION_2_PARAGRAPH_2')}</Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_F_SECTION_2_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('exclusions'),
                },
            },
            {
                title: i18n('EI_PAGE_F_SECTION_3_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_F_SECTION_3_PARAGRAPH_1'
                        )}
                    </Fragment>,
                    <Fragment>{i18n('EI_PAGE_F_SECTION_3_PARAGRAPH_2')}</Fragment>,
                    <Fragment>{i18n('EI_PAGE_F_SECTION_3_PARAGRAPH_3')}</Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_F_SECTION_3_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('detections'),
                },
            },
        ],

        images: [img.F_1, img.F_2, img.F_3],
        imageDimensions: [730, 640],
    };

    const PAGE_G = {
        sections: [
            {
                title: i18n('EI_PAGE_G_SECTION_1_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_G_SECTION_1_PARAGRAPH_1'
                        )}
                    </Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_G_SECTION_1_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('notifications'),
                },
            },
        ],

        images: [img.G_1],
        imageDimensions: [730, 640],
    };

    const PAGE_B_C_OP = {
        sections: [
            {
                title: i18n('EI_PAGE_C_SECTION_1_TITLE'),
                paragraphs: [<Fragment>{i18n('EI_PAGE_B_C_SECTION_1_PARAGRAPH_1')}</Fragment>],
            },
            {
                title: i18n('EI_PAGE_C_SECTION_2_TITLE'),
                paragraphs: [<Fragment>{i18n('EI_PAGE_B_C_SECTION_2_PARAGRAPH_1')}</Fragment>],
            },
            {
                title: i18n('EI_PAGE_C_SECTION_3_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_B_C_SECTION_3_PARAGRAPH_1'
                        )}
                    </Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_C_SECTION_3_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('detections'),
                },
            },
            {
                title: i18n('EI_PAGE_B_SECTION_1_TITLE'),
                paragraphs: [
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_B_C_SECTION_4_PARAGRAPH_1'
                        )}
                    </Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_B_SECTION_2_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('incidents'),
                },
            },
        ],

        images: [img.B_C_1, img.B_C_2],
        imageDimensions: [730, 640],
    };
    const PAGE_D_OP = {
        sections: [
            {
                title: i18n('EI_PAGE_D_SECTION_1_TITLE'),
                paragraphs: [
                    <Fragment>{i18n('EI_PAGE_D_OP_SECTION_1_PARAGRAPH_1')}</Fragment>,
                    <Fragment>
                        {i18nFormattedTranslation(
                            'ei-tag-mark',
                            'data-images',
                            Mark,
                            'EI_PAGE_D_OP_SECTION_1_PARAGRAPH_2'
                        )}
                    </Fragment>,
                    <Fragment>{i18n('EI_PAGE_D_OP_SECTION_1_PARAGRAPH_3')}</Fragment>,
                    <Fragment>{i18n('EI_PAGE_D_OP_SECTION_1_PARAGRAPH_4')}</Fragment>,
                ],
                learnMore: {
                    text: i18n('EI_PAGE_D_OP_SECTION_1_PARAGRAPH_HELPTEXT'),
                    link: createEsetHelpLink('rule_remediate', false, 'remediate'),
                },
            },
        ],

        images: [img.D_1],
        imageDimensions: [730, 640],
    };

    return isCloud
        ? [PAGE_A, PAGE_B, PAGE_C, PAGE_D, PAGE_E, PAGE_F, PAGE_G]
        : [PAGE_A, PAGE_B_C_OP, PAGE_D_OP, PAGE_E, PAGE_F, PAGE_G];
}

export default getPages;
