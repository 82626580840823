import { useState } from 'react';

import { BACKEND, withBackend } from 'Services/backend';

import Progress from 'Bricks/Progress';

import Button from 'uilib-wrappers/button';
import ConfirmationModal from 'uilib-wrappers/confirmation-modal';
import Text from 'uilib-wrappers/text';

function CommentDeleteWindow(props) {
    const [progress, setProgress] = useState(false);
    const onCommentDelete = () => {
        setProgress(true);
        BACKEND.delete(
            'comment',
            {
                commentId: props.commentId,
                objectId: props.objectId,
                objectType: props.objectType,
                ...props.additionalDataForRequestBody,
            },
            props.componentUuid
        )
            .success(() => {
                props.onChange();
            })
            .always(() => {
                setProgress(false);
                props.onClose();
            })
            .execute();
    };

    return (
        <ConfirmationModal
            show={props.isOpen}
            message="DELETE_COMMENT"
            type="question"
            onDispose={props.onClose}
            buttons={[
                <Button id="eid-comment-button-save" type="primary" text="DELETE" onClick={onCommentDelete} />,
                <Button id="eid-comment-button-cancel" type="secondary" text="CANCEL" onClick={props.onClose} />,
            ]}
        >
            <Text>ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COMMENT</Text>
            <Progress showProgress={progress} />
        </ConfirmationModal>
    );
}

export default withBackend(CommentDeleteWindow);
