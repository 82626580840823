import IconHardwareComputer from '@uilib/assets-business-icons/IconHardwareComputer';
import IconTerminalExecutable from '@uilib/assets-business-icons/IconTerminalExecutable';

import { useState, useEffect } from 'react';
import { BACKEND, withBackend } from 'Services/backend';
import { checkState } from '@uilib/business-components/Checkbox';
import toast from '@uilib/business-components/ToastContainer/Toast';
import Loading from '@uilib/business-components/Animations/Loading/Loading';
import Spacer from '@uilib/core/Spacer/Spacer';
import FlexContainer from '@uilib/core/FlexContainer/FlexContainer';
import Button from 'uilib-wrappers/button';
import Text from 'uilib-wrappers/text';

import {
    REMEDIATION_CONFIRMATION_TYPE,
    RemediationCheckboxGroup,
    RemediationConfirmation,
    RemediationActionBubbleLink,
    TrustedExecutablesWarning,
    getRemediationConfirmationTitle,
    getRemediationConfirmationButtons,
} from 'Bricks/remediation-common';

import {
    handleRemediationBadRequest,
    handleRemediationInternalServerError,
} from '../remediation-common/remediation-response-handlers';

import { PROTECT_NETWORK_ACTIONS, PROTECT_COMPUTER_ACTIONS, REMEDIATION_ACTIONS } from './remediation-actions';
import i18n from 'Services/i18n';
import { Fragment } from 'react';
import Filters from 'Services/Filters';

import { requestFailed } from 'Bricks/remediation-common/remediation-response-handlers';
import GridCells from 'Bricks/Grid/Cells';
import { Navigator } from 'Services';
import ActionsModal from 'Bricks/actions-modal';
import HighlightBlank from 'Bricks/highlight-blank';
import SimpleTable from 'Bricks/simple-table';
import Progress from 'Bricks/Progress';

import './index.scss';

const ALL_REMEDIATION_ACTIONS = [...PROTECT_NETWORK_ACTIONS, ...PROTECT_COMPUTER_ACTIONS];

function goToComputerDetails(event, computerId) {
    Navigator.open(event, 'console.computer.details', { computerId });
}

function goToExecutableDetails(event, moduleId) {
    Navigator.open(event, 'console.module.details', { moduleId });
}

function goToProcessDetails(event, processId) {
    Navigator.open(event, 'console.process.details', { processId });
}

function RemediationComputersBubbles(props) {
    return props.computers?.length > 0 ? (
        props.computers.length <= 5 || props.showAll ? (
            props.computers.map((computer) => (
                <RemediationActionBubbleLink
                    key={computer.id}
                    {...(props.showAll && { align: 'start', justify: 'start' })}
                    onClick={(event) => goToComputerDetails(event, computer.id)}
                    icon={<IconHardwareComputer fill="currentcolor" />}
                    severity={computer.status}
                    text={computer.name}
                />
            ))
        ) : (
            i18n('COMPUTERS_COUNT', { count: props.computers.length })
        )
    ) : (
        <HighlightBlank className="ei-bleak-text" value="" />
    );
}

function RemediationModulesBubblesSection(props) {
    return props.modules?.length > 0 ? (
        props.modules.length <= 5 || props.showAll ? (
            props.modules.map((module) => (
                <RemediationActionBubbleLink
                    key={module.id}
                    {...(props.showAll && { align: 'start', justify: 'start' })}
                    onClick={(event) => goToExecutableDetails(event, module.id)}
                    icon={<IconTerminalExecutable fill="currentcolor" />}
                    severity={module.status}
                    text={module.name}
                />
            ))
        ) : (
            i18n('EXECUTABLES_COUNT', { count: props.modules.length })
        )
    ) : (
        <HighlightBlank className="ei-bleak-text" value="" />
    );
}

function RemediationProcessesBubbles(props) {
    return props.processes?.length > 0 ? (
        props.processes.length <= 5 || props.showAll ? (
            props.processes.map((proces) => (
                <RemediationActionBubbleLink
                    key={proces.processId}
                    {...(props.showAll && { align: 'start', justify: 'start' })}
                    onClick={(event) => goToProcessDetails(event, proces.processId)}
                    icon={<IconTerminalExecutable fill="currentcolor" />}
                    severity={proces.status}
                    text={proces.moduleName}
                />
            ))
        ) : (
            i18n('PROCESSES_COUNT', { count: props.processes.length })
        )
    ) : (
        <HighlightBlank className="ei-bleak-text" value="" />
    );
}

function ComputersTable(props) {
    const options = {
        columns: [
            {
                field: 'COMPUTER',
                cellComponent: GridCells.ComputerNameActionCell,
                width: 400,
                onClick: goToComputerDetails,
            },
            {
                field: 'REMEDIATION_ACTIONS',
                width: 300,
                cellComponent: GridCells.RemediationActionsCell,
            },
        ],
        entities: props.data,
        settings: props.settings,
        data: props.data?.map((entity) => [entity.name, {}]) || [],
        disableSelection: true,
    };
    return <SimpleTable className="table-border" options={options} />;
}

function ExecutablesTable(props) {
    const options = {
        columns: [
            {
                field: 'EXECUTABLE',
                cellComponent: GridCells.ExecutableNameActionCell,
                onClick: goToExecutableDetails,
            },
            {
                field: 'REPUTATION_LIVEGRID_WITH_SIGN',
                tooltip: true,
                cellComponent: GridCells.RemediationReputationCell,
                centered: true,
            },
            {
                field: 'CERTIFICATE',
            },
            {
                field: 'REMEDIATION_ACTIONS',
                cellComponent: GridCells.RemediationActionsCell,
            },
        ],
        entities: props.data,
        settings: props.settings,
        data:
            props.data?.map((entity) => [
                entity.name,
                entity.cloudEiReputation,
                Filters.processSignType(entity.processSignType),
                {},
            ]) || [],
        disableSelection: true,
    };

    return (
        <Fragment>
            {props.containsSafeExecutables && props.containsSafeExecutablesToBlock ? (
                <TrustedExecutablesWarning />
            ) : (
                props.containsSafeExecutables && (
                    <Spacer type="mb-4">
                        <Text>EXECUTABLES_WITH_A_HIGH_REPUTATION_IN_ESET_LIVEGRID_ARE_UNCHECKED_BY_DEFAULT</Text>
                    </Spacer>
                )
            )}

            <SimpleTable className="table-border" options={options} />
        </Fragment>
    );
}

function ProcessTable(props) {
    const options = {
        columns: [
            {
                field: 'PROCESS',
                cellComponent: GridCells.ProcessNameActionCell,
                onClick: goToProcessDetails,
            },
            {
                field: 'COMPUTER',
            },
            {
                field: 'PID',
            },
            {
                field: 'REMEDIATION_ACTIONS',
                cellComponent: GridCells.RemediationActionsCell,
            },
        ],
        entities: props.data,
        settings: props.settings,
        data: props.data?.map((entity) => [entity.moduleName, entity.computerName, entity.systemProcessId, {}]) || [],
        disableSelection: true,
    };
    return <SimpleTable className="table-border" options={options} />;
}

function RemediationModalIncident(props) {
    const [isLoading, setIsLoading] = useState(false);

    const [containsSafeExecutablesToBlock, setContainsSafeExecutablesToBlock] = useState(false);
    const [containsSafeExecutables, setContainsSafeExecutables] = useState(false);

    /**************************************** CHECKBOXES  ****************************************/
    const [remediationActions, setRemediationActions] = useState([]);

    function getAllCheckboxesDisabled() {
        return ALL_REMEDIATION_ACTIONS.reduce(
            (accumulator, action) => ((accumulator[action.value] = false), accumulator),
            {}
        );
    }

    function blockExecutableActionSelected(actions) {
        return actions.some(
            (action) =>
                action.value === REMEDIATION_ACTIONS.BLOCK_EXECUTABLES ||
                action.value === REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES
        );
    }

    function isActionChecked(actionValue) {
        if (remediationActions) {
            return remediationActions[actionValue] ? checkState.checked : checkState.unchecked;
        }
        return checkState.unchecked;
    }

    function toggleAction(actionValue) {
        setRemediationActions((prevSettings) => {
            const newValues = {
                [actionValue]: !prevSettings[actionValue],
            };

            // reset table checkbox on uncheck
            prevSettings[actionValue] && clearSelectedActionsInTable(actionValue);

            // action is being enabled, possible other actions to enable
            if (!prevSettings[actionValue]) {
                const actionConfig = ALL_REMEDIATION_ACTIONS.find((action) => action.value === actionValue);
                const additionalActionsTnEnable = actionConfig.enables;
                additionalActionsTnEnable.forEach((actionFn) => {
                    actionFn().forEach((action) => {
                        newValues[action] = true;
                    });
                });
            } else {
                // on disable clean previously enabled checkboxes for empty data
                const actionConfig = ALL_REMEDIATION_ACTIONS.find((action) => action.value === actionValue);
                const additionalActionsTnEnable = actionConfig.enables;
                additionalActionsTnEnable.forEach((actionFn) => {
                    actionFn().forEach((action) => {
                        newValues[action] = !shouldDisableOnEmpty(action, actionValue);
                    });
                });
            }

            return {
                ...prevSettings,
                ...newValues,
            };
        });
    }

    function removeRedundantActions(actions) {
        return actions.filter((action) => action.value === REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES).length
            ? actions.filter((action) => action.value !== REMEDIATION_ACTIONS.BLOCK_EXECUTABLES)
            : actions;
    }

    function getSelectedActions() {
        return removeRedundantActions(ALL_REMEDIATION_ACTIONS.filter((action) => remediationActions[action.value]));
    }

    function getSelectedActionsThatHaveData() {
        return removeRedundantActions(
            ALL_REMEDIATION_ACTIONS.filter((action) => {
                if (
                    action.value === REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS &&
                    isActionChecked(REMEDIATION_ACTIONS.BLOCK_EXECUTABLES)
                ) {
                    /** If no executables selected to block then auto enabled kill for all also should be removed.
                     *  Kill can be anabled when processes table is empty and exe block is checked because its kill another processes in the backend
                     */
                    return getPreparedForRequestData(REMEDIATION_ACTIONS.BLOCK_EXECUTABLES)?.length ? true : false;
                }
                return remediationActions[action.value] && getPreparedForRequestData(action.value)?.length;
            })
        );
    }

    function shouldRemediationBeDisabled() {
        return getSelectedActions().length === 0;
    }

    function shouldDisableOnEmpty(actionValue, skipActionValue) {
        if (
            actionValue === REMEDIATION_ACTIONS.BLOCK_EXECUTABLES ||
            actionValue === REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES
        ) {
            if (!(data.modules?.length > 0)) {
                return true;
            }
        } else if (actionValue === REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS) {
            if (!(data.processes?.length > 0)) {
                if (skipActionValue !== undefined) {
                    return !blockExecutableActionSelected(
                        getSelectedActions().filter(({ value }) => value !== skipActionValue)
                    );
                } else {
                    return !blockExecutableActionSelected(getSelectedActions());
                }
            }
        } else if (
            actionValue === REMEDIATION_ACTIONS.ISOLATE_COMPUTERS_FROM_NETWORK ||
            actionValue === REMEDIATION_ACTIONS.SCAN_COMPUTERS_FOR_MALWARE ||
            actionValue === REMEDIATION_ACTIONS.SHUTDOWN_COMPUTERS
        ) {
            if (!(data.computers?.length > 0)) return true;
        }
        return false;
    }

    function shouldActionBeDisabled(action) {
        return (
            shouldDisableOnEmpty(action.value) ||
            action.disableOn.some((condition) => condition(undefined, remediationActions))
        );
    }

    /***************************************** DATA ****************************************/
    const [data, setData] = useState({});
    const [dataPreparedForRequest, setDataPreparedForRequest] = useState({});
    const [remediationActionsForExecutables, setRemediationActionsForExecutables] = useState({});
    const [remediationActionsForComputers, setRemediationActionsForComputers] = useState({});
    const [remediationActionsForProcesses, setRemediationActionsForProcesses] = useState({});

    const CHECKBOX_DATA_KEY = {
        // celan & block is more like an extension for block - use the same storage
        [REMEDIATION_ACTIONS.BLOCK_EXECUTABLES]: 'EXECUTABLES',
        [REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES]: 'EXECUTABLES',

        [REMEDIATION_ACTIONS.ISOLATE_COMPUTERS_FROM_NETWORK]: 'ISOLATE_COMPUTERS_FROM_NETWORK',
        [REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS]: 'KILL_PROCESSES_ON_COMPUTERS',
        [REMEDIATION_ACTIONS.SCAN_COMPUTERS_FOR_MALWARE]: 'SCAN_COMPUTERS_FOR_MALWARE',
        [REMEDIATION_ACTIONS.SHUTDOWN_COMPUTERS]: 'SHUTDOWN_COMPUTERS',
    };

    function getData() {
        setIsLoading(true);

        const computers = BACKEND.get(`INCIDENT/${props.incidentId}/COMPUTERS`, props.componentUuid)
            .success((computers) => computers)
            .execute();

        const modules = BACKEND.get(`INCIDENT/${props.incidentId}/MODULES`, props.componentUuid)
            .success((modules) => modules)
            .execute();

        const processes = BACKEND.get(`INCIDENT/${props.incidentId}/PROCESSES`, props.componentUuid)
            .success((processes) => processes)
            .execute();

        Promise.all([computers, modules, processes])
            .then(([{ computers }, { modules }, { processes }]) => {
                setContainsSafeExecutables(
                    modules?.some((module) => Filters.isReputationSafe(module.cloudEiReputation))
                );
                setData({ computers, modules, processes });
            })
            .catch(() => {
                requestFailed();
                props.close();
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function getComputersActionsForReview() {
        return [
            ...(isActionChecked(REMEDIATION_ACTIONS.ISOLATE_COMPUTERS_FROM_NETWORK)
                ? [{ title: 'ISOLATE_FROM_NETWORK', actionValue: REMEDIATION_ACTIONS.ISOLATE_COMPUTERS_FROM_NETWORK }]
                : []),
            ...(isActionChecked(REMEDIATION_ACTIONS.SCAN_COMPUTERS_FOR_MALWARE)
                ? [{ title: 'SCAN', actionValue: REMEDIATION_ACTIONS.SCAN_COMPUTERS_FOR_MALWARE }]
                : []),
            ...(isActionChecked(REMEDIATION_ACTIONS.SHUTDOWN_COMPUTERS)
                ? [{ title: 'SHUTDOWN', actionValue: REMEDIATION_ACTIONS.SHUTDOWN_COMPUTERS }]
                : []),
        ];
    }

    function getExecutablesActionsForReview() {
        return [
            ...(isActionChecked(REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES)
                ? [{ title: 'CLEAN_AND_BLOCK', actionValue: REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES }]
                : isActionChecked(REMEDIATION_ACTIONS.BLOCK_EXECUTABLES)
                ? [{ title: 'BLOCK', actionValue: REMEDIATION_ACTIONS.BLOCK_EXECUTABLES }]
                : []),
        ];
    }

    function getProcessesActionsForReview() {
        return [
            ...(isActionChecked(REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS)
                ? [{ title: 'KILL', actionValue: REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS }]
                : []),
        ];
    }

    function prepareComputersData() {
        const computerActionsForReview = getComputersActionsForReview();
        setRemediationActionsForComputers(
            data.computers.reduce((computerActionsData, computer) => {
                const previousActionsState = getSingleComputerActionsForReview(computer.id);

                return {
                    ...computerActionsData,
                    [computer.id]: computerActionsForReview.reduce((actions, currentAction) => {
                        const disabled =
                            currentAction.actionValue === REMEDIATION_ACTIONS.ISOLATE_COMPUTERS_FROM_NETWORK &&
                            computer.osFamily !== Filters.OS_FAMILY.WINDOWS;

                        return {
                            ...actions,
                            [CHECKBOX_DATA_KEY[currentAction.actionValue]]: {
                                checkedState: previousActionsState?.[CHECKBOX_DATA_KEY[currentAction.actionValue]]
                                    ? previousActionsState[CHECKBOX_DATA_KEY[currentAction.actionValue]].checkedState
                                    : disabled
                                    ? checkState.unchecked
                                    : checkState.checked,
                                disabled,
                                tooltip: disabled
                                    ? 'ISOLATE_COMPUTERS_FROM_NETWORK_NOT_AVAILABLE_FOR_OS_FAMILY_OTHER_THAN_WINDOWS'
                                    : undefined,
                                title: currentAction.title,
                            },
                        };
                    }, {}),
                };
            }, {})
        );
    }

    function prepareExecutablesData() {
        const moduleActionsForReview = getExecutablesActionsForReview();
        setRemediationActionsForExecutables(
            data.modules.reduce((moduleActionsData, module) => {
                const previousActionsState = getSingleExecutableActionsForReview(module.id);
                return {
                    ...moduleActionsData,
                    [module.id]: moduleActionsForReview.reduce((actions, currentAction) => {
                        const disabled =
                            currentAction.actionValue === REMEDIATION_ACTIONS.BLOCK_EXECUTABLES && module.blocked;

                        return {
                            ...actions,
                            [CHECKBOX_DATA_KEY[currentAction.actionValue]]: {
                                /** priority on the last selection even if nexr changes make this checkbox disabled, they will be filtered later - onsbmit */
                                checkedState: previousActionsState?.[CHECKBOX_DATA_KEY[currentAction.actionValue]]
                                    ? previousActionsState[CHECKBOX_DATA_KEY[currentAction.actionValue]].checkedState
                                    : Filters.isReputationSafe(module.cloudEiReputation)
                                    ? checkState.unchecked
                                    : checkState.checked,
                                disabled,
                                tooltip: disabled ? 'ALREADY BLOCKED' : undefined,
                                title: currentAction.title,
                                safe: Filters.isReputationSafe(module.cloudEiReputation),
                            },
                        };
                    }, {}),
                };
            }, {})
        );
    }

    function prepareProcessesData(setAllChecked = false) {
        const processActionsForReview = getProcessesActionsForReview();
        setRemediationActionsForProcesses(
            data.processes.reduce((processActionsData, process) => {
                const previousActionsState = getSingleProcessActionsForReview(process.processId);
                return {
                    ...processActionsData,
                    [process.processId]: processActionsForReview.reduce((actions, currentAction) => {
                        return {
                            ...actions,
                            [CHECKBOX_DATA_KEY[currentAction.actionValue]]: {
                                checkedState: setAllChecked
                                    ? checkState.checked
                                    : previousActionsState?.[CHECKBOX_DATA_KEY[currentAction.actionValue]]
                                    ? previousActionsState[CHECKBOX_DATA_KEY[currentAction.actionValue]].checkedState
                                    : checkState.checked,
                                title: currentAction.title,
                            },
                        };
                    }, {}),
                };
            }, {})
        );
    }

    useEffect(() => {
        if (props.show) {
            getData();
        }
    }, [props.show]);

    /**************************************** TABLES ACTIONS ****************************************/
    function onSingleComputerReviewActionChange(id, actionDataKey, checkedState) {
        const remediationActionsForComputersTemp = { ...remediationActionsForComputers };
        remediationActionsForComputersTemp[id][actionDataKey].checkedState = checkedState;
        setRemediationActionsForComputers(remediationActionsForComputersTemp);
    }

    function onSingleExecutableReviewActionChange(id, actionDataKey, checkedState) {
        const remediationActionsForExecutablesTemp = { ...remediationActionsForExecutables };

        remediationActionsForExecutablesTemp[id][actionDataKey].checkedState = checkedState;

        if (remediationActionsForExecutablesTemp[id][actionDataKey].safe) {
            const containsSafeChecked =
                remediationActionsForExecutablesTemp[id][actionDataKey].checkedState === checkState.checked ||
                Object.keys(remediationActionsForExecutablesTemp).some((id) => {
                    return (
                        remediationActionsForExecutablesTemp[id][actionDataKey].safe &&
                        remediationActionsForExecutablesTemp[id][actionDataKey].checkedState === checkState.checked
                    );
                });

            setContainsSafeExecutablesToBlock(containsSafeChecked);
        }

        setRemediationActionsForExecutables(remediationActionsForExecutablesTemp);
    }

    function onSingleProcessReviewActionChange(id, actionDataKey, checkedState) {
        const remediationActionsForProcessesTemp = { ...remediationActionsForProcesses };
        remediationActionsForProcessesTemp[id][actionDataKey].checkedState = checkedState;
        setRemediationActionsForProcesses(remediationActionsForProcessesTemp);
    }

    function getSingleComputerActionsForReview(id) {
        return remediationActionsForComputers[id];
    }

    function getSingleExecutableActionsForReview(id) {
        return remediationActionsForExecutables[id];
    }

    function getSingleProcessActionsForReview(id) {
        return remediationActionsForProcesses[id];
    }

    function clearSelectedActionsInTable(actionValue) {
        if (
            actionValue === REMEDIATION_ACTIONS.ISOLATE_COMPUTERS_FROM_NETWORK ||
            actionValue === REMEDIATION_ACTIONS.SCAN_COMPUTERS_FOR_MALWARE ||
            actionValue === REMEDIATION_ACTIONS.SHUTDOWN_COMPUTERS
        ) {
            const remediationActionsForComputersTemp = { ...remediationActionsForComputers };
            Object.keys(remediationActionsForComputersTemp).forEach((id) => {
                delete remediationActionsForComputersTemp[id][CHECKBOX_DATA_KEY[actionValue]];
            });
            setRemediationActionsForComputers(remediationActionsForComputersTemp);
        } else if (actionValue === REMEDIATION_ACTIONS.BLOCK_EXECUTABLES) {
            /**
             * in case: actionValue === REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES
             * do not reset, unchecking - restricts actions from 'clean & block' to 'clean' but applies to the same exe
             */
            const remediationActionsForExecutablesTemp = { ...remediationActionsForExecutables };
            Object.keys(remediationActionsForExecutablesTemp).forEach((id) => {
                delete remediationActionsForExecutablesTemp[id][CHECKBOX_DATA_KEY[actionValue]];
            });
            setRemediationActionsForExecutables(remediationActionsForExecutablesTemp);
        } else if (actionValue === REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS) {
            const remediationActionsForProcessesTemp = { ...remediationActionsForProcesses };
            Object.keys(remediationActionsForProcessesTemp).forEach((id) => {
                delete remediationActionsForProcessesTemp[id][CHECKBOX_DATA_KEY[actionValue]];
            });
            setRemediationActionsForProcesses(remediationActionsForProcessesTemp);
        }
    }

    /**************************************** SUBMIT *****************************************/
    const [submitInProgress, setSubmitInProgress] = useState(false);

    const REMEDIATION = {
        [REMEDIATION_ACTIONS.BLOCK_EXECUTABLES]: {
            request: sendBlockRequest,
            prepareData: () => prepareExecutablesDataForRequest(REMEDIATION_ACTIONS.BLOCK_EXECUTABLES),
            getPreparedForRequestData: () => getPreparedForRequestData(REMEDIATION_ACTIONS.BLOCK_EXECUTABLES),
        },
        [REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES]: {
            request: sendBlockAndCleanRequest,
            prepareData: () => prepareExecutablesDataForRequest(REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES),
            getPreparedData: () => getPreparedForRequestData(REMEDIATION_ACTIONS.CLEAN_AND_BLOCK_EXECUTABLES),
        },
        [REMEDIATION_ACTIONS.ISOLATE_COMPUTERS_FROM_NETWORK]: {
            request: sendIsolateRequest,
            prepareData: () => prepareComputersDataForRequest(REMEDIATION_ACTIONS.ISOLATE_COMPUTERS_FROM_NETWORK),
            getPreparedForRequestData: () =>
                getPreparedForRequestData(REMEDIATION_ACTIONS.ISOLATE_COMPUTERS_FROM_NETWORK),
        },
        [REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS]: {
            /**
             * do not do this request if the kill action is disabled
             * the situation occurs when the exe block is checked - kill is performed automatically on a backend - request unnecessary
             * the action is not filtered because we are informing the user about this action.
             */
            ...(shouldActionBeDisabled(ALL_REMEDIATION_ACTIONS[REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS])
                ? {
                      request: () => Promise.resolve(null),
                      prepareData: () => [],
                      getPreparedForRequestData: () => {},
                  }
                : {
                      request: sendKillRequest,
                      prepareData: () =>
                          prepareProcessesDataForRequest(REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS, false),
                      getPreparedForRequestData: () =>
                          getPreparedForRequestData(REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS),
                  }),
        },
        [REMEDIATION_ACTIONS.SCAN_COMPUTERS_FOR_MALWARE]: {
            request: sendScanRequest,
            prepareData: () => prepareComputersDataForRequest(REMEDIATION_ACTIONS.SCAN_COMPUTERS_FOR_MALWARE),
            getPreparedForRequestData: () => getPreparedForRequestData(REMEDIATION_ACTIONS.SCAN_COMPUTERS_FOR_MALWARE),
        },
        [REMEDIATION_ACTIONS.SHUTDOWN_COMPUTERS]: {
            request: sendShutdownRequest,
            prepareData: () => prepareComputersDataForRequest(REMEDIATION_ACTIONS.SHUTDOWN_COMPUTERS),
            getPreparedForRequestData: () => getPreparedForRequestData(REMEDIATION_ACTIONS.SHUTDOWN_COMPUTERS),
        },
    };

    function prepareComputersDataForRequest(actionValue, withoutDisabled = true) {
        return data.computers?.filter((computer) => {
            const currenComputerCheckboxesState =
                remediationActionsForComputers[computer.id][CHECKBOX_DATA_KEY[actionValue]];
            return (
                currenComputerCheckboxesState.checkedState === checkState.checked &&
                (withoutDisabled ? !currenComputerCheckboxesState.disabled : true)
            );
        });
    }

    function prepareExecutablesDataForRequest(actionValue, withoutDisabled = true) {
        return data.modules?.filter((module) => {
            const currenExecutableCheckboxesState =
                remediationActionsForExecutables[module.id][CHECKBOX_DATA_KEY[actionValue]];
            return (
                currenExecutableCheckboxesState.checkedState === checkState.checked &&
                (withoutDisabled ? !currenExecutableCheckboxesState.disabled : true)
            );
        });
    }

    function prepareProcessesDataForRequest(actionValue, withoutDisabled = true) {
        return data.processes?.filter((process) => {
            const currenProcessCheckboxesState =
                remediationActionsForProcesses[process.processId][CHECKBOX_DATA_KEY[actionValue]];
            return (
                currenProcessCheckboxesState.checkedState === checkState.checked &&
                (withoutDisabled ? !currenProcessCheckboxesState.disabled : true)
            );
        });
    }

    function getPreparedForRequestData(actionValue) {
        return dataPreparedForRequest[CHECKBOX_DATA_KEY[actionValue]];
    }

    function sendBlockRequest(actionName = 'BLOCK_EXECUTABLES', shouldClean = false) {
        const selectedExecutbalesSha1 = REMEDIATION[REMEDIATION_ACTIONS.BLOCK_EXECUTABLES]
            .getPreparedForRequestData()
            .map((module) => module.moduleHash.sha1);

        const requestBody = {
            hashes: selectedExecutbalesSha1,
            shouldClean: shouldClean,
            note: '',
        };

        return sendRemediationRequest(BACKEND.put, 'hashes/block', requestBody, actionName);
    }

    function sendBlockAndCleanRequest() {
        return sendBlockRequest('CLEAN_AND_BLOCK_EXECUTABLES', true);
    }

    function sendKillRequest() {
        const killRequests = REMEDIATION[REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS]
            .getPreparedForRequestData()
            .map((process) => {
                const requestBody = {
                    action: 'killProcess',
                    dbProcessId: process.processId,
                };
                return sendRemediationRequestInSerie(BACKEND.post, 'remediation', requestBody, process);
            });

        return Promise.allSettled(killRequests).then((results) => {
            const badList = results.filter((result) => result.status === 'rejected' || result.value.success === false);
            if (badList.length > 0) {
                const toastTitle = i18n('KILL_PROCESSES_ON_COMPUTERS');
                const toastMessage = (
                    <FlexContainer direction="column">
                        <Text color="white" fontWeight="bold">
                            FAILED_FOR
                        </Text>
                        <Spacer type="px-2 py-3" className="failed-list">
                            <FlexContainer className="flex-nowrap" direction="column" align="stretch">
                                <RemediationProcessesBubbles showAll processes={badList.map((bad) => bad.value.item)} />
                            </FlexContainer>
                        </Spacer>
                    </FlexContainer>
                );
                toast.error(toastMessage, { autoClose: false }, toastTitle);
            } else {
                toast.success(i18n('KILL_PROCESSES_ON_COMPUTERS'));
            }
        });
    }

    function showComputersActionErrorToast(actionName, badList) {
        const toastTitle = i18n(actionName);
        const toastMessage = (
            <FlexContainer direction="column">
                <Text color="white" fontWeight="bold">
                    FAILED_FOR
                </Text>
                <Spacer type="px-2 py-3" className="failed-list">
                    <FlexContainer className="flex-nowrap" direction="column" align="stretch">
                        <RemediationComputersBubbles showAll computers={badList.map((bad) => bad.value.item)} />
                    </FlexContainer>
                </Spacer>
            </FlexContainer>
        );
        toast.error(toastMessage, { autoClose: false }, toastTitle);
    }

    function sendIsolateRequest() {
        const isolateRequests = REMEDIATION[REMEDIATION_ACTIONS.ISOLATE_COMPUTERS_FROM_NETWORK]
            .getPreparedForRequestData()
            .map((computer) => {
                const requestBody = {
                    action: 'isolate',
                    computer: computer.id,
                };
                return sendRemediationRequestInSerie(BACKEND.post, 'remediation', requestBody, computer);
            });

        return Promise.allSettled(isolateRequests).then((results) => {
            const badList = results.filter((result) => result.status === 'rejected' || result.value.success === false);
            if (badList.length > 0) {
                showComputersActionErrorToast('ISOLATE_COMPUTERS_FROM_NETWORK', badList);
            } else {
                toast.success(i18n('ISOLATE_COMPUTERS_FROM_NETWORK'));
            }
        });
    }

    function sendScanRequest() {
        const scanRequests = REMEDIATION[REMEDIATION_ACTIONS.SCAN_COMPUTERS_FOR_MALWARE]
            .getPreparedForRequestData()
            .map((computer) => {
                const requestBody = {
                    action: 'scan',
                    computer: computer.id,
                };
                return sendRemediationRequestInSerie(BACKEND.post, 'remediation', requestBody, computer);
            });

        return Promise.allSettled(scanRequests).then((results) => {
            const badList = results.filter((result) => result.status === 'rejected' || result.value.success === false);
            if (badList.length > 0) {
                showComputersActionErrorToast('SCAN_COMPUTERS_FOR_MALWARE', badList);
            } else {
                toast.success(i18n('SCAN_COMPUTERS_FOR_MALWARE'));
            }
        });
    }

    function sendShutdownRequest() {
        const scanRequests = REMEDIATION[REMEDIATION_ACTIONS.SHUTDOWN_COMPUTERS]
            .getPreparedForRequestData()
            .map((computer) => {
                const requestBody = {
                    action: 'shutdown',
                    computer: computer.id,
                };
                return sendRemediationRequestInSerie(BACKEND.post, 'remediation', requestBody, computer);
            });

        return Promise.allSettled(scanRequests).then((results) => {
            const badList = results.filter((result) => result.status === 'rejected' || result.value.success === false);
            if (badList.length > 0) {
                showComputersActionErrorToast('SHUTDOWN_COMPUTERS', badList);
            } else {
                toast.success(i18n('SHUTDOWN_COMPUTERS'));
            }
        });
    }

    function sendRemediationRequest(method, url, requestBody, actionName) {
        return method(url, requestBody, props.componentUuid)
            .onStatus(400, (method, url, response) => handleRemediationBadRequest(method, url, response, actionName))
            .onStatus(500, (method, url, response) =>
                handleRemediationInternalServerError(method, url, response, actionName)
            )
            .success(() => toast.success(i18n(actionName)))
            .execute();
    }

    function sendRemediationRequestInSerie(method, url, requestBody, item) {
        return method(url, requestBody, props.componentUuid)
            .failure(() => ({ success: false, item }))
            .success(() => ({ success: true }))
            .execute();
    }

    function prepareConfirmationData() {
        setDataPreparedForRequest(
            getSelectedActions().reduce((actionsPrepared, action) => {
                return {
                    ...actionsPrepared,
                    [CHECKBOX_DATA_KEY[action.value]]: REMEDIATION[action.value].prepareData(),
                };
            }, {})
        );
    }

    function runSelectedRemediationRequests() {
        return getSelectedActionsThatHaveData().map((action) => REMEDIATION[action.value].request());
    }

    function handleSubmit() {
        setSubmitInProgress(true);
        Promise.allSettled(runSelectedRemediationRequests())
            .then(() => {
                props.close();
            })
            .finally(() => {
                setSubmitInProgress(false);
            });
    }

    /**************************************** PAGES ****************************************/
    const [page, setPage] = useState(0);

    function nextPage() {
        pages[page + 1].prepareDataBefore?.();
        setPage(page + 1);
    }

    function previousPage() {
        setPage(page - 1);
    }

    function resetView() {
        setRemediationActions(getAllCheckboxesDisabled());
        setRemediationActionsForComputers({});
        setRemediationActionsForExecutables({});
        setRemediationActionsForProcesses({});
        setPage(0);
    }

    const showProcessesPage =
        isActionChecked(REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS) &&
        !shouldActionBeDisabled(ALL_REMEDIATION_ACTIONS[REMEDIATION_ACTIONS.KILL_PROCESSES_ON_COMPUTERS]);

    const showExecutablesPage = blockExecutableActionSelected(getSelectedActions());

    const confirmationType = !showExecutablesPage
        ? REMEDIATION_CONFIRMATION_TYPE.DEFAULT
        : containsSafeExecutablesToBlock
        ? REMEDIATION_CONFIRMATION_TYPE.DANGER
        : REMEDIATION_CONFIRMATION_TYPE.BLOCK;

    const showComputersPage =
        isActionChecked(REMEDIATION_ACTIONS.ISOLATE_COMPUTERS_FROM_NETWORK) ||
        isActionChecked(REMEDIATION_ACTIONS.SCAN_COMPUTERS_FOR_MALWARE) ||
        isActionChecked(REMEDIATION_ACTIONS.SHUTDOWN_COMPUTERS);

    const remediationHeader = [
        {
            label: <Text fontWeight="bold">COMPUTERS</Text>,
            content: (
                <FlexContainer direction="row" align="center">
                    <RemediationComputersBubbles computers={data.computers} />
                </FlexContainer>
            ),
        },
        {
            label: <Text fontWeight="bold">EXECUTABLES</Text>,
            content: (
                <FlexContainer direction="row" align="center">
                    <RemediationModulesBubblesSection modules={data.modules} />
                </FlexContainer>
            ),
        },
        {
            label: <Text fontWeight="bold">PROCESSES</Text>,
            content: (
                <FlexContainer direction="row" align="center">
                    <RemediationProcessesBubbles processes={data.processes} />
                </FlexContainer>
            ),
        },
    ];

    const remediationContent = [
        {
            label: <Text fontWeight="bold">PREVENT_SPREAD</Text>,
            content: (
                <RemediationCheckboxGroup
                    actions={PROTECT_NETWORK_ACTIONS}
                    shouldActionBeDisplayed={() => true}
                    shouldActionBeDisabled={(action) => shouldActionBeDisabled(action)}
                    isChecked={isActionChecked}
                    onChange={toggleAction}
                />
            ),
        },
        {
            label: <Text fontWeight="bold">PROTECT_DEVICES</Text>,
            content: (
                <RemediationCheckboxGroup
                    actions={PROTECT_COMPUTER_ACTIONS}
                    shouldActionBeDisplayed={() => true}
                    shouldActionBeDisabled={(action) => shouldActionBeDisabled(action)}
                    isChecked={isActionChecked}
                    onChange={toggleAction}
                />
            ),
        },
    ];

    const remediationButtons = [
        <Button type="primary" text="REVIEW_ACTIONS" onClick={nextPage} disabled={shouldRemediationBeDisabled()} />,
        <Button type="secondary" text="CANCEL" onClick={props.close} />,
    ];

    const navigationButtons = [
        <Button type="primary" text="NEXT" onClick={nextPage} />,
        <Button type="secondary" text="BACK" onClick={previousPage} />,
    ];

    const confirmationButtons = getRemediationConfirmationButtons(confirmationType, {
        onSubmit: handleSubmit,
        onClose: props.close,
        disableSubmit: !getSelectedActionsThatHaveData()?.length || submitInProgress,
    });

    const pages = [
        {
            label: 'REMEDIATE_INCIDENT',
            buttons: remediationButtons,
        },
        ...(showComputersPage
            ? [
                  {
                      prepareDataBefore: prepareComputersData,
                      label: 'REMEDIATE_COMPUTERS',
                      content: (
                          <ComputersTable
                              data={data.computers}
                              settings={{
                                  getActions: getSingleComputerActionsForReview,
                                  onChange: onSingleComputerReviewActionChange,
                              }}
                          />
                      ),
                      buttons: navigationButtons,
                  },
              ]
            : []),
        ...(showExecutablesPage
            ? [
                  {
                      prepareDataBefore: prepareExecutablesData,
                      label: 'REMEDIATE_EXECUTABLES',
                      content: (
                          <ExecutablesTable
                              containsSafeExecutablesToBlock={containsSafeExecutablesToBlock}
                              containsSafeExecutables={containsSafeExecutables}
                              data={data.modules}
                              settings={{
                                  getActions: getSingleExecutableActionsForReview,
                                  onChange: onSingleExecutableReviewActionChange,
                              }}
                          />
                      ),
                      buttons: navigationButtons,
                  },
              ]
            : []),
        ...(showProcessesPage
            ? [
                  {
                      prepareDataBefore: prepareProcessesData,
                      label: 'REMEDIATE_PROCESSES',
                      content: (
                          <ProcessTable
                              data={data.processes}
                              settings={{
                                  getActions: getSingleProcessActionsForReview,
                                  onChange: onSingleProcessReviewActionChange,
                              }}
                          />
                      ),
                      buttons: navigationButtons,
                  },
              ]
            : []),
        {
            prepareDataBefore: prepareConfirmationData,
            label: getRemediationConfirmationTitle(confirmationType),
            content: (
                <Fragment>
                    <Progress showProgress={submitInProgress} />
                    <RemediationConfirmation actions={getSelectedActionsThatHaveData()} type={confirmationType} />
                </Fragment>
            ),
            buttons: confirmationButtons,
            type: confirmationType === REMEDIATION_CONFIRMATION_TYPE.DANGER ? 'error' : 'warning',
        },
    ];

    return (
        <ActionsModal
            isLoading={true}
            show={props.show}
            onClose={props.close}
            onHidden={resetView}
            title={pages[page].label}
            buttons={pages[page].buttons}
            remediationHeader={remediationHeader}
            remediationContent={remediationContent}
            type={pages[page].type || 'simple'}
        >
            {isLoading ? <Loading show /> : pages[page].content}
        </ActionsModal>
    );
}

export default withBackend(RemediationModalIncident);
