import { useState, useEffect } from 'react';

import Select from 'uilib-wrappers/select';

import { BACKEND, withBackend } from 'Services/backend';

function WebConsoleUserSelect(props) {
    const [users, setUsers] = useState([]);
    const [menuPortal, setMenuPortal] = useState(null);

    useEffect(() => {
        setMenuPortal(document.getElementById(props.modalId));

        BACKEND.get('web-console-users', props.componentUuid)
            .success((newUsers) => {
                setUsers(newUsers);
            })
            .execute();
    }, []);

    return (
        <Select
            menuPortalTarget={menuPortal}
            options={users.map((user) => ({ value: user.uuid, label: user.name }))}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
        />
    );
}

export default withBackend(WebConsoleUserSelect);
