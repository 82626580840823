import { useRef, useState, useEffect } from 'react';

import moment from 'moment';
import $ from 'jquery';

import 'daterangepicker';
import 'daterangepicker/daterangepicker.css';

import i18n from 'Services/i18n';

import './index.scss';

function DateRangePicker(props) {
    const dateRangePickerRef = useRef(null);
    const [label, setLabel] = useState('');

    useEffect(() => {
        function updateLabel(value) {
            let label = '';
            if (
                value.timeRange &&
                value.timeRange !==
                    (props.options.getTimeRangeKey?.(props.options.locale.customRangeLabel) ||
                        props.options.locale.customRangeLabel)
            ) {
                // If preset was selected then display its name for all operators.
                label = value.timeRange;
            } else if (props.operator === undefined) {
                // Task wizard should always display a range in case of a custom one.
                const START_DATE_STR = moment(value.startDate).format(props.options.locale.format);
                const END_DATE_STR = moment(value.endDate).format(props.options.locale.format);

                label = `${START_DATE_STR} ${props.options.locale.separator || '-'} ${END_DATE_STR}`;
            } else if (props.operator === 'EQ') {
                // Show duration in case of range and a date in case of time point.
                const START_DATE = moment(value.startDate);
                const END_DATE = moment(value.endDate);

                label = START_DATE.isSame(END_DATE)
                    ? START_DATE.format(props.options.locale.format)
                    : moment.duration(END_DATE.diff(START_DATE)).humanize();
            } else {
                label = moment(props.operator === 'GE' ? value.startDate : value.endDate).format(
                    props.options.locale.format
                );
            }
            setLabel(props.options.translateTimeRange?.(label) || i18n(label));
        }

        const PICKER = $(dateRangePickerRef.current);
        PICKER.daterangepicker(props.options);

        PICKER.on('apply.daterangepicker', (ev, picker) => {
            const chosenLabel = props.options.getTimeRangeKey?.(picker.chosenLabel) || picker.chosenLabel;

            const START_DATE =
                picker.chosenLabel !== undefined && props.options.ranges[chosenLabel] !== undefined
                    ? props.options.ranges[chosenLabel][0]
                    : picker.startDate !== null
                    ? moment(picker.startDate)
                    : null;

            // Check showCustomRangeLabel field which is used to distinguish between dashboard time filter and non-dashboard time filter.
            const END_DATE =
                picker.chosenLabel !== undefined && props.options.ranges[chosenLabel] !== undefined
                    ? props.options.ranges[chosenLabel][1]
                    : picker.endDate !== null
                    ? moment(picker.endDate)
                    : null;

            const value = {
                timeRange: props.options.getTimeRangeKey?.(picker.chosenLabel) || picker.chosenLabel,
                startDate: START_DATE !== null ? START_DATE.toDate() : null,
                endDate: END_DATE !== null ? END_DATE.toDate() : null,
            };
            updateLabel(value);
            props.onChange(value);
        });

        if (props.value) {
            updateLabel(props.value);
        }
    }, [props]);

    return <div ref={dateRangePickerRef}>{props.template !== undefined ? props.template(label) : label}</div>;
}

export default DateRangePicker;
