import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ProgressLayout from './layout';

export const PROGRESS_DELAY_MS = 200;

export function moduleFunctions({ setShow }) {
    function onLoad() {
        const progressTimer = window.setTimeout(() => {
            setShow(true);
        }, PROGRESS_DELAY_MS);

        return () => {
            window.clearTimeout(progressTimer);
        };
    }

    return { onLoad };
}

function ProgressModule(props) {
    const [show, setShow] = useState(props.instant); // Hide progress by default.
    const { onLoad } = moduleFunctions({ setShow });

    useEffect(() => {
        return onLoad();
    }, [onLoad]);

    return props.showProgress ? <ProgressLayout dim={props.dim} show={show && !props.table} /> : null;
}

ProgressModule.propTypes = {
    showProgress: PropTypes.bool.isRequired,

    dim: PropTypes.bool,
    instant: PropTypes.bool,
    table: PropTypes.bool,
};

ProgressModule.defaultProps = {
    dim: false,
    instant: false,
    table: false,
};

export default ProgressModule;
