import Spacer from '@uilib/core/Spacer/Spacer';

import Text from 'uilib-wrappers/text';

import { Fragment } from 'react';

function LearningModeDescription(props) {
    return (
        <Fragment>
            <Spacer type="py-2">
                <Text>
                    LEARNING_MODE_AUTOMATICALLY_CREATES_EXCLUSIONS_TO_ADAP_ENTERPRISE_INSPECTOR_TO_YOUR_NETWORK_FOR_A_CERTAIN_TIME
                </Text>
                <Text>
                    ANY_DETECTION_DURING_LEARNING_MODE_IS_ASSUMED_TO_BE_A_FALSE_POSITIVE_AND_A_CORRESPONDING_EXCLUSION_WILL_BE_CREATED
                </Text>
            </Spacer>

            <Spacer type="py-2">
                <Text fontWeight="bold">IMPORTANT</Text>
                <Text>IF_A_REAL_THREAT_APPEARS_DURING_LEARNING_MODE_IT_MAY_BE_FALSELY_EXCLUDED</Text>
            </Spacer>

            <Spacer type="py-2">
                <Text>CHOOSE_A_SET_OF_TYPICAL_COMPUTERS_THAT_ARE_CERTAIN_NOT_TO_BE_INFECTED</Text>
            </Spacer>
        </Fragment>
    );
}

export default LearningModeDescription;
