import './index.scss';
import REPUTATION_ICON from 'Assets/eei-reputation.svg';
import POPULARITY_ICON from 'Assets/eei-popularity.svg';

export function Reputation(props) {
    return (
        <div
            className={`ei-reputation-icon-${props.reputation}`}
            style={{ backgroundImage: `url(${REPUTATION_ICON})` }}
        ></div>
    );
}

export function Popularity(props) {
    return (
        <div
            className={`ei-popularity-icon-${props.popularity}`}
            style={{ backgroundImage: `url(${POPULARITY_ICON})` }}
        ></div>
    );
}
