import { Component } from 'react';
import i18n from 'Services/i18n';

const APP_FALLBACK_ROUTE = '/dashboard';

// According to ReactJS error boundaries do not catch all errors but still it's better than nothing.
// For more information check the page: https://reactjs.org/docs/error-boundaries.html
class AppErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { asDeadAsTheDodo: false };
    }

    static getDerivedStateFromError(error) {
        return { asDeadAsTheDodo: true };
    }

    componentDidCatch(error, errorInfo) {
        // We can ignore error arguments as they are sent to the console error by default.

        // This will prevent the user from accessing the rest of the program's interface until the dialog box is closed.
        window.alert(i18n('INTERNAL_FRONTEND_ERROR'));

        // Redirect user to the fallback route thorugh the HREF change - this will reload whole application from the server.
        window.location.href = APP_FALLBACK_ROUTE;
    }

    render() {
        return this.state.asDeadAsTheDodo ? null : this.props.children;
    }
}

export default AppErrorBoundary;
