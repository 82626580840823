import { forwardRef } from 'react';
import Table from '@uilib/business-components/Table/Table/Table';

import i18n from 'Services/i18n';

const TableWrapper = forwardRef((props, ref) => {
    return (
        <Table
            {...props}
            ref={ref}
            columns={props.columns.map((column) => ({
                ...column,
                header: i18n(column.header),
                title: i18n(column.title),
            }))}
            headerContextMenu={
                props.headerContextMenu
                    ? props.headerContextMenu.map(({ header, items }) => ({
                          header: i18n(header),
                          items: items.map((item) => ({
                              ...item,
                              title: i18n(item.title),
                          })),
                      }))
                    : undefined
            }
            noDataActionText={i18n(props.noDataActionText)}
            noDataText={i18n(props.noDataText)}
            noDataTitle={i18n(props.noDataTitle)}
        />
    );
});

export default TableWrapper;
