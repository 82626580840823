import PropTypes from 'prop-types';
import classnames from 'classnames';

import SplitPane from '@uilib/business-components/SplitPane';
import { Fragment } from 'react';
import ReactResizeDetector from 'react-resize-detector';

function SplitPaneWrapper(props) {
    const minSizes = props.minSize || [20, 20];

    function calculateAllowableSize(size) {
        if (size >= minSizes[0]) {
            if (size >= 100 - minSizes[1]) {
                return 100 - minSizes[1];
            } else {
                return size;
            }
        } else {
            return minSizes[0];
        }
    }

    return props.removeSplitPane ? (
        <Fragment>{props.children}</Fragment>
    ) : (
        <ReactResizeDetector handleWidth handleHeight>
            {({ width, height }) => {
                const firstPaneRatio = props.forceFirstPaneRatio
                    ? props.firstPaneRatio
                    : calculateAllowableSize(props.firstPaneRatio);
                return (
                    <SplitPane
                        className={classnames('ui-fix-split-pane', {
                            'ui-fix-split-pane--no-resizer': firstPaneRatio === 0 || firstPaneRatio === 100,
                            'expand-width-after-remove': props.expandWidthAfterRemove,
                        })}
                        removeOnZeroSize={false}
                        split={props.horizontal ? 'horizontal' : 'vertical'}
                        overflow={props.overflow}
                        initialFirstChildSize={firstPaneRatio}
                        children={props.children}
                        resizeDetector={{ width, height }}
                        minSizes={minSizes}
                        parentSize={props.horizontal ? height : width}
                        onResized={props.savePaneRatio}
                        units="%"
                    />
                );
            }}
        </ReactResizeDetector>
    );
}

//-----------------------------------------------------------------------------
SplitPaneWrapper.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    horizontal: PropTypes.bool,
    overflow: PropTypes.string,
    firstPaneRatio: PropTypes.number,
};

//-----------------------------------------------------------------------------
SplitPaneWrapper.defaultProps = {
    horizontal: false,
    overflow: 'hidden',
    firstPaneRatio: 50,
};

export default SplitPaneWrapper;
