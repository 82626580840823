class Subscription {
    constructor(id, callback) {
        this.id = id;
        this.callback = callback;
    }
}

class Message {
    constructor(message) {
        this.message = message;
        this.subscriptions = {};
        this.nextId = 0;
    }

    subscribe = (callback) => {
        const subscriptionId = this.nextId++;
        const subscription = new Subscription(subscriptionId, callback);
        this.subscriptions[subscriptionId] = subscription;
        return () => this.unsubscribe(subscriptionId);
    };

    unsubscribe = (id) => {
        delete this.subscriptions[id];
    };

    notify = (payload) => {
        Object.values(this.subscriptions).forEach((subscription) => {
            subscription.callback(this.message, payload);
        });
    };
}

class Eventing {
    constructor() {
        this.messages = {};
    }

    subscribe = (message, callback) => {
        const msg = this.messages[message] || (this.messages[message] = new Message(message));
        return msg.subscribe(callback);
    };

    unsubscribe = (message, id) => {
        if (this.messages[message]) {
            this.messages[message].unsubscribe(id);
        }
    };

    publish = (message, payload) => {
        if (this.messages[message]) {
            this.messages[message].notify(payload);
        }
    };
}

const EVENT = new Eventing();
const EventNames = {
    LEARNING_MODE_EVENT: 'LearningModeEvent',

    NAVIGATION_BAR_CHANGED_EVENT: 'NavigationBarChangedEvent',

    GROUP_FILTER_CHANGED_EVENT: 'GroupFilterChangedEvent',
    LOCAL_FILTERS_CHANGED_EVENT: 'LocalFiltersChangedEvent',

    COMMANDING_ACTION_CALLED_EVENT: 'CommandingActionCalledEvent',

    TABLE_COLUMNS_VISIBILITY_CHANGED_EVENT: 'TableColumnsVisibilityChangedEvent',

    REMEDIATION_REBOOT_EVENT: 'RemediationRebootEvent',
    REMEDIATION_SHUTDOWN_EVENT: 'RemediationShutdownEvent',
    REMEDIATION_KILL_EVENT: 'RemediationKillEvent',
    REMEDIATION_SCAN_EVENT: 'RemediationScanEvent',
    REMEDIATION_SYNC_EVENT: 'RemediationSyncEvent',
    REMEDIATION_SYSINSPECTORLOG_EVENT: 'RemediationSysInspectorLogEvent',
    REMEDIATION_ISOLATE_EVENT: 'RemediationIsolateEvent',
    REMEDIATION_INTEGRATE_EVENT: 'RemediationIntegrateEvent',
    REMEDIATION_LOGOUT_EVENT: 'RemediationLogOutEvent',

    BACKEND_ALERT_EVENT: 'BackendAlertEvent',

    SIMPLE_ALERT_EVENT: 'SimpleAlertEvent',
    LOGOUT_EVENT: 'LogoutEvent',
    USER_AUTHENTICATED_EVENT: 'UserAuthenticatedEvent',

    AUTHORIZATION_TOKEN_EVENT: 'AuthorizationTokenEvent',

    INCIDENT_MODIFIED_EVENT: 'IncidentModifiedEvent',
    DETAILS_MODIFIED_EVENT: 'DetailsModifiedEvent',

    SHOW_PRODUCT_TOUR_EVENT: 'ShowProductTourEvent',

    UPDATE_PROCESS_TREE_EVENT: 'UpdateProcessTreeEvent',

    ASYNCHRONOUS_WORKER_FOR_REQUEST_EVENT: 'AsynchronousWorkerForRequestEvent',
    REQUEST_CANCELLATION_EVENT: 'RequestCancellationEvent',
    REQUEST_CANCELLED_EVENT: 'RequestCancelledEvent',

    ASSISTANT_EVENT: 'AssistantEvent',
};

export { EVENT, EventNames };
