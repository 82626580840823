import { BACKEND } from 'Services/backend';
import { THEMES } from 'Services/darkreader';

const SETTINGS_ROOT_URL = 'users/settings/';

const SETTINGS = {
    DATE_TYPE: 'DATE_TYPE',
    LAST_URL: 'LAST_URL',
    NAVBAR: 'NAVBAR',
    GROUP_FILTER: 'GROUP_FILTER',
    PROCESS_TREE: 'PROCESS_TREE',
    SKIN: 'SKIN',
};

const SETTINGS_SEUTUP = {
    [SETTINGS.DATE_TYPE]: { url: 'dateType', defaultValue: 'Absolute' },
    [SETTINGS.LAST_URL]: { url: 'lastUrl', defaultValue: null },
    [SETTINGS.NAVBAR]: { url: 'navbar', defaultValue: { expanded: true } },
    [SETTINGS.GROUP_FILTER]: {
        url: 'groupFilter',
        defaultValue: { groupId: 1, groupName: 'ALL_COMPUTERS', parentGroupId: null },
    },
    [SETTINGS.PROCESS_TREE]: { url: 'processTree', defaultValue: { firstPaneRatio: 70 } },
    [SETTINGS.SKIN]: { url: 'skin', defaultValue: { theme: THEMES.AUTO } },
};

function getDefaultSetting(setting) {
    return SETTINGS_SEUTUP[setting].defaultValue;
}

function getSetting(setting, componentUuid) {
    return BACKEND.get(SETTINGS_ROOT_URL + SETTINGS_SEUTUP[setting].url, componentUuid);
}

function putSetting(setting, payload, componentUuid) {
    return BACKEND.put(SETTINGS_ROOT_URL + SETTINGS_SEUTUP[setting].url, payload, componentUuid);
}

export { SETTINGS, getDefaultSetting, getSetting, putSetting };
