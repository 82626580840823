/**
 * @class RequestBuilder
 * @type {Object}
 * @property {object} backend
 * @property {object} request
 * @property {object} handlers
 */
class RequestBuilder {
    constructor(backend) {
        this.backend = backend;
        this.request = null;
        this.handlers = {};
    }

    /**
     * registers custom callback for response status(es) for given request
     * @param {(number|number[])} status number or array of numbers of response status to handle
     * @param {function} callback callback for given response status
     */
    onStatus = (status, callback) => {
        if (!Array.isArray(status)) {
            status = [status];
        }
        for (const s of status) {
            this.handlers[s] = callback;
        }
        return this;
    };

    /**
     * registers handler called when request finishes with success
     * @param   {function} callback
     * @returns {RequestBuilder} same instance of request builder
     */
    success = (callback) => {
        this.handlers['success'] = callback;
        return this;
    };

    /**
     * registers handler called when request finishes with failure
     * @param   {function} callback
     * @returns {RequestBuilder} same instance of request builder
     */
    failure = (callback) => {
        this.handlers['failure'] = callback;
        return this;
    };

    /**
     * registers handler called always at the end of request
     * @param   {function} callback
     * @returns {RequestBuilder} same instance of request builder
     */
    always = (callback) => {
        this.handlers['always'] = callback;
        return this;
    };

    /**
     * enables handling async requests via asyncWorker on 202 code
     * @returns {RequestBuilder} same instance of request builder
     */
    withAsync = () => {
        this.handlers['async'] = true;
        return this;
    };

    /**
     * adds ability to cancel database operations started by request
     * @returns {RequestBuilder} same instance of request builder
     */
    cancellable = () => {
        this.handlers['cancellable'] = true;
        return this;
    };

    /**
     * executes the request
     * @returns {Promise} promise indicating finish of request
     */
    execute = () => {
        return this.request(this.handlers);
    };

    /**
     * defines the GET request
     * @override
     * @param   {string} path - url
     * @param   {string} componentUuid - unique id of component
     * @param   {bool}   skipTokenUpdate - defines if token update should be skipped
     * @returns {RequestBuilder} same instance of request builder
     */
    get = (path, componentUuid, skipTokenUpdate) => {
        if (this.request) {
            throw new Error('Only one request type definition allowed.');
        }
        this.request = async (handlers) => await this.backend.get(path, componentUuid, skipTokenUpdate, handlers);
        return this;
    };

    /**
     * defines the POST request
     * @param   {string} path - url
     * @param   {object} data - data attached to request
     * @param   {string} componentUuid - unique id of component
     * @param   {bool}   skipTokenUpdate - defines if token update should be skipped
     * @returns {RequestBuilder} same instance of request builder
     */
    post = (path, data, componentUuid, skipTokenUpdate) => {
        if (this.request) {
            throw new Error('Only one request type definition allowed.');
        }
        this.request = async (handlers) =>
            await this.backend.post(path, data, componentUuid, skipTokenUpdate, handlers);
        return this;
    };

    /**
     * defines the PUT request
     * @param   {string} path - url
     * @param   {object} data - data attached to request
     * @param   {string} componentUuid - unique id of component
     * @param   {bool}   skipTokenUpdate - defines if token update should be skipped
     * @returns {RequestBuilder} same instance of request builder
     */
    put = (path, data, componentUuid, skipTokenUpdate) => {
        if (this.request) {
            throw new Error('Only one request type definition allowed.');
        }
        this.request = async (handlers) => await this.backend.put(path, data, componentUuid, skipTokenUpdate, handlers);
        return this;
    };

    /**
     * defines the DELETE request
     * @param   {string} path - url
     * @param   {object} data - data attached to request
     * @param   {string} componentUuid - unique id of component
     * @param   {bool}   skipTokenUpdate - defines if token update should be skipped
     * @returns {RequestBuilder} same instance of request builder
     */
    delete = (path, data, componentUuid, skipTokenUpdate) => {
        if (this.request) {
            throw new Error('Only one request type definition allowed.');
        }
        this.request = async (handlers) =>
            await this.backend.delete(path, data, componentUuid, skipTokenUpdate, handlers);
        return this;
    };
}

export default RequestBuilder;
