/** CLOUD */
import EIC_A_1 from 'Assets/product-tour/cloud/EIC_A_1.png';
import EIC_A_2 from 'Assets/product-tour/cloud/EIC_A_2.png';
import EIC_A_3 from 'Assets/product-tour/cloud/EIC_A_3.png';
import EIC_A_4 from 'Assets/product-tour/cloud/EIC_A_4.png';

import EIC_B_1 from 'Assets/product-tour/cloud/EIC_B_1.png';
import EIC_B_2 from 'Assets/product-tour/cloud/EIC_B_2.png';
import EIC_B_3 from 'Assets/product-tour/cloud/EIC_B_3.png';

import EIC_C_1 from 'Assets/product-tour/cloud/EIC_C_1.png';
import EIC_C_2 from 'Assets/product-tour/cloud/EIC_C_2.png';
import EIC_C_3 from 'Assets/product-tour/cloud/EIC_C_3.png';
import EIC_C_4 from 'Assets/product-tour/cloud/EIC_C_4.png';

import EIC_D_1 from 'Assets/product-tour/cloud/EIC_D_1.png';

import EIC_E_1 from 'Assets/product-tour/cloud/EIC_E_1.png';
import EIC_E_2 from 'Assets/product-tour/cloud/EIC_E_2.png';
import EIC_E_3 from 'Assets/product-tour/cloud/EIC_E_3.png';
import EIC_E_4 from 'Assets/product-tour/cloud/EIC_E_4.png';
import EIC_E_5 from 'Assets/product-tour/cloud/EIC_E_5.png';

import EIC_F_1 from 'Assets/product-tour/cloud/EIC_F_1.png';
import EIC_F_2 from 'Assets/product-tour/cloud/EIC_F_2.png';
import EIC_F_3 from 'Assets/product-tour/cloud/EIC_F_3.png';

import EIC_G_1 from 'Assets/product-tour/cloud/EIC_G_1.png';

/** ON-PREM */
import EI_A_1 from 'Assets/product-tour/on-prem/EI_A_1.png';
import EI_A_2 from 'Assets/product-tour/on-prem/EI_A_2.png';
import EI_A_3 from 'Assets/product-tour/on-prem/EI_A_3.png';
import EI_A_4 from 'Assets/product-tour/on-prem/EI_A_4.png';

import EI_B_C_1 from 'Assets/product-tour/on-prem/EI_B_C_1.png';
import EI_B_C_2 from 'Assets/product-tour/on-prem/EI_B_C_2.png';

import EI_D_1 from 'Assets/product-tour/on-prem/EI_D_1.png';

import EI_E_1 from 'Assets/product-tour/on-prem/EI_E_1.png';
import EI_E_2 from 'Assets/product-tour/on-prem/EI_E_2.png';
import EI_E_3 from 'Assets/product-tour/on-prem/EI_E_3.png';
import EI_E_4 from 'Assets/product-tour/on-prem/EI_E_4.png';
import EI_E_5 from 'Assets/product-tour/on-prem/EI_E_5.png';

import EI_F_1 from 'Assets/product-tour/on-prem/EI_F_1.png';
import EI_F_2 from 'Assets/product-tour/on-prem/EI_F_2.png';
import EI_F_3 from 'Assets/product-tour/on-prem/EI_F_3.png';

import EI_G_1 from 'Assets/product-tour/on-prem/EI_G_1.png';

export class ProductTourImages {
    constructor(isCloud) {
        this.A_1 = isCloud ? EIC_A_1 : EI_A_1;
        this.A_2 = isCloud ? EIC_A_2 : EI_A_2;
        this.A_3 = isCloud ? EIC_A_3 : EI_A_3;
        this.A_4 = isCloud ? EIC_A_4 : EI_A_4;

        this.B_1 = EIC_B_1;
        this.B_2 = EIC_B_2;
        this.B_3 = EIC_B_3;

        this.C_1 = EIC_C_1;
        this.C_2 = EIC_C_2;
        this.C_3 = EIC_C_3;
        this.C_4 = EIC_C_4;

        this.B_C_1 = EI_B_C_1;
        this.B_C_2 = EI_B_C_2;

        this.D_1 = isCloud ? EIC_D_1 : EI_D_1;

        this.E_1 = isCloud ? EIC_E_1 : EI_E_1;
        this.E_2 = isCloud ? EIC_E_2 : EI_E_2;
        this.E_3 = isCloud ? EIC_E_3 : EI_E_3;
        this.E_4 = isCloud ? EIC_E_4 : EI_E_4;
        this.E_5 = isCloud ? EIC_E_5 : EI_E_5;

        this.F_1 = isCloud ? EIC_F_1 : EI_F_1;
        this.F_2 = isCloud ? EIC_F_2 : EI_F_2;
        this.F_3 = isCloud ? EIC_F_3 : EI_F_3;

        this.G_1 = isCloud ? EIC_G_1 : EI_G_1;
    }
}
export default ProductTourImages;
