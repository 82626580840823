import { useEffect, useState, useRef, Fragment } from 'react';
import Theme from '@uilib/business-components/_utils/theme';
import { checkState } from '@uilib/business-components/Checkbox';
import Button from 'uilib-wrappers/button';
import Modal from 'uilib-wrappers/modal';

import { BACKEND, withBackend } from 'Services/backend';
import LearningModeSettings from './settings';
import LearningModeDescription from './description';
import moment from 'moment';
import { EVENT, EventNames } from 'Services/Eventing';
import LearningModeNotification from './learning-mode-notification';
import Progress from 'Bricks/Progress';

const MODAL_WINDOW_ID = 'eid-learning-mode-settings';
const NUMBER_OF_DAYS_IN_WEEK = 7;
const LEARNING_PERIODS = [
    { value: 1, label: '1_WEEK' },
    { value: 2, label: '2_WEEKS' },
    { value: 3, label: '3_WEEKS' },
];

function LearningModeModal(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [showProgress, setShowProgress] = useState(false);

    const [validStatus, setValidStatus] = useState(true);
    const [learningPeriod, setLearningPeriod] = useState({ value: 2, label: '2_WEEKS' });
    const [dataRetentionPeriod, setDataRetentionPeriod] = useState(props.dataRetentionPeriod);
    const computersRef = useRef();
    const [autoEnableExclusionsSelection, setAutoEnableExclusionsSelectionChange] = useState(checkState.checked);

    function handleComputersSelectionChange(selectedComputers, isAllSelected) {
        setValidStatus(!(selectedComputers.length === 0 && !isAllSelected));
    }

    function handlePeriodChange(learningPeriod) {
        setLearningPeriod(learningPeriod);
    }

    function handleClose() {
        setModalOpen(false);
    }

    function handleExitLearningMode() {
        if (settingsOpen) {
            const yesterdaysDateToTurnOff = moment.utc().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
            BACKEND.post('config', { learningModeEnd: yesterdaysDateToTurnOff }, props.componentUuid)
                .success(() => {
                    EVENT.publish(EventNames.LEARNING_MODE_EVENT, {
                        enable: false,
                    });
                })
                .execute();
        }

        setModalOpen(false);
    }

    function handleEnableLearningModeAllComputers() {
        const startDate = calculateLearningPeriodStartDate();
        const endDate = calculateLearningPeriodStart()
            .add(7, 'days')
            .subtract(5, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss');

        const allComputersRequestBody = {
            groupId: 1,
            localFilters: {
                filterTree: {
                    status: {
                        NE: '-1',
                    },
                },
                subgroups: true,
            },
            selection: [
                {
                    column: 'id',
                    groupColumn: '',
                    groupValue: '',
                    selectAll: true,
                    uniqueIds: [],
                },
            ],
        };

        handleEnableComputersImpl(startDate, endDate, allComputersRequestBody);
    }

    function handleSelectComputers() {
        if (dataRetentionPeriod) {
            setSettingsOpen(true);
        } else {
            getEventsAndProcessesStorageDays();
        }
    }

    function getEventsAndProcessesStorageDays() {
        BACKEND.get('config/eventsAndProcessesStorageDays', props.componentUuid)
            .success((response) => {
                setDataRetentionPeriod(response);
                setSettingsOpen(true);
                if (response < LEARNING_PERIODS[1].value * NUMBER_OF_DAYS_IN_WEEK) {
                    setLearningPeriod(LEARNING_PERIODS[0]);
                } else {
                    setLearningPeriod(LEARNING_PERIODS[1]);
                }
            })
            .execute();
    }

    function getLearningModePeriods() {
        return LEARNING_PERIODS.filter((period) => period.value * NUMBER_OF_DAYS_IN_WEEK <= dataRetentionPeriod);
    }

    function calculateLearningPeriodStart() {
        return moment().startOf('day').add(1, 'days').utc();
    }

    function calculateLearningPeriodStartDate() {
        return calculateLearningPeriodStart().add(5, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    }

    function calculateLearningPeriodEndDate() {
        return dataRetentionPeriod <= NUMBER_OF_DAYS_IN_WEEK
            ? calculateLearningPeriodStart()
                  .add(dataRetentionPeriod, 'days')
                  .subtract(5, 'minutes')
                  .format('YYYY-MM-DD HH:mm:ss')
            : calculateLearningPeriodStart()
                  .add(learningPeriod.value, 'weeks')
                  .subtract(5, 'minutes')
                  .format('YYYY-MM-DD HH:mm:ss');
    }

    function handleApply() {
        if (validStatus) {
            const startDate = calculateLearningPeriodStartDate();
            const endDate = calculateLearningPeriodEndDate();

            handleEnableComputersImpl(startDate, endDate, computersRef.current.getSelectionRequestBody());
        }
    }

    function handleEnableComputersImpl(startDate, endDate, learningModeRequestBody) {
        const requestBody = {
            learningModeStart: startDate,
            learningModeEnd: endDate,
            learningModeAutoEnableExclusions: autoEnableExclusionsSelection === checkState.checked ? '1' : '0',
        };

        const resetConfigAutoxclusionsCounter = BACKEND.delete(
            'config/learningModeAutoExclusionsCount',
            {},
            props.componentUuid
        )
            .success(() => {
                return true;
            })
            .execute();

        const resetConfigSummaryFlagRequest = BACKEND.delete(
            'config/learningModeEndSummaryShowed',
            {},
            props.componentUuid
        )
            .success(() => {
                return true;
            })
            .execute();

        const newLearningModePeriodPromise = BACKEND.post('config', requestBody, props.componentUuid)
            .success(() => {
                return true;
            })
            .execute();

        const newLearningModeComputersPromise = BACKEND.post(
            'MACHINES/LEARNINGMODE',
            learningModeRequestBody,
            props.componentUuid
        ).execute();

        setShowProgress(true);
        Promise.all([
            resetConfigAutoxclusionsCounter,
            resetConfigSummaryFlagRequest,
            newLearningModePeriodPromise,
            newLearningModeComputersPromise,
        ])
            .then((data) => {
                if (data[0]) {
                    EVENT.publish(EventNames.LEARNING_MODE_EVENT, {
                        enable: true,
                        endDate,
                    });
                    setModalOpen(false);
                }
            })
            .finally(() => setShowProgress(false));
    }

    useEffect(() => {
        if (props.open) {
            if (props.resetMode) {
                setSettingsOpen(false);
                setModalOpen(true);
            } else if (!modalOpen) {
                BACKEND.post(
                    'userauth',
                    { accessRight: window.serverInfo.constants.accessRight.AR_Change_Server_Settings },
                    props.componentUuid
                )
                    .onStatus(403, () => props.onExit())
                    .onStatus(200, () => {
                        BACKEND.get('config/learningModeEnd', props.componentUuid)
                            .onStatus(404, () => Promise.resolve(null))
                            .success((learningModeEnd) => {
                                if (learningModeEnd === null) {
                                    setSettingsOpen(false);
                                    setModalOpen(true);
                                } else {
                                    props.onExit();
                                }
                            })
                            .execute();
                    })
                    .execute();
            }
        }
    }, [props.open]);

    useEffect(() => {
        if (props.dataRetentionPeriod) {
            setDataRetentionPeriod(props.dataRetentionPeriod);
            if (props.dataRetentionPeriod < LEARNING_PERIODS[1].value * NUMBER_OF_DAYS_IN_WEEK) {
                setLearningPeriod(LEARNING_PERIODS[0]);
            } else {
                setLearningPeriod(LEARNING_PERIODS[1]);
            }
        }
    }, [props.dataRetentionPeriod]);

    return (
        <Fragment>
            <Progress showProgress={showProgress} />
            <Theme.Provider
                theme={{
                    Modal: {
                        width: settingsOpen ? 'min(1239px, 75%)' : '650px',
                        ...(settingsOpen && { height: 'min(808px, 90%)' }),
                    },
                }}
            >
                <Modal
                    id={MODAL_WINDOW_ID}
                    show={modalOpen}
                    title="ENABLE_RULE_LEARNING_MODE"
                    onDispose={handleExitLearningMode}
                    onHidden={props.onExit}
                    buttons={
                        settingsOpen
                            ? [
                                  <Button
                                      id="eid-learning-mode-enable"
                                      type="primary"
                                      text="ENABLE"
                                      disabled={!validStatus}
                                      onClick={handleApply}
                                  />,
                                  <Button
                                      id="eid-learning-mode-ask-again"
                                      type="secondary"
                                      text="ASK_AGAIN_LATER"
                                      onClick={handleClose}
                                  />,
                              ]
                            : [
                                  <Button
                                      id="eid-learning-mode-select-computers"
                                      type="primary"
                                      text="ENABLE"
                                      onClick={handleEnableLearningModeAllComputers}
                                  />,
                                  <Button
                                      id="eid-learning-mode-customize"
                                      type="secondary"
                                      text="CUSTOMIZE_LEARNING_MODE"
                                      onClick={handleSelectComputers}
                                  />,
                              ]
                    }
                >
                    {settingsOpen && dataRetentionPeriod ? (
                        <LearningModeSettings
                            ref={computersRef}
                            modalWindowId={MODAL_WINDOW_ID}
                            validStatus={validStatus}
                            learningPeriod={learningPeriod}
                            onComputersSelectionChange={handleComputersSelectionChange}
                            onPeriodChange={handlePeriodChange}
                            learningPeriods={getLearningModePeriods()}
                            autoEnableExclusionsSelection={autoEnableExclusionsSelection}
                            setAutoEnableExclusionsSelectionChange={setAutoEnableExclusionsSelectionChange}
                        />
                    ) : (
                        <LearningModeDescription />
                    )}
                </Modal>
            </Theme.Provider>
        </Fragment>
    );
}

export { LearningModeNotification };
export default withBackend(LearningModeModal);
