import { useState, useEffect } from 'react';
import Spacer from '@uilib/core/Spacer/Spacer';
import Link from 'uilib-wrappers/link';
import Text from 'uilib-wrappers/text';
import Tile from 'uilib-wrappers/tile';

import { BACKEND, withBackend } from 'Services/backend';
import { createEsetHelpLink } from 'app.common';
import i18n from 'Services/i18n';

function WarningEventsStoringDisabled(props) {
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        if (props.show) {
            BACKEND.get('server/settings', props.componentUuid)
                .success((response) => {
                    setShowWarning(
                        !Object.values(response.dataCollection.filterOutDatabaseEvents).every(
                            (isFilterOut) => !isFilterOut
                        )
                    );
                })
                .execute();
        } else {
            setShowWarning(false);
        }
    }, [props.show]);

    const handleHelpLinkClick = () => {
        window.open(createEsetHelpLink('data_collection', true), '_blank');
    };

    return (
        showWarning && (
            <Spacer type="mt-1 mx-2">
                <Tile type="info">
                    <Text>
                        {i18n(
                            'THE_COLLECTION_OF_LOW-LEVEL_EVENTS_IS_SET_TO_BE_LIMITED_ONLY_EVENTS_CURRENTLY_IN_THE_DATABASE_CAN_BE_ACCESSED_AND_SHOWN'
                        )}
                        <Link onClick={handleHelpLinkClick} text="MORE_INFORMATION" />
                    </Text>
                </Tile>
            </Spacer>
        )
    );
}

export default withBackend(WarningEventsStoringDisabled);
