import PropTypes from 'prop-types';
import ConfirmationModal from '@uilib/business-components/Modal/ConfirmationModal/ConfirmationModal';

import i18n from 'Services/i18n';

export default function ConfirmationModalWrapper(props) {
    return (
        <ConfirmationModal
            {...props}
            testDataLabel="eid-popup"
            message={i18n(props.message)}
            text={i18n(props.text)}
            onAway={props.onDispose}
            onClose={props.onDispose}
            onKeyEsc={props.onDispose}
        >
            {props.children}
        </ConfirmationModal>
    );
}

ConfirmationModalWrapper.propTypes = {
    onDispose: PropTypes.func.isRequired,
};
