import { EVENT, EventNames } from 'Services/Eventing';
import { BACKEND } from 'Services/backend';

const CANCEL_REQUEST_URL_BASE = 'db_operations';

class RequestsCancellation {
    init = () => {
        this.componentUuid = BACKEND.init();
        this.requestCancellationUnsubscribe = EVENT.subscribe(
            EventNames.REQUEST_CANCELLATION_EVENT,
            (event, { requestId, componentUuid }) => {
                this.cancelRequest(requestId, componentUuid);
            }
        );
    };

    clear = () => {
        this.requestCancellationUnsubscribe();
        BACKEND.clear(this.componentUuid);
    };

    cancelRequest = (requestId, componentUuid) => {
        if (requestId) {
            console.log(`Requesting server to cancel operations related to request ${requestId}.`);
            BACKEND.delete(`${CANCEL_REQUEST_URL_BASE}/${requestId}`, null, this.componentUuid)
                .success(() => {
                    EVENT.publish(EventNames.REQUEST_CANCELLED_EVENT, { requestId, componentUuid });
                })
                .execute();
        }
    };
}

const requestsCancellation = new RequestsCancellation();

export default requestsCancellation;
