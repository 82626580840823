import { createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import React, { Fragment, useRef } from 'react';

const DndContext = createDndContext(HTML5Backend);

export default function CustomDndManager(props) {
    const manager = useRef(DndContext);

    if (!props.children) return null;

    const childrenWithDndManager = React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { dragDropManager: manager.current.dragDropManager });
        }
        return child;
    });

    return <Fragment>{childrenWithDndManager}</Fragment>;
}
