import { enable, disable, auto } from 'darkreader';
import constants from '@uilib/business-components/_utils/constants';

const THEMES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
    AUTO: 'AUTO',
};

const defaultFixes = {
    ignoreInlineStyle: ['.epi-logo-ignore-darkreader path'],
};

const THEME_SKINS = {
    [THEMES.DARK]: constants.DARK_READER_VALUES,
};

function enableSkin(theme) {
    switch (theme) {
        case THEMES.LIGHT:
            auto(false);
            disable();
            break;
        case THEMES.DARK:
            auto(false);
            enable(THEME_SKINS[THEMES.DARK], defaultFixes);
            break;
        case THEMES.AUTO:
            auto(THEME_SKINS[THEMES.DARK], defaultFixes);
            break;
        default:
            auto(THEME_SKINS[THEMES.DARK], defaultFixes);
            break;
    }
}

export { enableSkin, THEMES };
