import Authorization from 'Services/Authorization';

const DELAY = 1000;
const NETWORK_ERROR_RETRIES = 2;

class CancellableDelay {
    constructor() {
        this.timeout = null;
        this.rejectHandler = null;
        this.promise = null;
    }

    start = () => {
        if (this.timeout === null) {
            this.promise = new Promise((resolve, reject) => {
                this.rejectHandler = reject;
                this.timeout = window.setTimeout(resolve, DELAY);
            }).then(() => {
                this.timeout = null;
            });
        }
        return this;
    };

    cancel = () => {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
            this.timeout = null;
            this.rejectHandler();
            this.rejectHandler = null;
        }
    };

    wait = async () => {
        return await this.promise;
    };

    cancelled = () => {
        return this.timeout === null;
    };
}

const asyncWorker = async (
    originalResponse,
    componentUuid,
    skipTokenUpdate,
    abortControllers,
    timers,
    isNetworkError
) => {
    const asyncTaskUuid = await originalResponse.json().then((resp) => resp.taskUuid);
    const url = `${window.location.protocol}//${window.location.host}/frontend/asynctask/${asyncTaskUuid}`;

    const options = {
        method: 'get',
        headers: {
            Accept: ['application/json', 'text/plain', '*/*'],
        },
    };

    if (skipTokenUpdate) {
        options.headers['X-Token-Update'] = `skip`;
    }

    const TOKEN = Authorization.token;
    if (TOKEN) {
        options.headers['Authorization'] = `Bearer ${TOKEN}`;
    }

    const abortData = {
        key: `${componentUuid}_get_${url}_${options.body || ''}`,
        controllers: abortControllers[componentUuid],
        timers: timers[componentUuid],
    };

    abortData.timers.requests[abortData.key] = new CancellableDelay();
    abortData.controllers.requests[abortData.key] = new window.AbortController();
    options.signal = abortData.controllers.requests[abortData.key].signal;

    let networkErrorRetries = NETWORK_ERROR_RETRIES;
    while (true) {
        try {
            await abortData.timers.requests[abortData.key].start().wait();
            const response = await fetch(url, options);

            if (response.status === -1) {
                throw new Error(response);
            }

            if (response.status !== 202) {
                delete abortData.controllers.requests[abortData.key];
                delete abortData.timers.requests[abortData.key];
                return response;
            } else {
                networkErrorRetries = NETWORK_ERROR_RETRIES;
            }
        } catch (error) {
            if (isNetworkError(error)) {
                if (networkErrorRetries > 0) {
                    console.info('Retrying Fetch request!');
                    --networkErrorRetries;
                    continue;
                }
            }

            delete abortData.controllers.requests[abortData.key];
            delete abortData.timers.requests[abortData.key];
            throw error;
        }
    }
};

export default asyncWorker;
