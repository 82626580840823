import { Fragment } from 'react';
import Headline from '@uilib/business-components/Headline/Headline';
import List from 'uilib-wrappers/list';
import Text from 'uilib-wrappers/text';

import i18n from 'Services/i18n';

export default function HtmlList(props) {
    let parsedData = [...props.data];
    if (parsedData.length > 11) {
        parsedData = parsedData.slice(0, 10);
    }

    for (let i = 0; i < parsedData.length; i++) {
        if (parsedData[i].includes('>')) {
            parsedData[i] = parsedData[i].replace('>', '&gt;').replace('<', '&lt;');
        }
        if (props.status?.[i] === '2') {
            parsedData[i] = (
                <Text defaultColor>
                    {parsedData[i]} ({i18n('DELETED')})
                </Text>
            );
        }
    }

    return (
        <Fragment>
            {props.title && <Headline type="h2">{i18n(props.title)}</Headline>}
            <List type="ol">
                {parsedData.map((elem, i) => (
                    <List.Item key={`${elem}-${i}`}>{elem}</List.Item>
                ))}
            </List>
            {props.data.length > 11 && <Text defaultColor>...</Text>}
        </Fragment>
    );
}
