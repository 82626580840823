import IconLetterI from '@uilib/assets-business-icons/IconLetterI';
import IconActionDelete from '@uilib/assets-business-icons/IconActionDelete';
import IconUserUser from '@uilib/assets-business-icons/IconUserUser';
import IconTimeClock from '@uilib/assets-business-icons/IconTimeClock';
import IconEngineeringAutomation from '@uilib/assets-business-icons/IconEngineeringAutomation';
import IconStatusSuspended from '@uilib/assets-business-icons/IconStatusSuspended';
import IconStatusBlocked from '@uilib/assets-business-icons/IconStatusBlocked';
import IconObjectTags from '@uilib/assets-business-icons/IconObjectTags';
import IconStatusClosed from '@uilib/assets-business-icons/IconStatusClosed';
import IconSelectionRadio from '@uilib/assets-business-icons/IconSelectionRadio';

import { Navigator } from 'Services';
import { BACKEND } from 'Services/backend';
import toast from '@uilib/business-components/ToastContainer/Toast';
import i18n from 'Services/i18n';
import { createSimpleSelectionBody } from 'Bricks/Helpers';

import { EVENT, EventNames } from 'Services/Eventing';
import { accessGroupSubmenu, showAffectedRowsInfo } from 'Bricks/Helpers';
import IncidentCommandingToolbar from './incident-commanding-toolbar';

const COMMANDING_MODE = {
    GRID: 0,
    DETAILS: 1,
    LIMITED_DETAILS: 2,
};

function createIncidentesCommanding({
    accessGroupSubmenuClbk,
    tagsEditOpen,
    getIncidentInfo,
    mode,
    reload,
    componentUuid,
    onAssigneeSelectOpen,
    onAddComment,
    onRemediate,
}) {
    const clbkAction = (event, clbk) => {
        if (mode === COMMANDING_MODE.GRID) {
            this.gridApi.applyOnSingleSelection((entity) =>
                clbk(event, {
                    incidentId: entity.id,
                })
            );
        } else {
            const { incidentId } = getIncidentInfo();
            clbk?.(event, {
                incidentId,
            });
        }
    };

    function updateBackend(method, uri, { incidentId }, clbk) {
        BACKEND[method](uri, createSimpleSelectionBody([incidentId]), componentUuid)
            .success((response) => clbk(response))
            .execute();
    }

    const clbkWithBackend = (method, uri, clbk) => {
        if (mode === COMMANDING_MODE.GRID) {
            this.gridApi.updateBackendWithSelection(method, uri, clbk);
        } else {
            updateBackend(method, uri, getIncidentInfo(), clbk);
        }
    };

    const goToIncidentDetails = (event, { incidentId }) => {
        Navigator.go(event, 'console.incident.graph', { incidentId });
    };

    const makeCurrentIncident = (event, { incidentId }) => {
        BACKEND.post(`incident/current/${incidentId}`, {}, componentUuid).success(reload).execute();
    };

    const changeStatus = (status, clbk) => {
        return () => {
            clbkWithBackend('post', `incidents/status/${status}`, (response, selectedCount = 1) => {
                clbk ? clbk() : reload();
                if (response && response.affectedRows !== undefined && response.affectedRows < selectedCount) {
                    showAffectedRowsInfo(selectedCount, response.affectedRows);
                } else {
                    toast.success(i18n('SUCCESSFULLY_CHANGED_STATUS_OF_INCIDENTS'), {
                        autoClose: 3000,
                    });
                }
            });
        };
    };

    const edit = (event, { incidentId }) => {
        Navigator.go(event, 'console.incidentEdit', { incidentId });
    };

    const reopen = changeStatus(window.serverInfo.constants.incidentStatus.open);
    const startProgress = changeStatus(window.serverInfo.constants.incidentStatus.inProgress);
    const putOnHold = changeStatus(window.serverInfo.constants.incidentStatus.onHold);
    const resolve = changeStatus(window.serverInfo.constants.incidentStatus.resolved);
    const close = changeStatus(window.serverInfo.constants.incidentStatus.closed);
    const deleteIncident = () => {
        EVENT.publish(EventNames.SIMPLE_ALERT_EVENT, {
            type: 'question',
            message: mode === COMMANDING_MODE.GRID ? 'DELETE_SELECTED_INCIDENT_QUESTION' : 'DELETE_INCIDENT_QUESTION',
            details: 'DELETING_AN_INCIDENT_IS_A_PERMANENT_ACTION_ARE_YOU_SURE_YOU_WANT_TO_CONTINUE',
            buttons: [
                {
                    id: 'delete',
                    text: 'DELETE',
                    type: 'primary',
                    onClick: changeStatus(
                        window.serverInfo.constants.incidentStatus.deleted,
                        mode === COMMANDING_MODE.DETAILS
                            ? () => {
                                  Navigator.go(null, 'console.incidents');
                              }
                            : undefined
                    ),
                },
                {
                    id: 'cancel',
                    text: 'CANCEL',
                },
            ],
        });
    };
    const markAsInvalid = changeStatus(window.serverInfo.constants.incidentStatus.invalid);

    const context = {
        header: 'INCIDENTS',
        layout: [
            ...(mode === COMMANDING_MODE.GRID ? [['INCIDENTS_GO_TO_DETAILS']] : []),
            ['INCIDENTS_MAKE_CURRENT_INCIDENT', 'INCIDENTS_ASSIGN', 'INCIDENTS_STATUS', 'INCIDENTS_DELETE_INCIDENT'],
            ['ACCESS_GROUP'],
        ],
    };

    const toolbar = {
        left: [
            ...(mode === COMMANDING_MODE.DETAILS || mode === COMMANDING_MODE.LIMITED_DETAILS
                ? ['INCIDENT_CONTEXT']
                : []),
            ...(mode === COMMANDING_MODE.DETAILS
                ? [
                      'INCIDENTS_REMEDIATION',
                      'INCIDENTS_COMMENT',
                      'INCIDENTS_EDIT',
                      'INCIDENTS_ASSIGN',
                      'INCIDENTS_STATUS',
                  ]
                : []),
            ...(mode === COMMANDING_MODE.GRID
                ? [
                      'INCIDENTS_MAKE_CURRENT_INCIDENT',
                      'INCIDENTS_ASSIGN',
                      'INCIDENTS_STATUS',
                      'INCIDENTS_DELETE_INCIDENT',
                  ]
                : []),
        ],
        right: [],
    };

    const commands = {
        INCIDENTS_REMEDIATION: {
            name: 'REMEDIATION',
            clbk: onRemediate,
            type: 'primary',
            singleSelection: true,
        },
        INCIDENTS_COMMENT: {
            name: 'COMMENT',
            clbk: onAddComment,
            singleSelection: true,
        },
        INCIDENTS_EDIT: {
            name: 'EDIT',
            clbk: (event) => clbkAction(event, edit),
            singleSelection: true,
        },
        INCIDENTS_GO_TO_DETAILS: {
            name: 'DETAILS',
            icon: <IconLetterI fill="currentcolor" />,
            clbk: (event) => clbkAction(event, goToIncidentDetails),
            singleSelection: true,
        },
        INCIDENTS_MAKE_CURRENT_INCIDENT: {
            name: 'MAKE_CURRENT_INCIDENT',
            icon: <IconLetterI fill="currentcolor" />,
            clbk: (event) => clbkAction(event, makeCurrentIncident),
            singleSelection: true,
        },
        INCIDENTS_DELETE_INCIDENT: {
            name: 'DELETE_INCIDENT',
            icon: <IconActionDelete fill="currentcolor" />,
            clbk: deleteIncident,
            multiSelection: true,
        },
        INCIDENTS_ASSIGN: {
            name: 'ASSIGN',
            icon: <IconUserUser fill="currentcolor" />,
            clbk: onAssigneeSelectOpen,
            multiSelection: true,
        },
        INCIDENTS_STATUS_REOPEN: {
            name: 'REOPEN',
            icon: <IconTimeClock fill="currentcolor" />,
            clbk: reopen,
            multiSelection: true,
        },
        INCIDENTS_STATUS_START_PROGRESS: {
            name: 'START_PROGRESS',
            icon: <IconEngineeringAutomation fill="currentcolor" />,
            clbk: startProgress,
            multiSelection: true,
        },
        INCIDENTS_STATUS_ON_HOLD: {
            name: 'ON_HOLD',
            icon: <IconStatusSuspended fill="currentcolor" />,
            clbk: putOnHold,
            multiSelection: true,
        },
        INCIDENTS_STATUS_RESOLVE: {
            name: 'RESOLVE_TRUE_POSITIVE',
            icon: <IconStatusClosed fill="currentcolor" />,
            clbk: resolve,
            multiSelection: true,
        },
        INCIDENTS_STATUS_CLOSE: {
            name: 'CLOSE',
            icon: <IconSelectionRadio fill="currentcolor" />,
            clbk: close,
            multiSelection: true,
        },
        INCIDENTS_STATUS_INVALID: {
            name: 'INVALID_FALSE_POSITIVE',
            icon: <IconStatusBlocked fill="currentcolor" />,
            clbk: markAsInvalid,
            multiSelection: true,
        },
        INCIDENTS_STATUS: {
            name: 'PROGRESS',
            commands: [
                [
                    'INCIDENTS_STATUS_START_PROGRESS',
                    'INCIDENTS_STATUS_ON_HOLD',
                    'INCIDENTS_STATUS_RESOLVE',
                    'INCIDENTS_STATUS_CLOSE',
                    'INCIDENTS_STATUS_REOPEN',
                    'INCIDENTS_STATUS_INVALID',
                ],
            ],
            multiSelection: true,
        },
        INCIDENTS_TAGS: {
            name: 'TAGS',
            icon: <IconObjectTags fill="currentcolor" />,
            clbk: tagsEditOpen,
            multiSelection: true,
        },
        ...accessGroupSubmenu(accessGroupSubmenuClbk),
        INCIDENTS_CONTEXT: {
            name: 'INCIDENTS',
            commands: [...context.layout, ['INCIDENTS_TAGS']],
        },
        INCIDENT_CONTEXT: {
            name: 'INCIDENT',
            commands: [...context.layout, ['INCIDENTS_TAGS']],
        },
    };

    return {
        commands,
        context,
        toolbar,
    };
}

export { IncidentCommandingToolbar, COMMANDING_MODE };
export default createIncidentesCommanding;
