import IconMagnifierSearch from '@uilib/assets-business-icons/IconMagnifierSearch';

import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import i18n from 'Services/i18n';

import Loading from '@uilib/business-components/Animations/Loading/Loading';
import Spacer from '@uilib/core/Spacer/Spacer';
import FlexContainer from '@uilib/core/FlexContainer/FlexContainer';
import Input from 'uilib-wrappers/input';
import Tag from 'uilib-wrappers/tag';
import Text from 'uilib-wrappers/text';

import IconButton from 'Bricks/icon-button';
import debounce from 'lodash/debounce';
import Separator from 'Bricks/separator';
import colors from 'eset-ui-colors';

const SCROLL_DEBOUNCE_TIME = 200;

function TagsPane(props) {
    const [searchMode, setSearchMode] = useState(false);

    const uploadTagsPageDebounced = debounce(props.onScroll, SCROLL_DEBOUNCE_TIME);

    function handleTagsContentScroll(event) {
        // Don't persist this event.
        uploadTagsPageDebounced(event.target.scrollTop, event.target.scrollTopMax);
    }

    function handleSearchClick(event) {
        setSearchMode(true);
    }

    function handleSearchBlur(value, event) {
        if (props.pattern.length === 0) {
            setSearchMode(false);
        }
    }

    function handleSearchClear(event) {
        setSearchMode(false);
    }

    return (
        <Fragment>
            <div className="pane-header">
                {!searchMode ? (
                    <Fragment>
                        <Spacer type="pl-5 pt-3">
                            <Text fontWeight="normal" fontSize="1.2em">
                                {i18n('TAGS')}
                            </Text>
                        </Spacer>
                        <IconButton
                            testDataLabel="eid-search-tags-button"
                            className="pane-header__icon"
                            icon={<IconMagnifierSearch fill="currentcolor" />}
                            onClick={handleSearchClick}
                        />
                    </Fragment>
                ) : (
                    <Spacer className="pane-header__input" type="px-2">
                        <Input
                            id="eid-tags-search-input"
                            autoFocus
                            contentLeft={<IconMagnifierSearch fill={colors.iconColor} />}
                            onBlur={handleSearchBlur}
                            onClear={handleSearchClear}
                            value={props.pattern}
                            onChange={props.onPatternChange}
                            placeholder="TYPE_TO_SEARCH"
                        />
                    </Spacer>
                )}
            </div>
            <Separator />
            <div className="pane-content-tags" onScroll={handleTagsContentScroll} data-label={props.testDataLabel}>
                {props.isLoading ? (
                    <div className="ei-flex-full-height">
                        <Spacer type="m-auto">
                            <Loading show />
                        </Spacer>
                    </div>
                ) : (
                    <Spacer type="p-2">
                        <FlexContainer>
                            {props.tags.map((tag) => (
                                <Fragment key={tag.id}>
                                    <Spacer type="p-1">
                                        <Tag
                                            id={`eid-tag-container-${tag.name.replace(/\s/g, '-')}`}
                                            testDataLabel="eid-tag"
                                            value={tag}
                                            color={
                                                props.activeTags.find((activeTag) => activeTag.id === tag.id)
                                                    ? 'active'
                                                    : 'default'
                                            }
                                            onClick={props.onTagClick}
                                            onRemove={props.onTagRemove}
                                        >
                                            {tag.name}
                                        </Tag>
                                    </Spacer>
                                </Fragment>
                            ))}
                        </FlexContainer>
                    </Spacer>
                )}
            </div>
        </Fragment>
    );
}

//-----------------------------------------------------------------------------
TagsPane.propTypes = {
    tags: PropTypes.array.isRequired,
    onScroll: PropTypes.func.isRequired,
    pattern: PropTypes.string,
    onPatternChange: PropTypes.func.isRequired,
    onTagClick: PropTypes.func.isRequired,
    onTagRemove: PropTypes.func.isRequired,
};

//-----------------------------------------------------------------------------
TagsPane.defaultProps = {
    pattern: '',
};

export default TagsPane;
