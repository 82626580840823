import IconEngineeringAutomation from '@uilib/assets-business-icons/IconEngineeringAutomation';

import { useEffect, useState, Fragment } from 'react';

import Text from 'uilib-wrappers/text';
import Tooltip from 'uilib-wrappers/tooltip';

import { BACKEND, withBackend } from 'Services/backend';
import { EVENT, EventNames } from 'Services/Eventing';
import Filters from 'Services/Filters';
import i18n from 'Services/i18n';
import moment from 'moment';
import './index.scss';
import LearningModeSummary from './summary';

const LEARNING_MODE_CHECK_INTERVAL_MILLISECONDS = 60000;

function LearningModeNotification(props) {
    const [showNotification, setShowNotification] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [summaryOpen, setSummaryOpen] = useState(false);
    const [exclusionsNum, setExclusionsNum] = useState();

    useEffect(() => {
        const learningModeEventUnsubscribe = EVENT.subscribe(EventNames.LEARNING_MODE_EVENT, (event, data) => {
            if (data.endDate) {
                setEndDate(data.endDate);
            }
            setShowNotification(data.enable);
        });

        const checkLearningModeSummaryShow = () => {
            BACKEND.post(
                'userauth',
                { groupId: 1, accessRight: window.serverInfo.constants.accessRight.AR_Exclusion_Management },
                props.componentUuid,
                BACKEND.SKIP_TOKEN_UPDATE
            )
                .onStatus(403, () => Promise.resolve(null))
                .success(() => {
                    BACKEND.get(
                        'config/learningModeAutoExclusionsCount',
                        props.componentUuid,
                        BACKEND.SKIP_TOKEN_UPDATE
                    )
                        .onStatus(404, () => Promise.resolve(null))
                        .success((exclusionCount) => {
                            if (exclusionCount !== null) {
                                BACKEND.get(
                                    'config/learningModeEndSummaryShowed',
                                    props.componentUuid,
                                    BACKEND.SKIP_TOKEN_UPDATE
                                )
                                    .onStatus(404, () => Promise.resolve(null))
                                    .success((result) => {
                                        if (result === null) {
                                            setExclusionsNum(Number(exclusionCount));
                                            setSummaryOpen(true);
                                            BACKEND.post(
                                                'config',
                                                { learningModeEndSummaryShowed: '1' },
                                                props.componentUuid,
                                                BACKEND.SKIP_TOKEN_UPDATE
                                            ).execute();
                                        }
                                    })
                                    .execute();
                            }
                        })
                        .execute();
                })
                .execute();
        };

        const checkLearningModeEnable = () => {
            BACKEND.get('config/learningModeEnd', props.componentUuid, BACKEND.SKIP_TOKEN_UPDATE)
                .onStatus(404, () => Promise.resolve(null))
                .success((learningModeEnd) => {
                    if (learningModeEnd === null || moment.utc().diff(moment.utc(learningModeEnd)) > 0) {
                        setShowNotification((showNotification) => {
                            if (showNotification) {
                                EVENT.publish(EventNames.LEARNING_MODE_EVENT, {
                                    enable: false,
                                });
                            }
                            checkLearningModeSummaryShow();
                            return false;
                        });
                    } else {
                        setEndDate(learningModeEnd);
                        setShowNotification((showNotification) => {
                            if (!showNotification) {
                                EVENT.publish(EventNames.LEARNING_MODE_EVENT, {
                                    enable: true,
                                });
                            }
                            return true;
                        });
                    }
                })
                .execute();
        };

        checkLearningModeEnable();

        const checkLearningModeEnableLoop = window.setInterval(
            checkLearningModeEnable,
            LEARNING_MODE_CHECK_INTERVAL_MILLISECONDS
        );

        return () => {
            learningModeEventUnsubscribe();
            window.clearInterval(checkLearningModeEnableLoop);
        };
    }, []);

    return (
        <Fragment>
            {showNotification && (
                <Tooltip
                    place="bottom"
                    tooltipContent={i18n('END', { endDate: Filters.localDate(moment.utc(endDate)) })}
                >
                    <div className="learning-mode-notification">
                        <IconEngineeringAutomation fill="currentcolor" className="learning-mode-notification__icon" />
                        <Text color="inherit" className="learning-mode-notification__label">
                            LEARNING_MODE_ENABLED
                        </Text>
                    </div>
                </Tooltip>
            )}
            <LearningModeSummary
                summaryOpen={summaryOpen}
                setSummaryOpen={setSummaryOpen}
                exclusionsNum={exclusionsNum}
            />
        </Fragment>
    );
}

export default withBackend(LearningModeNotification);
