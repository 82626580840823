import IconStatusWarning from '@uilib/assets-business-icons/IconStatusWarning';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import i18n from 'Services/i18n';

import { CommandingToolbar } from 'Bricks/Commanding';

import './footer.scss';

//-----------------------------------------------------------------------------
function ComplexTableFooter(props) {
    return (
        <Fragment>
            {props.isLoading && <div className="footer-disabled"></div>}
            {props.expectedCountMessage && (
                <div className="footer-status footer-status--right footer-status--warning">
                    <IconStatusWarning fill="currentcolor" />
                    <span>{i18n(props.expectedCountMessage)}</span>
                </div>
            )}
            {props.toolbarVisible &&
                (props.CustomCommandingComponent ? (
                    React.cloneElement(props.CustomCommandingComponent, {
                        commanding: props.commanding,
                        groupingOptionId: props.groupingOptionId,
                    })
                ) : (
                    <CommandingToolbar commanding={props.commanding} groupingOptionId={props.groupingOptionId} />
                ))}
        </Fragment>
    );
}

//-----------------------------------------------------------------------------
ComplexTableFooter.propTypes = {
    toolbarVisible: PropTypes.bool,
    commanding: PropTypes.object,
    expectedCountMessage: PropTypes.string,
};

//-----------------------------------------------------------------------------
ComplexTableFooter.defaultProps = {
    toolbarVisible: false,
    commanding: null,
    expectedCountMessage: '',
};

export default ComplexTableFooter;
