import IconAdminRoot from '@uilib/assets-business-icons/IconAdminRoot';
import IconFolderMsp from '@uilib/assets-business-icons/IconFolderMsp';
import IconBuildingOrganization from '@uilib/assets-business-icons/IconBuildingOrganization';
import IconFolderShared from '@uilib/assets-business-icons/IconFolderShared';
import IconLocationLocate from '@uilib/assets-business-icons/IconLocationLocate';
import IconBuildingCompany from '@uilib/assets-business-icons/IconBuildingCompany';
import IconFolderClassic from '@uilib/assets-business-icons/IconFolderClassic';

import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import colors from 'eset-ui-colors';
import i18n from 'Services/i18n';

import Tree from '@uilib/business-components/Tree/Tree';
import Loading from '@uilib/business-components/Animations/Loading/Loading';
import Spacer from '@uilib/core/Spacer/Spacer';
import { escapeRegExp } from 'Bricks/Helpers';
import { createGroupsTreeDictionary } from 'Bricks/Helpers';

import CustomDndManager from './custom-dnd-manager';

export function getGroupIcon(nodeLocationType) {
    const locationType = window.serverInfo.constants.locationType;

    switch (nodeLocationType) {
        case locationType.mspRoot:
            return <IconAdminRoot fill="currentcolor" />;
        case locationType.mspCompany:
            return <IconFolderMsp fill="currentcolor" />;
        case locationType.mspManager:
            return <IconFolderMsp fill="currentcolor" />;
        case locationType.mspCustomer:
            return <IconBuildingOrganization fill="currentcolor" />;
        case locationType.mspShared:
            return <IconFolderShared fill="currentcolor" />;
        case locationType.site:
            return <IconLocationLocate fill="currentcolor" />;
        case locationType.company:
            return <IconBuildingCompany fill="currentcolor" />;
        case locationType.unknown:
            return <IconFolderClassic fill="currentcolor" />;
        default:
            return <IconFolderClassic fill="currentcolor" />;
    }
}

function GroupsTree(props) {
    const [treeData, setTreeData] = useState([]);
    const [treeDataDictionary, setTreeDataDictionary] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [treeKey, forceUpdatedateTree] = useReducer((key) => key + 1, 0);

    function parseGroupsTree(groupTree) {
        return groupTree?.map((node) => {
            const NAME = node.name === 'All' ? i18n('ALL_COMPUTERS') : node.name;

            return {
                ...node,
                icon: React.cloneElement(getGroupIcon(node.locationType), {
                    fill: colors.iconColor,
                }),
                disabled: node.accessible !== undefined && !node.accessible,
                title: NAME,
                expanded: props.expanded.includes(node.id),
                children:
                    node.children?.length > 0
                        ? parseGroupsTree(
                              node.children.sort((group1st, group2nd) => group1st.name.localeCompare(group2nd.name))
                          )
                        : node.children,
            };
        });
    }

    function filterGroupsTree(groupTree, regex) {
        for (const node of groupTree) {
            // Start from leaves.
            filterGroupsTree(node.children, regex);
            // Remove invalid children if exists.
            node.children = node.children.filter((child) => !child.invalid);
            // Check the node itself.
            if (node.children.length === 0 && !regex.test(node.title)) {
                node.invalid = true;
            } else {
                node.expanded = true; // Expand nodes as they might contain filtered
            }
        }
    }

    useEffect(() => {
        const regex = new RegExp(escapeRegExp(props.filter), 'i');
        const groupsTree = parseGroupsTree(props.data || []);
        props.filter && filterGroupsTree(groupsTree, regex);
        groupsTree[0]?.children && setTreeDataDictionary(createGroupsTreeDictionary(groupsTree[0]));
        setTreeData(groupsTree);
        forceUpdatedateTree();
        setIsLoading(false);
    }, [props.data, props.expanded, props.filter]);

    function handleTreeChange(data) {
        function checkExpanded(groupTree, expanded) {
            for (const node of groupTree) {
                if (node.expanded) {
                    expanded.push(node.id);
                }
                checkExpanded(node.children, expanded);
            }
        }

        const expanded = [];
        checkExpanded(data, expanded);
        if (props.onExpandedChange !== null) {
            props.onExpandedChange(expanded);
        }
        setTreeData(data);
        forceUpdatedateTree();
    }

    function handleTreeClick(node) {
        props.onChange(node.id, node.name);
    }

    function onCheckedChange(checkedNodeIds) {
        /**
         * tree filtering removes nodes and their selection status,
         * so they are missing in the checkedNodeIds array,
         * */

        const unchecked =
            props.initiallyGroupsSelected?.filter((initiallyCheckedId) => {
                return !checkedNodeIds.find((checkedNodeId) => Number(initiallyCheckedId) === Number(checkedNodeId));
            }) || [];
        const falselyUnchecked = unchecked.filter((id) => !treeDataDictionary[id]);

        props.onGroupSelectionChange([...checkedNodeIds, ...falselyUnchecked].map((nodeId) => Number(nodeId)));
    }

    return props.isLoading || isLoading ? (
        <div className="ei-flex-full-height">
            <Spacer type="m-auto">
                <Loading show />
            </Spacer>
        </div>
    ) : (
        <CustomDndManager>
            <Tree
                /**
                 * TODO: BUG IN UIlib
                 * When the checkboxes are active, it is not possible to turn off the selection counter and the counter itself provides incorrect information
                 * The temporary solution is an empty string as format
                 */
                {...(props.hasCheckboxes && {
                    showSelectedInfo: {
                        displayEmptySelection: false,
                        format: (checkedNodes, totalNodes) => '',
                        totalNodes: undefined,
                    },
                })}
                key={treeKey}
                initiallyChecked={props.initiallyGroupsSelected}
                onCheckedChange={onCheckedChange}
                hasCheckboxes={props.hasCheckboxes}
                initialActiveNode={props.defaultActive}
                treeData={treeData}
                onChange={handleTreeChange}
                onClick={handleTreeClick}
            />
        </CustomDndManager>
    );
}

//-----------------------------------------------------------------------------
GroupsTree.propTypes = {
    defaultActive: PropTypes.number,
    expanded: PropTypes.arrayOf(PropTypes.number),
    onExpandedChange: PropTypes.func,
    filter: PropTypes.string,
};

//-----------------------------------------------------------------------------
GroupsTree.defaultProps = {
    defaultActive: 1,
    expanded: [1],
    onExpandedChange: null,
    filter: '',
};
export default GroupsTree;
