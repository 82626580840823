import IconMagnifierSearch from '@uilib/assets-business-icons/IconMagnifierSearch';
import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import Spacer from '@uilib/core/Spacer/Spacer';

import Input from 'uilib-wrappers/input';
import Text from 'uilib-wrappers/text';

import GroupFilterService from 'Services/group-filter';
import { BACKEND, withBackend } from 'Services/backend';
import { EVENT, EventNames } from 'Services/Eventing';
import i18n from 'Services/i18n';

import IconButton from 'Bricks/icon-button';
import GroupsTree from 'Bricks/groups-tree';
import Separator from 'Bricks/separator';
import './index.scss';
import colors from 'eset-ui-colors';

const DEFAULT_ACTIVE_GROUP = 1;

function GroupsPane(props) {
    const [searchMode, setSearchMode] = useState(false);
    const [pattern, setPattern] = useState('');

    const [groupId, setGroupId] = useState(props.defaultActive || DEFAULT_ACTIVE_GROUP);
    const [groups, setGroups] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    function getGroups() {
        if (!props.groups) {
            setIsLoading(true);
            const groupsTreeRequest = props.accessRight
                ? BACKEND.post('groupsTree/0', { accessRight: props.accessRight }, props.componentUuid)
                : BACKEND.get('groupsTree/0', props.componentUuid);

            groupsTreeRequest
                .success((response) => {
                    setGroups([response]);
                })
                .always(() => {
                    setIsLoading(false);
                })
                .execute();
        }
    }

    function getGroupsAndSubscribe() {
        const groupFilterChangedUnsubscribe = EVENT.subscribe(
            EventNames.GROUP_FILTER_CHANGED_EVENT,
            (message, payload) => {
                if (groupId !== payload.groupId) {
                    setGroupId(payload.groupId);
                }
            }
        );

        GroupFilterService.disable(groupId);

        // always load the whole tree
        getGroups();

        return () => {
            groupFilterChangedUnsubscribe();
            GroupFilterService.enable();
        };
    }

    useEffect(() => (!props.disableGroupChangeSubscribe ? getGroupsAndSubscribe() : getGroups()), []);

    useEffect(() => {
        if (props.groups) {
            setGroups(props.groups);
            setIsLoading(false);
        }
    }, [props.groups]);

    function handleSearchClick(event) {
        setSearchMode(true);
    }

    function handleSearchBlur(value, event) {
        if (pattern.length === 0) {
            setSearchMode(false);
        }
    }

    function handlePatternChange(value, event) {
        setPattern(value);
    }

    function handleGroupsTreeChange(treeGroupId, treeGroupName) {
        if (groupId !== treeGroupId) {
            setGroupId(treeGroupId);
            !props.disableGroupChangeSubscribe && GroupFilterService.disable(treeGroupId);
            props.onChange(treeGroupId, treeGroupName);
        }
    }

    return (
        <Fragment>
            <div className="pane-header">
                {!searchMode ? (
                    <Fragment>
                        <Spacer type="pl-5 pt-3">
                            <Text fontWeight="normal" fontSize="1.2em">
                                {i18n('GROUPS')}
                            </Text>
                        </Spacer>
                        <IconButton
                            className="pane-header__icon"
                            icon={<IconMagnifierSearch fill="currentcolor" />}
                            onClick={handleSearchClick}
                        />
                    </Fragment>
                ) : (
                    <Spacer className="pane-header__input" type="px-2">
                        <Input
                            autoFocus
                            contentLeft={<IconMagnifierSearch fill={colors.iconColor} />}
                            onBlur={handleSearchBlur}
                            value={pattern}
                            onChange={handlePatternChange}
                            placeholder="TYPE_TO_SEARCH"
                        />
                    </Spacer>
                )}
            </div>
            <Separator />
            <div className="pane-content">
                <GroupsTree
                    initiallyGroupsSelected={props.initiallyGroupsSelected}
                    hasCheckboxes={props.hasCheckboxes}
                    onGroupSelectionChange={props.onGroupSelectionChange}
                    isLoading={isLoading || props.isLoading}
                    defaultActive={groupId}
                    expanded={props.expanded}
                    onExpandedChange={props.onExpandedChange}
                    data={groups}
                    filter={pattern}
                    onChange={handleGroupsTreeChange}
                />
            </div>
        </Fragment>
    );
}

//-----------------------------------------------------------------------------
GroupsPane.propTypes = {
    onChange: PropTypes.func.isRequired,
    componentUuid: PropTypes.string.isRequired,
};

//-----------------------------------------------------------------------------
GroupsPane.defaultProps = {};

export { DEFAULT_ACTIVE_GROUP };
export default withBackend(GroupsPane);
