import RequestBuilder from './request-builder';
import BackendService from './service';

import * as React from 'react';

const backendService = new BackendService();

const BACKEND = {
    get: (...params) => new RequestBuilder(backendService).get(...params),
    post: (...params) => new RequestBuilder(backendService).post(...params),
    put: (...params) => new RequestBuilder(backendService).put(...params),
    delete: (...params) => new RequestBuilder(backendService).delete(...params),

    init: backendService.init,
    cancel: backendService.cancel,
    clear: backendService.clear,
    clearAll: backendService.clearAll,

    SKIP_TOKEN_UPDATE: true,
};

function backendWrapper(backend) {
    return function withBackend(Component) {
        class WithBackend extends React.Component {
            constructor(props) {
                super(props);
                this.componentUuid = backend.init();
            }

            componentWillUnmount() {
                backend.clear(this.componentUuid);
            }

            render() {
                return <Component {...this.props} ref={this.props.forwardedRef} componentUuid={this.componentUuid} />;
            }
        }

        return React.forwardRef((props, ref) => {
            return <WithBackend {...props} forwardedRef={ref} />;
        });
    };
}
const withBackend = backendWrapper(backendService);

export { BACKEND, withBackend };
