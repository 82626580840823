import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import colors from 'eset-ui-colors';

const IconButtonLayout = React.forwardRef((props, ref) => {
    return (
        <button
            ref={ref}
            type="button"
            id={props.id}
            data-label={props.testDataLabel}
            disabled={props.disabled}
            className={classnames(props.className, {
                'ei-cursor-pointer': !props.disabled,
                'icon-button-white': props.white,
                'icon-button-transparent': props.transparent,
            })}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            {React.cloneElement(props.icon, {
                ...(props.fillName && { fill: colors[props.fillName] }),
                size: props.size || 16,
                className: 'icon-button',
            })}
        </button>
    );
});

IconButtonLayout.propTypes = {
    onClick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,

    className: PropTypes.string,
    disabled: PropTypes.bool,
    fillName: PropTypes.oneOf(Object.keys(colors)),
    id: PropTypes.string,
    size: PropTypes.number,
    white: PropTypes.bool,
};

export default IconButtonLayout;
