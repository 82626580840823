import { Fragment } from 'react';
import PropTypes from 'prop-types';

import i18n from 'Services/i18n';

import LocalFilters from 'Bricks/local-filters';
import WarningEventsStoringDisabled from 'Bricks/warning-events-storing-disabled';
import classnames from 'classnames';

import './header.scss';

//-----------------------------------------------------------------------------
function ComplexTableHeader(props) {
    return (
        <Fragment>
            <WarningEventsStoringDisabled show={props.showWarningEventsStoringDisabled} />
            <div className={classnames('complex-table-header', { 'header-disabled': props.isLoading })}>
                {i18n(props.title)}
                {props.localFiltersOptions && (
                    <LocalFilters
                        tableApi={props.tableApi}
                        tags={props.localFiltersTags}
                        activeTags={props.localFiltersActiveTags}
                        onTagsChange={props.onTagsChange}
                        visible={props.localFiltersOptions.visible}
                        storage={props.storage}
                        grouping={props.localFiltersOptions.grouping}
                        subgroups={props.localFiltersOptions.subgroups}
                        dashboard={props.localFiltersOptions.dashboard}
                        filters={props.localFiltersOptions.filters}
                        presets={props.localFiltersOptions.presets}
                        initialTableState={props.initialTableState}
                        onRegisterApi={props.onRegisterApi}
                        onRefresh={props.onReloadClick}
                        idParameter={props.idParameter}
                        menuPortalTarget={props.menuPortalTarget}
                    />
                )}
            </div>
        </Fragment>
    );
}

//-----------------------------------------------------------------------------
ComplexTableHeader.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    storage: PropTypes.string.isRequired,
    localFiltersOptions: PropTypes.object,
    tableApi: PropTypes.object.isRequired,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            active: PropTypes.bool,
        })
    ),
    onTagsChange: PropTypes.func,
    initialTableState: PropTypes.object,
    onRegisterApi: PropTypes.func.isRequired,
};

//-----------------------------------------------------------------------------
ComplexTableHeader.defaultProps = {
    title: null,
    tags: [],
    onTagsChange: null,
    initialTableState: {},
};

export default ComplexTableHeader;
