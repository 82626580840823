import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import Checkbox from '@uilib/business-components/Checkbox/Checkbox';
import Spacer from '@uilib/core/Spacer/Spacer';
import FlexContainer from '@uilib/core/FlexContainer/FlexContainer';
import FormGroup from 'uilib-wrappers/form-group';
import Select from 'uilib-wrappers/select';
import Text from 'uilib-wrappers/text';
import Tooltip from 'uilib-wrappers/tooltip';

import Computers from './computers';
import i18n from 'Services/i18n';

function LearningModeSettings(props, ref) {
    const [menuPortal, setMenuPortal] = useState(null);
    const computersRef = useRef();

    useImperativeHandle(ref, () => ({
        getSelectionRequestBody: () => computersRef.current.gridApi?.getSelectionRequestBody(),
    }));

    useEffect(() => {
        setMenuPortal(document.getElementById(props.modalWindowId));
    }, []);

    return (
        <FlexContainer direction="column" align="stretch" className="learning-mode-settings">
            <Text>ENABLE_LEARNING_MODE_SELECT_COMPUTERS</Text>
            <Spacer type="py-2" className="computers-form-container">
                <FormGroup
                    className="computers-form"
                    required={true}
                    showLabel={false}
                    hasError={!props.validStatus}
                    validationText="SELECT_AT_LEAST_ONE_COMPUTER"
                    content={
                        <Computers ref={computersRef} onComputersSelectionChange={props.onComputersSelectionChange} />
                    }
                />
            </Spacer>
            <Tooltip tooltipContent={<Text>ENABLE_AUTOEXCLUSIONS_LEARNING_MODE_TOOLTIP</Text>}>
                <div>
                    <Checkbox
                        checked={props.autoEnableExclusionsSelection}
                        onChange={(val) => props.setAutoEnableExclusionsSelectionChange(val)}
                        testDataLabel="eid-checkbox"
                        text={i18n('ENABLE_AUTOEXCLUSIONS_LEARNING_MODE')}
                    />
                </div>
            </Tooltip>
            {props.learningPeriods.length > 1 && (
                <Spacer type="py-2">
                    <FormGroup
                        labelText="LEARNING_MODE_PERIOD"
                        content={
                            <Select
                                isSearchable={false}
                                isClearable={false}
                                menuPortalTarget={menuPortal}
                                options={props.learningPeriods}
                                value={props.learningPeriod}
                                onChange={props.onPeriodChange}
                            />
                        }
                    />
                </Spacer>
            )}
        </FlexContainer>
    );
}

export default forwardRef(LearningModeSettings);
