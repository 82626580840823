import IconEngineeringSettings from '@uilib/assets-business-icons/IconEngineeringSettings';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BACKEND, withBackend } from 'Services/backend';
import HighlightBlank from 'Bricks/highlight-blank';
import copyToClipboard from '@uilib/core/_utils/copyToClipboard';
import Dropdown from 'uilib-wrappers/dropdown';

import './index.css';
import colors from 'eset-ui-colors';

function SimpleHash(props) {
    if (props.isGroup) {
        return false;
    }
    return (
        <HighlightBlank
            value={
                props.hash
                    ? props.hash
                    : props.isPerformanceExcluded
                    ? 'UNABLE_TO_COMPUTE_HASH_PERFORMANCE_EXCLUDED'
                    : 'UNABLE_TO_COMPUTE_HASH'
            }
            bleak={!props.hash}
            monospace={!!props.hash}
            uppercase={!!props.hash}
            disableCellOverflow={true}
        />
    );
}

SimpleHash.propTypes = {
    hash: PropTypes.string,
};

SimpleHash.defaultProps = {
    hash: null,
};

function ComplexHash(props) {
    const [content, setContent] = useState([
        {
            items: [
                {
                    title: 'COPY_TO_CLIPBOARD',
                    id: 'dropdown-copy-to-clipboard',
                    callback: () => {
                        copyToClipboard(props.hash);
                    },
                },
            ],
        },
    ]);

    useEffect(() => {
        BACKEND.get('users/searchpages', props.componentUuid)
            .success((searchPagesData) => {
                if (searchPagesData && searchPagesData.length > 0) {
                    const newContent = [
                        {
                            items: searchPagesData.map((item) => ({
                                title: item.label,
                                id: `dropdown-search-page-${item.searchPageId}`,
                                callback: () => {
                                    window.open(item.link.replace(/<>/, props.hash));
                                },
                            })),
                        },
                        ...content,
                    ];
                    setContent(newContent);
                }
            })
            .execute();
    }, []);

    return (
        <span className="ei-hash" data-label="eid-hash">
            <SimpleHash hash={props.hash} isPerformanceExcluded={props.isPerformanceExcluded} />
            {props.hash !== null && <Dropdown id="ei-hash-dropdown" reference={<ToggleIcon />} content={content} />}
        </span>
    );
}

const ToggleIcon = React.forwardRef((props, ref) => (
    <IconEngineeringSettings onClick={props.toggle} ref={ref} className="ei-cursor-pointer" fill={colors.primary} />
));

ComplexHash.propTypes = {
    hash: PropTypes.string,
};

ComplexHash.defaultProps = {
    hash: null,
};

const ComplexHashWithBackend = withBackend(ComplexHash);

export { SimpleHash, ComplexHashWithBackend as ComplexHash };
