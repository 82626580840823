const MAX_HISTORY_LENGTH = 100;

class Navigator {
    #history = [];

    constructor() {
        this.router = null;
        this.history = {
            push: (page) => {
                if (JSON.stringify(page) !== JSON.stringify(this.#history[this.#history.length - 1])) {
                    this.#history.push(page);
                }
                if (this.#history.length > MAX_HISTORY_LENGTH) {
                    this.#history.shift();
                }
            },
            getHistory: () => this.#history,
            getPrevious: () => this.#history[this.#history.length - 2],
            back: (event) => {
                if (this.#history.length > 1) {
                    if (!event?.ctrlKey) {
                        this.#history.pop();
                    }
                    const previous = this.#history[this.#history.length - 1];
                    previous.name && this.go(event, previous.name, previous.params);
                }
            },
            available: () => this.#history.length > 1,
        };
    }

    init(router) {
        this.router = router;
    }

    _handler(options, openInNewTab = false) {
        return function handler(event, stateName, stateParams = {}) {
            if (this.router === null) {
                throw new Error('Navigator not initialized!');
            }

            if (!stateName || (typeof stateName !== 'string' && typeof stateName !== 'object')) {
                throw new Error(`Navigator::handler - parameter 'stateName' is required.`);
            }

            if ((event && event.ctrlKey) || openInNewTab) {
                window.open(this.router.stateService.href(stateName, stateParams));
            } else {
                this.router.stateService.go(stateName, stateParams, options);
            }
        };
    }

    go = this._handler();
    open = this._handler(undefined, true);
    replace = this._handler({ location: 'replace' });
}

const navigator = new Navigator();

export default navigator;
