import React, { useState, useEffect } from 'react';
import { BACKEND, withBackend } from 'Services/backend';
import ProductTour from '@uilib/business-components/ProductTour/ProductTour';
import Theme from '@uilib/business-components/_utils/theme';
import getPages from './pages';

import i18n from 'Services/i18n';

const SETTINGS_URL = 'users/settings/product_tour';
const IS_CLOUD = window.serverInfo.useCentralRedirector;

const PRODUCT_TOUR_THEME = {
    Modal: {
        maxHeight: 'min(750px, 90%)',
        maxWidth: 'min(1150px, 75%)',
    },
};

function ProductTourModal(props) {
    const [productTourOpen, setProductTourOpen] = useState(false);

    const pages = React.useMemo(() => getPages(IS_CLOUD), [props.open]);

    useEffect(() => {
        if (props.open) {
            BACKEND.get(SETTINGS_URL, props.componentUuid)
                .onStatus(404, () => Promise.resolve(null))
                .success((response) => {
                    if (response.completed) {
                        props.onExit();
                    } else {
                        setProductTourOpen(true);
                    }
                })
                .execute();
        }
    }, [props.open]);

    function close() {
        setProductTourOpen(false);
        props.onExit();
    }

    function finish() {
        BACKEND.put(SETTINGS_URL, { completed: true }, props.componentUuid).always(close).execute();
    }

    return (
        productTourOpen && (
            <Theme.Provider theme={PRODUCT_TOUR_THEME}>
                <ProductTour
                    testDataLabel="eid-popup"
                    backButton={{
                        id: 'product-tour-back',
                        text: i18n('BACK'),
                    }}
                    nextButton={{
                        id: 'product-tour-next',
                        text: i18n('NEXT'),
                    }}
                    finishButton={{
                        id: 'product-tour-finish',
                        text: i18n('FINISH'),
                        onClick: finish,
                    }}
                    onCancelled={finish}
                    pages={pages}
                    pagingStatus={(currentPage, totalPages) =>
                        i18n('PAGE_OF', { currentPage: currentPage + 1, totalPages })
                    }
                    title={i18n(IS_CLOUD ? 'ESET_INSPECT_TOUR_CLOUD' : 'ESET_INSPECT_TOUR')}
                />
            </Theme.Provider>
        )
    );
}

export default withBackend(ProductTourModal);
