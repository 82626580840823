import Theme from '@uilib/business-components/_utils/theme';
import Modal from 'uilib-wrappers/modal';
import RemediationBody from 'Bricks/remediation-body';

const THEME = {
    REMEDIATION: {
        innerPadding: '15px',
    },

    CHILDREN: {
        innerPadding: '24px',
    },

    MODAL: {
        maxHeight: 'min(808px, 90%)',
        maxWidth: 'min(1239px, 75%)',
    },
};

function ActionsModal(props) {
    const actionsModalTheme = {
        Modal: {
            ...THEME.MODAL,
            ...(props.children ? THEME.CHILDREN : THEME.REMEDIATION),
        },
    };

    return (
        <Theme.Provider theme={actionsModalTheme}>
            <Modal {...props} show={props.show} title={props.title} onDispose={props.onClose} buttons={props.buttons}>
                {props.children ? (
                    props.children
                ) : (
                    <RemediationBody
                        remediationHeader={props.remediationHeader}
                        remediationContent={props.remediationContent}
                        remediationFooter={props.remediationFooter}
                    />
                )}
            </Modal>
        </Theme.Provider>
    );
}

export default ActionsModal;
