import IconMediaPlay from '@uilib/assets-business-icons/IconMediaPlay';
import IconArrowHigher from '@uilib/assets-business-icons/IconArrowHigher';
import IconArrowLower from '@uilib/assets-business-icons/IconArrowLower';

import classnames from 'classnames';

import './index.scss';

function ProcessIcon(props) {
    const integrityLevel =
        props.integrityLevel !== undefined && props.integrityLevel !== null
            ? props.integrityLevel
            : window.serverInfo.constants.integrityLevel.MEDIUM;
    const isDefaultIntegrityLevel =
        integrityLevel >= window.serverInfo.constants.integrityLevel.MEDIUM &&
        integrityLevel < window.serverInfo.constants.integrityLevel.HIGH;
    return (
        <span className="process-icon">
            <IconMediaPlay
                fill="currentcolor"
                className={classnames({
                    'process-icon__integrity': !isDefaultIntegrityLevel,
                    'process-icon__breadcrumb': props.breadcrumb && !isDefaultIntegrityLevel,
                })}
            />
            {integrityLevel >= window.serverInfo.constants.integrityLevel.HIGH && (
                <IconArrowHigher fill="currentcolor" />
            )}
            {integrityLevel < window.serverInfo.constants.integrityLevel.MEDIUM && (
                <IconArrowLower fill="currentcolor" />
            )}
        </span>
    );
}

export default ProcessIcon;
