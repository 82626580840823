import { withBackend } from 'Services/backend';
import { Navigator } from 'Services';
import Spacer from '@uilib/core/Spacer/Spacer';
import Button from 'uilib-wrappers/button';
import Modal from 'uilib-wrappers/modal';
import Text from 'uilib-wrappers/text';

import i18n from 'Services/i18n';

const MODAL_WINDOW_ID = 'eid-learning-mode-summary';

function LearningModeSummary(props) {
    function handleClose() {
        props.setSummaryOpen(false);
    }

    function handleGoToQuestions() {
        props.setSummaryOpen(false);
        Navigator.go(null, 'console.notifications');
    }

    return (
        <Modal
            id={MODAL_WINDOW_ID}
            show={props.summaryOpen}
            title="LEARNING_MODE_ENDED"
            onDispose={handleClose}
            onHidden={handleClose}
            buttons={
                props.exclusionsNum === 0
                    ? [<Button id="eid-go-to-questions" type="primary" text="OK" onClick={handleClose} />]
                    : [
                          <Button
                              id="eid-go-to-questions"
                              type="primary"
                              text="LEARNING_MODE_ENDED_GO_TO_QUESTIONS"
                              onClick={handleGoToQuestions}
                          />,
                          <Button id="eid-go-to-questions" type="primary" text="CLOSE" onClick={handleClose} />,
                      ]
            }
        >
            <Spacer type="py-2">
                {props.exclusionsNum === 0 ? (
                    <Text>LEARNING_MODE_ENDED_NO_CREATED_EXCLUSIONS</Text>
                ) : (
                    <Spacer type="py-2">
                        <Text>{i18n('LEARNING_MODE_ENDED_N_CREATED_EXCLUSIONS', { count: props.exclusionsNum })}</Text>
                        <Spacer type="py-2">
                            <Text fontWeight="bold">IMPORTANT</Text>
                            <Text>LEARNING_MODE_ENDED_WARNING_1</Text>
                            <Text>LEARNING_MODE_ENDED_WARNING_2</Text>
                        </Spacer>
                    </Spacer>
                )}
            </Spacer>
        </Modal>
    );
}

export default withBackend(LearningModeSummary);
