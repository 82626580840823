import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'uilib-wrappers/tooltip';

import IconButtonLayout from './layout';

export function moduleFunctions({ setHovered }) {
    function handleMouseEnter(event) {
        setHovered(true);
    }

    function handleMouseLeave(event) {
        setHovered(false);
    }

    function getFillName(disabled, darkbg, hovered, iconColor) {
        if (disabled) {
            return 'disabled';
        } else if (darkbg) {
            return undefined;
        } else if (hovered) {
            return 'primaryHover';
        } else {
            return iconColor || 'iconColor';
        }
    }

    return {
        getFillName,
        handleMouseEnter,
        handleMouseLeave,
    };
}

const IconButtonModule = React.forwardRef((props, ref) => {
    const [hovered, setHovered] = useState(false);
    const { getFillName, handleMouseEnter, handleMouseLeave } = moduleFunctions({ setHovered });

    return (
        <Tooltip disabled={!props.title} tooltipContent={props.title}>
            <IconButtonLayout
                ref={ref}
                id={props.id}
                testDataLabel={props.testDataLabel}
                className={props.className}
                white={props.white}
                transparent={props.transparent}
                size={props.size}
                fillName={getFillName(props.disabled, props.darkbg, hovered, props.iconColor)}
                disabled={props.disabled || props.disabledButton}
                icon={props.icon}
                onClick={props.toggle || props.onClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
        </Tooltip>
    );
});

IconButtonModule.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    darkBg: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    size: PropTypes.number,
    white: PropTypes.bool,
};

export default IconButtonModule;
