import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Navigator } from 'Services';

import Page from '@uilib/business-components/Page/Page';
import Button from 'uilib-wrappers/button';

import i18n from 'Services/i18n';
import './index.scss';

function Panel(props) {
    function handleBackButtonClick(event) {
        Navigator.history.back(event);
    }

    return (
        <Page className={classnames('ei-panel', props.className)} testDataLabel={props.testDataLabel}>
            <Page.Header
                className={classnames(props.headerClassName, {
                    'ei-panel-header': !props.childrenAlignRight,
                    hidden: props.headerType === 'none',
                    'ui-fix-page-big-title': props.headerType === 'big',
                })}
                pageName={i18n(props.title)}
                type="small"
                breadcrumb={props.breadcrumb}
                backButton={
                    props.withBackButton && (
                        <Button
                            onClick={handleBackButtonClick}
                            type="secondary"
                            text="BACK"
                            disabled={!Navigator.history.available()}
                        />
                    )
                }
                childrenAlignRight={props.childrenAlignRight}
            >
                {props.header}
            </Page.Header>

            <Page.Content
                className={classnames({ 'ei-panel-content-non-standard-style': !props.standardStyle }, props.className)}
                standardStyle={props.standardStyle}
            >
                {props.children}
            </Page.Content>

            {/* Footer is mostly used with the commanding toolbar which is nicely placed at the bottom of a page. */}
            <Page.Footer className={classnames('ui-fix-page-footer', props.footerClassName)}>
                {props.footer}
            </Page.Footer>
        </Page>
    );
}
//-----------------------------------------------------------------------------
Panel.propTypes = {
    headerType: PropTypes.oneOf(['none', 'small', 'big']),
    standardStyle: PropTypes.bool,
    title: PropTypes.string,
};

//-----------------------------------------------------------------------------
Panel.defaultProps = {
    headerType: 'none',
    standardStyle: false,
    title: null,
};

export default Panel;
