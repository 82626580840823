import * as React from 'react';
import { UIRouter, UIView, pushStateLocationPlugin } from '@uirouter/react';

import App from '@uilib/business-components/App/App';
import ToastContainer from '@uilib/business-components/ToastContainer/ToastContainer';
import Theme from '@uilib/business-components/_utils/theme';
import Spacer from '@uilib/core/Spacer/Spacer';
import Button from 'uilib-wrappers/button';
import ConfirmationModal from 'uilib-wrappers/confirmation-modal';
import Modal from 'uilib-wrappers/modal';
import Text from 'uilib-wrappers/text';
import Tooltip from 'uilib-wrappers/tooltip';

import { EVENT, EventNames } from 'Services/Eventing';
import i18n, { init as i18nInit, defineLanguageProperty, getStoredLanguage } from 'Services/i18n';
import RemediationConfirmationModal from 'Bricks/remediation-confirmation-modal';

import { GroupFilter, NavigationBar, Navigator, Telemetry, Authorization, RequestsCancellation } from 'Services';
import { AppContext } from 'Services/AppContext';
import { BACKEND } from 'Services/backend';
import TitleManager from 'Services/title-manager';
import Progress from 'Bricks/Progress';

// Import main application related objects.
import AppErrorBoundary from 'app.error';
import appStates from 'app.states';
import LearningModeModal from 'Bricks/learning-mode-modal';
import ProductTourModal from 'Bricks/product-tour';
import { enableSkin } from 'Services/darkreader';
import { SETTINGS, getDefaultSetting, getSetting, putSetting } from 'Services/Settings';
import locales from '@uilib/core/_locale/locales';
import isEqual from 'lodash/isEqual'; // till ei-json is needed...

//-----------------------------------------------------------------------------
// ESET UI recommended style settings - normalize.css included.
//-----------------------------------------------------------------------------
import EsetUiColors from 'eset-ui-colors';

import 'app.scss';

//-----------------------------------------------------------------------------
// Router trace debugging options.
//-----------------------------------------------------------------------------
//import { trace } from '@uirouter/react';
// trace.enable("RESOLVE");
// trace.enable("TRANSITION");
// trace.enable("HOOK");
// trace.enable("UIVIEW");
// trace.enable("VIEWCONFIG");

//-----------------------------------------------------------------------------
// ESET UI recommended theme
//-----------------------------------------------------------------------------

const eiTheme = {
    // We have to migrate to the business them step by step...
    //...ThemeProvider.THEME_BUSINESS,
    ...Theme.THEME_DEFAULT,

    /*
    Modal: {
        innerPadding: '0 24px 16px 24px',
        borderTop: 0,
        header: {
            borderBottom: 0,
            height: '50px',
            padding: '0 15px 0 24px'
        },
        footer: {
            background: '#efefef',
            padding: '2px 16px 0 24px',
            borderTop: 0
        },
        mask: {
            background: 'rgba(0,0,0, 0.3)'
        }
    },
    Page: {
        Content: {
            padding: '0'
        },
        Footer: {
            innerPadding: '0 0 0 16px',
            padding: '0',
            borderColor: '#e3e3e3'
        }
    },
*/
    // Business theme uses 500px but we have smaller sidebar menu.
    Text: {
        color: 'var(--ui-color-default-text-color)',
    },

    FormGroup: {
        description: {
            color: 'var(--ui-color-default-text-color)',
        },
    },

    Input: {
        color: {
            default: 'var(--ui-color-default-text-color)',
            placeholder: 'var(--ui-color-default-text-color)',
            readonly: 'var(--ui-color-default-text-color)',
        },
    },

    Textarea: {
        color: {
            default: 'var(--ui-color-default-text-color)',
            placeholder: 'var(--ui-color-default-text-color)',
            readonly: 'var(--ui-color-default-text-color)',
        },
    },

    Sidebar: {
        minHeight: '400px',
        minHeightSm: '400px',
    },

    SplitPane: {
        color: 'var(--ui-color-barColor);',
        border: 'none',
        margin: 0,
        dragger: {
            color: 'var(--ui-color-barColor);',
            colorHover: '#cccccc',
        },
    },

    Toast: {
        background: 'rgba(0, 0, 0, 0.5)',
        border: '2px solid #8DC640',
        padding: '24px',
        Icon: {
            align: 'center',
            padding: '0 20px 0 0',
            size: '32px !important',
        },
        Text: {
            fontSize: '16px',
            fontWeight: '600',
        },
    },

    ToastContainer: {
        container: {
            content: {
                margin: '0px 25px 0px 0px',
            },
        },
        info: {
            borderColor: 'var(--ui-color-toastContainerBackgroundColor)',
            backgroundColor: 'var(--ui-color-toastContainerBackgroundColor)',
            flexIcon: {
                color: 'var(--ui-color-infoBig)',
            },
            text: {
                color: 'var(--ui-color-toastContainerTextColor)',
            },
        },
        success: {
            borderColor: 'var(--ui-color-toastContainerBackgroundColor)',
            backgroundColor: 'var(--ui-color-toastContainerBackgroundColor)',
            flexIcon: {
                color: 'var(--ui-color-successBig)',
            },
            text: {
                color: 'var(--ui-color-toastContainerTextColor)',
            },
        },
        warning: {
            borderColor: 'var(--ui-color-toastContainerBackgroundColor)',
            backgroundColor: 'var(--ui-color-toastContainerBackgroundColor)',
            flexIcon: {
                color: 'var(--ui-color-warningBig)',
            },
            text: {
                color: 'var(--ui-color-toastContainerTextColor)',
            },
        },
        error: {
            borderColor: 'var(--ui-color-toastContainerBackgroundColor)',
            backgroundColor: 'var(--ui-color-toastContainerBackgroundColor)',
            flexIcon: {
                color: 'var(--ui-color-error)',
            },
            text: {
                color: 'var(--ui-color-toastContainerTextColor)',
            },
        },
        default: {
            borderColor: 'var(--ui-color-toastContainerBackgroundColor)',
            backgroundColor: 'var(--ui-color-toastContainerBackgroundColor)',
            flexIcon: {
                color: 'var(--ui-color-infoBig)',
            },
            text: {
                color: 'var(--ui-color-toastContainerTextColor)',
            },
            closeButton: {
                color: 'var(--ui-color-toastContainerTextColor)',
            },
        },
    },

    // EEI specific - unlimited width (events args, server-settings help)
    // TODO: BUG in UI lib 2.1 (THEME for Tooltip did not work - do not delete this because it will probably start working in future version )
    // Temporarily fix: word-break: break-all in the tooltip (by default tooltip have ulimited height and maxWidth: 280px )
    Tooltip: {
        maxWidth: 'unset',
    },

    Tile: {
        color: 'var(--ui-color-default-text-color)',
        info: {
            color: 'var(--ui-color-default-text-color)',
        },
        warning: {
            color: 'var(--ui-color-default-text-color)',
        },
        error: {
            color: 'var(--ui-color-default-text-color)',
        },
    },

    Tree: {
        node: {
            title: {
                color: 'var(--ui-color-default-text-color)',
            },
            subtitle: {
                color: 'var(--ui-color-default-text-color)',
            },
        },
    },

    RightSidebar: {
        backgroundColor: '#ffffff',
    },

    Modal: {
        maxHeight: 'min(808px, 90%)',
        maxWidth: 'min(1239px, 75%)',
    },
};

function getSupportedLanguage(code) {
    function getCode(code) {
        switch (code) {
            /**some language codes are in a different standard in 'uilib locales'*/
            case 'en':
                return 'en-GB';
            case 'fa':
                return 'fa-IR';
            case 'zh':
                return 'zh-CN';
            default:
                return code;
        }
    }

    const langCode = getCode(code);
    if (locales[langCode]) {
        return langCode;
    }

    const langCodeParent = getCode(code.split('-')[0]);
    if (locales[langCodeParent]) {
        return langCodeParent;
    }

    const languageAttribute = getCode(document.documentElement.getAttribute('lang'));
    if (locales[languageAttribute]) {
        return languageAttribute;
    }

    return getCode('en');
}

//-----------------------------------------------------------------------------
// Default state of EI after successful login.
//-----------------------------------------------------------------------------
const isCloud = window.serverInfo.useCentralRedirector;

const APP_DEFAULT_STATE = isCloud ? 'console.dashboard.incidents' : 'console.dashboard.alarms';
const APP_DEFAULT_ROUTE = isCloud ? '/console/dashboard/incidents' : '/console/dashboard/detections';

const PRODUCT_TOUR_SETTINGS_URL = 'users/settings/product_tour';

class EnterpriseInspector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            context: {
                username: null,
                setUsername: this.setUsername,
                settings: {
                    navbar: getDefaultSetting(SETTINGS.NAVBAR),
                    processTree: getDefaultSetting(SETTINGS.PROCESS_TREE),
                    dateType: getDefaultSetting(SETTINGS.DATE_TYPE),
                    skin: getDefaultSetting(SETTINGS.SKIN),
                },
                setSettings: this.setSettings,
                appState: {},
            },
            alertVisible: false,
            simpleAlertVisible: false,
            productTourModalOpen: false,
            productTourModalOpenOnDemand: false,
            learningModeModalOpen: false,
            alertInfo: {
                type: 'info',
                message: '',
            },
            loading: true,
        };

        // Redirection URL used if login had to be triggered during transition.
        this.loginRedirection = {
            state: null,
            params: null,
        };

        document.titleManager = new TitleManager();

        this.initializeDefaultColors();

        this.componentUuid = BACKEND.init();
    }

    setUsername = (username) => {
        this.setState((prevState) => ({
            context: {
                ...prevState.context,
                username,
            },
        }));
    };

    setSettings = (settings) => {
        this.setState((prevState) => ({
            context: {
                ...prevState.context,
                settings: { ...prevState.context.settings, ...settings },
            },
        }));
    };

    updateGroupFilter = () => {
        const defaultGroupFilterSetting = getDefaultSetting(SETTINGS.GROUP_FILTER);

        getSetting(SETTINGS.GROUP_FILTER, this.componentUuid)
            .success((groupFilter) => {
                if (Object.keys(groupFilter).length !== 0) {
                    GroupFilter.set(groupFilter, true);
                } else {
                    GroupFilter.set(defaultGroupFilterSetting);
                }
            })
            .failure(() => {
                GroupFilter.set(defaultGroupFilterSetting);
            })
            .execute();
    };

    enableDefaultSkin() {
        enableSkin(getDefaultSetting(SETTINGS.SKIN));
        this.setSettings(getDefaultSetting(SETTINGS.SKIN));
    }

    buildCentralRedirectorUrl(redirector, returnTo) {
        try {
            const redirectUrl = new URL(redirector);
            const returnToUrl = new URL(returnTo);
            if (returnToUrl.pathname !== '/login') {
                redirectUrl.pathname = returnToUrl.pathname;
                redirectUrl.search = returnToUrl.search;
                redirectUrl.hash = returnToUrl.hash;
            }
            return redirectUrl;
        } catch (error) {
            return redirector;
        }
    }

    runThemeSkin = () => {
        getSetting(SETTINGS.SKIN, this.componentUuid)
            .success((skin) => {
                if (Object.keys(skin).length !== 0) {
                    enableSkin(skin.theme);
                    this.setSettings({ skin });
                } else {
                    this.enableDefaultSkin();
                    this.setSettings({ skin });
                }
            })
            .failure(() => {
                this.enableDefaultSkin();
            })
            .execute();
    };

    initNavbarSettings = () => {
        const defaultNavbarSetting = getDefaultSetting(SETTINGS.NAVBAR);

        getSetting(SETTINGS.NAVBAR, this.componentUuid)
            .success((navbar) => {
                if (Object.keys(navbar).length !== 0) {
                    this.setSettings({ navbar });
                } else {
                    this.setSettings({ navbar: defaultNavbarSetting });
                }
            })
            .failure(() => {
                this.setSettings({ navbar: defaultNavbarSetting });
            })
            .execute();
    };

    initProcessTreeSettings = () => {
        const defaultProcessTreeSetting = getDefaultSetting(SETTINGS.PROCESS_TREE);

        getSetting(SETTINGS.PROCESS_TREE, this.componentUuid)
            .success((processTree) => {
                if (Object.keys(processTree).length !== 0) {
                    this.setSettings({ processTree });
                } else {
                    this.setSettings({ processTree: defaultProcessTreeSetting });
                }
            })
            .failure(() => {
                this.setSettings({ processTree: defaultProcessTreeSetting });
            })
            .execute();
    };

    initDateTypeSettings = () => {
        const defaultDateTypeSetting = getDefaultSetting(SETTINGS.DATE_TYPE);

        getSetting(SETTINGS.DATE_TYPE, this.componentUuid)
            .success((dateType) => {
                if (dateType != null) {
                    this.setSettings(dateType);
                } else {
                    this.setSettings({ dateType: defaultDateTypeSetting });
                }
            })
            .failure(() => {
                this.setSettings({ dateType: defaultDateTypeSetting });
            })
            .execute();
    };

    onAppReload = () => {
        this.runThemeSkin();
        this.updateGroupFilter();
        this.initNavbarSettings();
        this.initProcessTreeSettings();
        this.initDateTypeSettings();
    };

    initializeDefaultColors() {
        const ROOT = document.documentElement;
        for (const [colorName, colorValue] of Object.entries(EsetUiColors)) {
            ROOT.style.setProperty(`--ui-color-${colorName}`, colorValue);
        }
    }

    routerConfiguration = (router) => {
        // Create direct access to the global UIRouter component.
        // TODO v1.6 >> Remove this.router and switch its usage (this.router.stateService.current.name) into this.state.context.appState.name
        this.router = router;

        Navigator.init(router);

        // When no url matches, redirect to default state.
        router.urlRouter.otherwise(() => APP_DEFAULT_ROUTE + document.location.search);

        // Register our own JSON-URI type as default one doesn't work properly.
        router.urlService.config.type('ei-json', {
            raw: true, // Do not url-encode parameter by default (handled by encode/decode methods).
            encode: (obj) => encodeURIComponent(JSON.stringify(obj)),
            decode: (str) => JSON.parse(decodeURIComponent(str.replace(/\+/g, '%20'))),
            is: (val) => typeof val === 'object',
            pattern: /[^/]+/,
            equals: (a, b) => isEqual(a, b),
        });

        const params = new URL(document.location).searchParams;
        const initialLoginRedirectionCode = params.get('code');

        router.transitionService.onBefore({}, (transition) => {
            if (!Authorization.isAuthenticated) {
                this.enableDefaultSkin();
            }

            if (transition.to().name === 'redirector' && transition.to().externalUrl) {
                const returnTo = transition.router.stateService.href(
                    transition.from().name,
                    transition.params('from'),
                    { absolute: true }
                );
                const redirectUrl = this.buildCentralRedirectorUrl(transition.to().externalUrl, returnTo);
                window.open(redirectUrl, '_self');
            } else if (!Authorization.isAuthenticated && transition.to().name !== 'login') {
                // User is not authenticated and wants to open a page different than a login one.
                if (
                    window.serverInfo.useCentralRedirector &&
                    window.serverInfo.centralRedirector &&
                    !initialLoginRedirectionCode
                ) {
                    const returnTo = transition.router.stateService.href(
                        transition.to().name,
                        transition.params('to'),
                        { absolute: true }
                    );
                    const redirectUrl = this.buildCentralRedirectorUrl(window.serverInfo.centralRedirector, returnTo);
                    window.open(redirectUrl, '_self');
                    return;
                }

                this.loginRedirection.state = transition.to();
                this.loginRedirection.params = transition.params('to');

                let params = new URL(document.location).searchParams;
                let oneTimeToken = params.get('ott');
                return transition.router.stateService.target('login', {
                    ott: oneTimeToken,
                    code: initialLoginRedirectionCode,
                });
            }
        });

        router.transitionService.onError({}, (transition) => {
            Navigator.history.push({ name: transition.from().name, params: transition.params('from') });
        });

        router.transitionService.onSuccess({}, (transition) => {
            if (
                Authorization.isAuthenticated &&
                transition.from().name !== 'login' &&
                transition.to().name !== 'login' &&
                transition.to().name !== 'redirector' &&
                !transition.to().externalUrl
            ) {
                const lastUrl = { state: transition.to().name, params: transition.params('to') };
                putSetting(SETTINGS.LAST_URL, lastUrl, this.componentUuid).execute();

                Navigator.history.push({ name: transition.to().name, params: transition.params('to') });
            }

            const NEW_CONTEXT = {
                ...this.state.context,
                appState: { ...transition.to() },
            };
            this.setState({ context: NEW_CONTEXT });
            Telemetry.submitTransition(transition.to().name, transition.from().name);
        });

        function shouldResetTitleManager(state, transition) {
            return (
                !state.parent?.name ||
                state.parent?.name === 'console' ||
                state.parent?.name !== transition._targetState?._definition?.parent?.name
            );
        }

        router.transitionService.onStart(
            {
                from: (state, transition) => {
                    return shouldResetTitleManager(state, transition);
                },
            },
            (transition) => {
                document.titleManager = new TitleManager(transition.to().pageTitle);
            }
        );

        router.transitionService.onStart(
            {
                from: (state, transition) => {
                    return !shouldResetTitleManager(state, transition);
                },
            },
            (transition) => {
                document.titleManager.page = transition.to().pageTitle;
            }
        );
    };

    componentDidMount() {
        console.log(
            '%cWARNING:%c This functionality of the web browser should be used only by the creators of the %cES%cET%c Enterprise Inspector. Due to security reasons do not copy and execute any commands in this window.',
            'color: red; font: bold 32px Arial; text-shadow: 1px 1px 2px #cccccc',
            'font-variant: bold; font-size: 16px',
            'border: 2px solid #0096a1; border-radius: 12px 0 0 12px; padding: 0 0 0 5px; color: #0096a1; font: bold 24px Arial',
            'border: 2px solid #0096a1; border-radius: 0 12px 12px 0; padding: 0 5px 0 0; color: white; background: #0096a1; font: bold 24px Arial',
            'font-variant: bold; font-size: 16px'
        );

        // Define special flags to show hidden features.
        Object.defineProperties(window, {
            eeiAugur: {
                configurable: true,
                get: () => window._eeiAugur,
                set: async (value) => {
                    window._eeiAugur = value;
                    this.forceUpdate();
                },
            },
            eeiEventFilterGenerator: {
                configurable: true,
                get: () => window._eeiEventFilterGenerator,
                set: async (value) => {
                    window._eeiEventFilterGenerator = value;
                    this.forceUpdate();
                },
            },
        });

        NavigationBar.init();
        Authorization.init();
        RequestsCancellation.init();

        //---------------------------------------------------------
        // Events subscriptions.
        //---------------------------------------------------------
        this.commandingActionCalledUnsubscribe = EVENT.subscribe(
            EventNames.COMMANDING_ACTION_CALLED_EVENT,
            (event, data) => {
                Telemetry.submitCommandingChange(this.router.stateService.current.name, data.name, data.count);
            }
        );

        this.groupFilterChangedUnsubscribe = EVENT.subscribe(EventNames.GROUP_FILTER_CHANGED_EVENT, (event, data) => {
            Telemetry.submitGroupFilterChange(this.router.stateService.current.name);
            if (data.save) {
                putSetting(
                    SETTINGS.GROUP_FILTER,
                    {
                        parentGroupId: data.parentGroupId,
                        groupId: data.groupId,
                        groupName: data.groupName,
                    },
                    this.componentUuid
                ).execute();
            }
        });

        this.localFiltersChangedUnsubscribe = EVENT.subscribe(EventNames.LOCAL_FILTERS_CHANGED_EVENT, (event, data) => {
            if (data.filterShifted !== undefined) {
                Telemetry.submitLocalFiltersVisibilityChange(
                    this.router.stateService.current.name,
                    data.filterShifted.id,
                    data.filterShifted.visible
                );
            }
        });

        this.columnsVisibilityChangedUnsubscribe = EVENT.subscribe(
            EventNames.TABLE_COLUMNS_VISIBILITY_CHANGED_EVENT,
            (event, data) => {
                Telemetry.submitTableColumnsVisibilityChange(
                    this.router.stateService.current.name,
                    data.id,
                    data.visible
                );
            }
        );

        // controller.isModalOpen = false;
        this.errorOfHandlingRestUnsubscribe = EVENT.subscribe(EventNames.BACKEND_ALERT_EVENT, (event, alertInfo) => {
            this.setState({
                alertVisible: true,
                alertInfo,
            });
        });

        this.simpleAlertEventUnsubscribe = EVENT.subscribe(EventNames.SIMPLE_ALERT_EVENT, (event, alertInfo) => {
            this.setState({
                simpleAlertVisible: true,
                alertInfo,
            });
        });

        this.userAuthenticatedEventUnsubscribe = EVENT.subscribe(EventNames.USER_AUTHENTICATED_EVENT, (event) => {
            const redirectNavigator = (lastUrl) => {
                if (null != this.loginRedirection.state) {
                    lastUrl = {
                        state: this.loginRedirection.state.name,
                        params: this.loginRedirection.params,
                    };
                    Navigator.replace(event, this.loginRedirection.state, this.loginRedirection.params);
                    this.loginRedirection.state = null;
                    this.loginRedirection.params = null;
                } else {
                    if (lastUrl != null) {
                        Navigator.replace(event, lastUrl.state, lastUrl.params);
                    } else {
                        Navigator.replace(event, APP_DEFAULT_STATE);
                    }
                }
            };

            this.onAppReload();

            getSetting(SETTINGS.LAST_URL, this.componentUuid)
                .success((lastUrl) => {
                    redirectNavigator(lastUrl, event);
                })
                .failure(() => {
                    redirectNavigator(null, event);
                })
                .always(() => {
                    this.setState({ productTourModalOpen: true });
                })
                .execute();
        });

        this.showProductTourEventUnsubscribe = EVENT.subscribe(EventNames.SHOW_PRODUCT_TOUR_EVENT, (event) => {
            BACKEND.put(PRODUCT_TOUR_SETTINGS_URL, { completed: false }, this.componentUuid)
                .success(() => {
                    this.setState({ productTourModalOpen: true, productTourModalOpenOnDemand: true });
                })
                .execute();
        });

        const language = getStoredLanguage();
        i18nInit(language).then(() => {
            this.setState({ loading: false });
            defineLanguageProperty(() => {
                window.location.reload();
            });
            document.titleManager?.update?.();
        });

        // listen for changes to localStorage
        window.addEventListener('storage', this.sessionStorageTransfer, false);

        // Ask other tabs for session storage (this is ONLY to trigger event)
        if (!window.sessionStorage.length) {
            window.localStorage.setItem('entInspectorGetSessionStorage', 'onNewConsole');
            window.localStorage.removeItem('entInspectorGetSessionStorage');
        }

        if (Authorization.isAuthenticated) {
            this.onAppReload();
        }
    }

    handleCloseModalClick = (event) => {
        this.setState({ alertVisible: false });

        if (this.state.alertInfo.redirectToDefaultState) {
            Navigator.go(event, APP_DEFAULT_STATE);
        }
    };

    componentWillUnmount() {
        this.columnsVisibilityChangedUnsubscribe();
        this.groupFilterChangedUnsubscribe();
        this.localFiltersChangedUnsubscribe();
        this.commandingActionCalledUnsubscribe();

        this.errorOfHandlingRestUnsubscribe();
        this.simpleAlertEventUnsubscribe();

        this.userAuthenticatedEventUnsubscribe();

        this.showProductTourEventUnsubscribe();

        Telemetry.clear();
        NavigationBar.clear();
        Authorization.clear();
        RequestsCancellation.clear();
        BACKEND.clearAll();

        window.removeEventListener('storage', this.sessionStorageTransfer, false);
    }

    sessionStorageTransfer = (event) => {
        // Transfer sessionStorage from one tab to another.

        // do nothing if no value to work with
        if (!event.newValue) {
            return;
        }

        if (event.key === 'entInspectorGetSessionStorage') {
            // another tab asked for the sessionStorage -> send it
            window.localStorage.setItem('entInspectorSessionStorage', JSON.stringify(window.sessionStorage));

            // the other tab should now have it, so we're done with it
            window.localStorage.removeItem('entInspectorSessionStorage');
        } else if (event.key === 'entInspectorSessionStorage' && !window.sessionStorage.length) {
            // another tab sent data <- get it
            const data = JSON.parse(event.newValue);
            for (let key in data) {
                window.sessionStorage.setItem(key, data[key]);
            }

            if (null != this.loginRedirection.state) {
                console.log('redirectAfterLoginUrl');
                this.onAppReload();
                Navigator.go(null, this.loginRedirection.state, this.loginRedirection.params);
            }
        }
    };

    handleSimpleModalClose = () => {
        this.setState({ simpleAlertVisible: false });
        if (this.state.alertInfo.callback) {
            this.state.alertInfo.callback();
        }
    };

    handleProductTourModalExit = () => {
        if (this.state.productTourModalOpenOnDemand) {
            this.setState({ productTourModalOpen: false, productTourModalOpenOnDemand: false });
        } else {
            this.setState({ productTourModalOpen: false, learningModeModalOpen: true });
        }
    };

    handleLearningModeModalExit = () => {
        this.setState({ learningModeModalOpen: false });
    };

    render() {
        return (
            <AppErrorBoundary>
                <Theme.Provider theme={eiTheme}>
                    <App locale={getSupportedLanguage(navigator.language)}>
                        <AppContext.Provider value={this.state.context}>
                            <RemediationConfirmationModal />
                            <ProductTourModal
                                open={this.state.productTourModalOpen && !this.state.alertVisible}
                                onExit={this.handleProductTourModalExit}
                            />
                            <LearningModeModal
                                open={this.state.learningModeModalOpen && !this.state.alertVisible}
                                onExit={this.handleLearningModeModalExit}
                            />
                            <Modal
                                show={this.state.alertVisible}
                                type={this.state.alertInfo.type}
                                title={this.state.alertInfo.message}
                                onDispose={() => this.setState({ alertVisible: false })}
                                buttons={[
                                    <Button
                                        id="eid-alert-button-ok"
                                        type="primary"
                                        text="OK"
                                        onClick={this.handleCloseModalClick}
                                    />,
                                ]}
                            >
                                <Text fontWeight="bold">{this.state.alertInfo.details}</Text>
                                {this.state.alertInfo.showRequestDetails && (
                                    <Spacer type="m-2">
                                        <Text defaultColor fontWeight="bold" className="ei-mod-text-uppercase">
                                            {`[${this.state.alertInfo.method}] >> ${this.state.alertInfo.url} >> ${this.state.alertInfo.status}`}
                                        </Text>
                                        <Text defaultColor fontWeight="bold" className="ei-mod-text-uppercase">
                                            {i18n('TIMESTAMP_WITH_VALUE', {
                                                timestamp: this.state.alertInfo.timestamp,
                                            })}
                                        </Text>
                                    </Spacer>
                                )}
                                {this.state.alertInfo.type === 'error' && (
                                    <Text noMargin={false}>
                                        IF_ERROR_PERSISTS_PLEASE_CONSULT_HELP_OR_CONTACT_CUSTOMER_CARE
                                    </Text>
                                )}
                            </Modal>
                            <Theme.Provider theme={{ ConfirmationModal: this.state.alertInfo.theme || {} }}>
                                <ConfirmationModal
                                    show={this.state.simpleAlertVisible}
                                    type={this.state.alertInfo.type}
                                    className={this.state.alertInfo.className}
                                    message={this.state.alertInfo.message}
                                    text={this.state.alertInfo.details}
                                    children={this.state.alertInfo.children}
                                    onDispose={this.handleSimpleModalClose}
                                    buttons={
                                        this.state.alertInfo.buttons?.map((buttonProps) => {
                                            const {
                                                id,
                                                type,
                                                onClick,
                                                tooltipContent,
                                                tooltipDisabled = true,
                                            } = buttonProps;
                                            return (
                                                <Tooltip
                                                    tooltipContent={i18n(tooltipContent)}
                                                    disabled={tooltipDisabled}
                                                    id={`eid-simple-alert-button-${id}-tooltip`}
                                                >
                                                    <div>
                                                        <Button
                                                            {...buttonProps}
                                                            id={`eid-simple-alert-button-${id}`}
                                                            type={type || 'secondary'}
                                                            onClick={() => {
                                                                this.handleSimpleModalClose();
                                                                onClick?.();
                                                            }}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            );
                                        }) || [
                                            <Button
                                                id="eid-simple-alert-button-ok"
                                                type="primary"
                                                text="OK"
                                                onClick={this.handleSimpleModalClose}
                                            />,
                                        ]
                                    }
                                />
                            </Theme.Provider>

                            {this.state.loading && <Progress dim instant showProgress />}

                            <UIRouter
                                config={this.routerConfiguration}
                                plugins={[pushStateLocationPlugin]}
                                states={appStates}
                            >
                                <React.Suspense fallback={<Progress dim instant showProgress />}>
                                    <UIView />
                                </React.Suspense>
                            </UIRouter>
                        </AppContext.Provider>
                        <ToastContainer />
                    </App>
                </Theme.Provider>
            </AppErrorBoundary>
        );
    }
}

export default EnterpriseInspector;
