import Loading from '@uilib/business-components/Animations/Loading/Loading';
import PropTypes from 'prop-types';

const PROGRESS_FULLSIZE = 100;

function ProgressLayout(props) {
    // Show fullscreen overlay - this will block potential clicks but
    // don't show progress immediately to remove flickering effect (nor show it on table views).
    return (
        <Loading
            className="ei-top-layer"
            backgroundColor={props.dim ? 'black!important' : 'transparent!important'}
            fullscreen
            size={PROGRESS_FULLSIZE}
            show={props.show}
            testDataLabelLoadings="eid-loading-page-animation"
        />
    );
}

ProgressLayout.propTypes = {
    show: PropTypes.bool.isRequired,
    dim: PropTypes.bool.isRequired,
};

export default ProgressLayout;
