import UAParser from 'ua-parser-js';
import moment from 'moment';

import { BACKEND } from 'Services/backend';

class Metrics {
    constructor(key, value) {
        this.value = {};
        this.value[key] = this._init(value);
    }

    accumulate = (metric) => {
        Object.keys(metric.value).forEach((key, index) => {
            if (key in this.value) {
                this.value[key] = this._accumulate(this.value[key], metric.value[key]);
            } else {
                this.value[key] = metric.value[key];
            }
        });
    };
}

class MetricsCounted extends Metrics {
    constructor(key, value) {
        super(key, value);
        this.type = 'MetricsCounted';
    }

    _init(value) {
        return value || 1;
    }

    _accumulate(lh, rh) {
        return lh + rh;
    }
}

class MetricsSampledDuration extends Metrics {
    constructor(key, value) {
        super(key, value);
        this.type = 'MetricsSampledDuration';
    }

    _init(value) {
        let s = {
            counter: 1,
            ravg: { unit: 'ms', value: value },
            min: { unit: 'ms', value: value },
            max: { unit: 'ms', value: value },
        };
        return s;
    }

    _accumulate(lh, rh) {
        let s = {
            counter: lh['counter'] + rh['counter'],
            ravg: { unit: 'ms', value: lh['ravg'].value + rh['ravg'].value },
            min: { unit: 'ms', value: Math.min(lh['min'].value, rh['min'].value) },
            max: { unit: 'ms', value: Math.max(lh['max'].value, rh['max'].value) },
        };
        return s;
    }
}

class Telemetry {
    constructor() {
        this.metrics = {};

        this.timerId = setInterval(() => {
            this.save();
        }, window.serverInfo.telemetry.callbackTime * 1000);

        this.componentUuid = BACKEND.init();

        this.submitSystemMetrics();
    }

    clear = () => {
        BACKEND.clear(this.componentUuid);
        if (this.timerId) {
            clearInterval(this.timerId);
        }
    };

    save = () => {
        if (Object.keys(this.metrics).length > 0) {
            BACKEND.put('telemetry', this.metrics, this.componentUuid)
                .success(() => {
                    this.metrics = {};
                })
                .failure(() => {
                    console.log('telemetry send error!');
                })
                .execute();
        }
    };

    submit = (id, metric) => {
        if (id in this.metrics) {
            this.metrics[id].accumulate(metric);
        } else {
            this.metrics[id] = metric;
        }
    };

    submitSystemMetrics = () => {
        const USER_AGENT_DATA = new UAParser().getResult();

        this.submit('FrontEnd/System_Cpu', new MetricsCounted(USER_AGENT_DATA.cpu.architecture));
        this.submit(
            'FrontEnd/System_Os',
            new MetricsCounted(`${USER_AGENT_DATA.os.name} ${USER_AGENT_DATA.os.version}`)
        );
        this.submit(
            'FrontEnd/System_Engine',
            new MetricsCounted(`${USER_AGENT_DATA.engine.name} ${USER_AGENT_DATA.engine.version}`)
        );
        this.submit(
            'FrontEnd/System_Device',
            new MetricsCounted(
                `${USER_AGENT_DATA.device.model} ${USER_AGENT_DATA.device.type} ${USER_AGENT_DATA.device.vendor}`
            )
        );
        this.submit(
            'FrontEnd/System_Browser',
            new MetricsCounted(`${USER_AGENT_DATA.browser.name} ${USER_AGENT_DATA.browser.version}`)
        );
        this.submit('FrontEnd/Browser_Screen', new MetricsCounted(`${window.screen.width}x${window.screen.height}`));
        this.submit('FrontEnd/Browser_Language', new MetricsCounted(navigator.language));
    };

    submitTransition = (targetStateName, sourceStateName) => {
        const TRANSITION_MOMENT = moment();
        this.submit('FrontEnd/Routes', new MetricsCounted([targetStateName, sourceStateName]));
        if (this.lastTransitionMoment !== undefined) {
            const RETENTION_TIME = TRANSITION_MOMENT.diff(this.lastTransitionMoment);
            this.submit('FrontEnd/Retention', new MetricsSampledDuration(sourceStateName, RETENTION_TIME));
        }
        this.lastTransitionMoment = TRANSITION_MOMENT;
    };

    submitGroupFilterChange = (stateName) => {
        const METRIC_ID = 'FrontEnd/GroupFilter';
        const METRIC_KEY = `${stateName}/Groups`;
        this.submit(METRIC_ID, new MetricsCounted(METRIC_KEY));
    };

    submitLocalFiltersVisibilityChange = (stateName, filterId, visible) => {
        const METRIC_ID = `FrontEnd/LocalFilters/${visible ? 'Enabled' : 'Disabled'}`;
        const METRIC_KEY = `${stateName}/${filterId}`;
        this.submit(METRIC_ID, new MetricsCounted(METRIC_KEY));
    };

    submitCommandingChange = (stateName, actionName, actionCount) => {
        const METRIC_ID = 'FrontEnd/Commanding';
        const METRIC_KEY = `${stateName}/${actionName}`;
        this.submit(METRIC_ID, new MetricsCounted(METRIC_KEY, actionCount));
    };

    submitTableColumnsVisibilityChange = (stateName, columnId, visible) => {
        const METRIC_ID = `FrontEnd/Columns/${visible ? 'Enabled' : 'Disabled'}`;
        const METRIC_KEY = `${stateName}/${columnId}`;
        this.submit(METRIC_ID, new MetricsCounted(METRIC_KEY));
    };
}

const telemetry = new Telemetry();

export default telemetry;
