import InputNumber from '@uilib/business-components/InputNumber';

import i18n from 'Services/i18n';

export default function InputNumberWrapper(props) {
    // UI Library hack (I_UILIB-1036)
    function parser(value) {
        const number = parseInt(value);
        if (Number.isNaN(number)) {
            return '';
        }
        return String(number);
    }

    return <InputNumber {...props} placeholder={i18n(props.placeholder)} parser={parser} />;
}
