import i18n from 'Services/i18n';
const APP_DEFAULT_TITLE = window.serverInfo.useCentralRedirector ? 'ESET_INSPECT_CLOUD' : 'ESET_INSPECT';
const INSTANCE_NAME = window.serverInfo.instance.instanceName || '';

class TitleManager {
    constructor(page, item) {
        this._item = item;
        this._page = page;
        this.update();
    }

    update = () => {
        const subtitle = this._page
            ? this._item
                ? `${i18n(this._item)} - ${i18n(this._page)}`
                : `${i18n(this._page)}`
            : '';

        document.title = `${INSTANCE_NAME ? `${INSTANCE_NAME}: ` : ''}${subtitle} - ${i18n(APP_DEFAULT_TITLE)}`;
    };

    set item(item) {
        this._item = item;
        this.update();
    }

    set page(page) {
        this._page = page;
        this.update();
    }
}

export default TitleManager;
